import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { IoIosArrowDown } from "react-icons/io";

const BannerChangelogHistory = ({ changelogs, bookingStatus }) => {
  const [newChangeLogs, setNewChangeLogs] = useState([]);

  useEffect(() => {
    const newLogs = changelogs.filter((log) => log.comment !== null);
    setNewChangeLogs(newLogs);
  }, [changelogs]);

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const formatTime = (isoDateString) => {
    const date = new Date(isoDateString);

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  if (!newChangeLogs || newChangeLogs.length === 0) {
    return null;
  }

  return (
    <div className="w-full bg-white pt-6">
      <Disclosure defaultOpen={bookingStatus !== "in_coordination"}>
        {({ open }) => (
          <>
            {/* Header */}
            <Disclosure.Button className="w-full flex items-center justify-between py-3 cursor-pointer">
              <div className="flex items-center space-x-2">
                <h3 className="text-base font-semibold">Korrekturen</h3>
                <div className="w-5 h-5 flex items-center justify-center bg-red-500 text-white text-xs rounded-full">
                  {newChangeLogs.length}
                </div>
                <IoIosArrowDown
                  className={`transition-transform transform ${
                    open ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
            </Disclosure.Button>

            {/* Collapsible Content */}
            <Disclosure.Panel className="py-4 space-y-4">
              {newChangeLogs.map((log, index) => (
                <div
                  key={index}
                  className="bg-gray-100 p-4 rounded-md shadow-sm"
                >
                  <div className="text-sm text-gray-600 mb-1">
                    <span className="font-bold">
                      {formatDate(log.created_at)}
                    </span>{" "}
                    <span className="text-gray-500">
                      {formatTime(log.created_at)} Uhr
                    </span>{" "}
                    <span className="text-gray-500">
                      von {log.user.first_name} {log.user.last_name}
                    </span>
                  </div>
                  <p className="text-gray-800 text-base">{log.comment}</p>
                </div>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default BannerChangelogHistory;

import { ComputerDesktopIcon } from "@heroicons/react/24/solid";
import React from "react";
import AdStatusBadge from "../../../components/badges/AdStatusBadge";
import Card from "../../../components/Card";
import LoadingBar from "../../../components/loading/LoadingBar";
import useFormatter from "../../../hooks/useFormatter";
import Spinner from "../../../components/loading/Spinner";

const SeaAd = ({ ad, isLoading, index }) => {
  const { decimalFormatter } = useFormatter();

  return (
    <div className="rounded bg-gray-100 p-5 md:grid grid-cols-3 gap-4">
      <div className="  col-span-2">
        <div className="flex justify-between w-full">
          <div className="flex items-center space-x-2">
            <h2 className="text-lg">Textanzeige {index}</h2>
            <ComputerDesktopIcon className="h-5 w-5 text-primary" />{" "}
          </div>
          {isLoading ? (
            <LoadingBar height="h-8" width="w-20" />
          ) : (
            <AdStatusBadge status={ad?.status} />
          )}
        </div>
      </div>
      <h4 className="text-lg mt-4 md:mt-0">Leistungsdaten</h4>

      <div className="col-span-2 ">
        <Card>
          {isLoading ? (
            <div className="flex justify-center items-center h-64 w-full">
              {" "}
              <Spinner />
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: ad?.content }}></div>
          )}
        </Card>
      </div>
      <div className=" mt-4 md:mt-0">
        <Card>
          <h5 className="font-semibold text-sm">Gesamt</h5>
          <div className="grid grid-cols-3 md:grid-cols-1 gap-4 xl:grid-cols-3  mt-4">
            <div>
              <p className="text-sm text-gray-500">Sichtkontakte</p>
              {isLoading ? (
                <LoadingBar height="h-8" width="w-14" type="headline" />
              ) : (
                <p className=" font-semibold text-xl">
                  {ad?.impressions
                    ? decimalFormatter.format(ad?.impressions)
                    : "-"}
                </p>
              )}
            </div>
            <div className="justify-self-center sm:justify-self-start xl:justify-self-center">
              <p className="text-sm text-gray-500">Klicks</p>
              {isLoading ? (
                <LoadingBar height="h-8" width="w-14" type="headline" />
              ) : (
                <p className=" font-bold text-xl">
                  {ad?.clicks ? decimalFormatter.format(ad?.clicks) : "-"}
                </p>
              )}
            </div>
            {/*  <div className="justify-self-center sm:justify-self-start  xl:justify-self-center">
              <p className="text-sm text-gray-500 ">Klickrate</p>
              {isLoading ? (
                <LoadingBar height="h-8" width="w-14" type="headline" />
              ) : (
                <p className="font-bold text-xl">
                  {ad?.impressions
                    ? ((ad?.clicks / ad?.impressions) * 100)
                        ?.toFixed(2)
                        .replace(/\./g, ",") + " %"
                    : "-"}{" "}
                </p>
              )}
            </div> */}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SeaAd;

import React, { useState } from "react";
import useChannelIcons from "../../../hooks/useChannelIcons";
import { LiaCheckSquareSolid } from "react-icons/lia";
import CreativeOverview from "../../../components/creatives/CreativeOverview";
import Modal from "../../../components/modals/Modal";
import useModal from "../../../hooks/useModal";
import RadioDesicionButtons from "../../../components/buttons/RadioDesicionButtons";
import TextArea from "../../../components/textareas/TextArea";
import { Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
const BannerConfirmationArea = ({
  adtemplate,
  onAcceptedOrDenied,
  onCommentChange,
  showRequiredErrors,
  disabled,
  bookingStatus,
  booking,
}) => {
  const { channelIcons } = useChannelIcons();
  const [selectedAdtemplate, setSelectedAdtemplate] = useState(null);
  const { toggleModal, modalRegistered } = useModal();
  return (
    <>
      <div className="lg:grid grid-cols-2">
        <div className="sm:flex flex-col h-full justify-between sm:space-y-24">
          <div>
            <p className="font-bold text-lg">
              {booking.company} {booking.title}
              {" / "}
              {adtemplate?.format}
            </p>
            <ol className="mt-4 space-y-2">
              <li className="grid grid-cols-4 gap-12 sm:gap-0">
                <p className="text-stw3-gray-300 text-base">Format:</p>
                <p className="text-base whitespace-nowrap">
                  {adtemplate.format}
                </p>
              </li>
              <li className="grid grid-cols-4 gap-12 sm:gap-0">
                <p className="text-stw3-gray-300 text-base">Abmessung:</p>
                <p className="text-base whitespace-nowrap">{`${adtemplate.width} x ${adtemplate.height} px`}</p>
              </li>
              <li className="grid grid-cols-4 gap-12 sm:gap-0">
                <p className="text-stw3-gray-300 text-base">Typ:</p>
                <div className="flex items-center space-x-2">
                  <span>{channelIcons("html", "h-6")}</span>
                  <div className="text-white bg-[#D35836] rounded p-1 px-2 text-sm font-bold">
                    HTML5
                  </div>
                  <div className="text-white bg-[#498D4D] rounded p-1 px-2 text-sm font-bold flex items-center space-x-1">
                    <LiaCheckSquareSolid className="text-white h-4 w-4 stroke-2" />
                    <p>AdCheck</p>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div className="hidden lg:block">
            <RadioDesicionButtons
              onChange={onAcceptedOrDenied}
              value={
                adtemplate.status === "approved"
                  ? true
                  : adtemplate.status === "rejected"
                  ? false
                  : null
              }
              labelAcceptButton={"Banner freigeben"}
              labelDeclineButton={"Banner bemängeln"}
              disabled={disabled}
            />
            {showRequiredErrors &&
              (adtemplate?.status === "waiting_for_approval" ||
                adtemplate?.status === null) && (
                <div className="text-red-600 flex items-center space-x-4 mt-4 text-sm">
                  <ExclamationTriangleIcon className="h-5 w-5" />
                  <p>Bitte geben Sie eine Entscheidung für den Banner ab.</p>
                </div>
              )}
          </div>
        </div>
        <div className="border border-stw3-gray-300 rounded flex items-center justify-center p-12 h-96 mt-4 lg:mt-0">
          <CreativeOverview
            ad={adtemplate}
            onClick={() => {
              setSelectedAdtemplate(adtemplate);
              toggleModal("creativePreview");
            }}
          />
        </div>
        <div className=" lg:hidden mt-4">
          <RadioDesicionButtons
            onChange={onAcceptedOrDenied}
            value={
              adtemplate.status === null
                ? null
                : adtemplate.status === "approved"
            }
            labelAcceptButton={"Banner freigeben"}
            labelDeclineButton={"Banner bemängeln"}
            disabled={disabled}
          />
          {showRequiredErrors &&
            (adtemplate?.status === "waiting_for_approval" ||
              adtemplate?.status === null) && (
              <div className="text-red-600 flex items-center space-x-4 mt-4 text-sm">
                <ExclamationTriangleIcon className="h-5 w-5" />
                <p>Bitte geben Sie eine Entscheidung für den Banner ab.</p>
              </div>
            )}
        </div>
      </div>

      <Transition
        show={
          adtemplate?.status === "rejected" &&
          bookingStatus === "in_coordination"
        }
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="mt-6 lg:mt-12">
          <p className="font-bold text-base mb-2">Begründung</p>
          {showRequiredErrors && !adtemplate?.comment && (
            <div className="text-red-600 flex items-center space-x-4 mb-4 text-sm">
              <ExclamationTriangleIcon className="h-5 w-5" />
              <p>
                Bitte geben Sie eine Begründung für die Ablehnung des Banners
                ab.
              </p>
            </div>
          )}
          <TextArea
            disabled={disabled}
            value={adtemplate.comment ?? ""}
            onChange={onCommentChange}
            placeholder={"Was ist an dem Banner zu bemängeln?"}
            rows={4}
          />
        </div>
      </Transition>
      <Modal
        show={modalRegistered("creativePreview")}
        onClose={() => {
          toggleModal("creativePreview");
          setSelectedAdtemplate(null);
        }}
      >
        {selectedAdtemplate && <CreativeOverview ad={selectedAdtemplate} />}
      </Modal>
    </>
  );
};

export default BannerConfirmationArea;

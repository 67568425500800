import React from "react";
import Badge from "./Badge";

const AdStatusBadge = ({ status }) => {
  const getLabel = () => {
    switch (status) {
      case "active":
      case "ACTIVE":
      case "ENABLED":
        return "Aktiv";
      case "diabled":
      case "DISABLED":
        return "Inaktiv";
      case "paused":
      case "PAUSED":
        return "Inaktiv";
      case null:
        return "";
      default:
        return "Inaktiv";
    }
  };

  const getColor = () => {
    switch (status) {
      case "active":
      case "ENABLED":
      case "ACTIVE":
        return "green";
      case "PAUSED":
      case "paused":
        return "yellow";
      case null:
        return "";
      default:
        return "yellow";
    }
  };

  return <Badge label={getLabel()} color={getColor()} size={"sm"} />;
};

export default AdStatusBadge;

import { useQuery } from "react-query";
import axios from "axios";

// API-URL aus Umgebungsvariablen holen
const apiUrl = process.env.REACT_APP_COREAPI_URL;

export const useSitelinks = (token, campaignId, queryString) => {
  // useQuery-Hook, der die fetchData-Funktion aufruft
  return useQuery(["sitelinks", campaignId, queryString], () =>
    getSitelinks(token, campaignId, queryString)
  );
};
const getSitelinks = async (token, campaignId, queryString) => {
  try {
    const { data } = await axios.get(
      `${apiUrl}/campaigns/${campaignId}/sitelinks${queryString ?? ""}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error("Fehler beim Abrufen der Kampagnendaten", error);
    return error.response;
  }
};

export const useKeywords = (token, campaignId, queryString) => {
  // useQuery-Hook, der die fetchData-Funktion aufruft
  return useQuery(["keywords", campaignId, queryString], () =>
    getKeywords(token, campaignId, queryString)
  );
};
const getKeywords = async (token, campaignId, queryString) => {
  try {
    const { data } = await axios.get(
      `${apiUrl}/campaigns/${campaignId}/keywords${queryString ?? ""}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error("Fehler beim Abrufen der Kampagnendaten", error);
    return error.response;
  }
};
export const useCampaignData = (token, queryString) => {
  // useQuery-Hook, der die fetchData-Funktion aufruft
  return useQuery(["campaignData", queryString], () =>
    getCampaigns(token, queryString)
  );
};

const getCampaigns = async (token, queryString) => {
  try {
    const { data } = await axios.get(
      `${apiUrl}/campaigns${queryString ? `?${queryString}` : ""}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.data; // Daten zurückgeben
  } catch (error) {
    console.error("Fehler beim Abrufen der Kampagnendaten", error);
    throw error; // Fehler werfen, um ihn in der aufrufenden Komponente zu behandeln
  }
};

export const downloadCampaignReportingPdf = async (
  token,
  campaignId,
  queryString
) => {
  try {
    let url = `${apiUrl}/campaigns/${campaignId}/reporting/download-pdf`;
    if (queryString && queryString !== "") {
      url += queryString;
    }

    const respone = await axios.get(url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return respone;
  } catch (error) {
    return error.response;
  }
};

import React, { useEffect, useReducer, useState } from "react";

import "./App.css";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import Routes from "./layout/Routes.js";
import AuthContext from "./contexts/AuthContext.js";
import authReducer from "./reducers/AuthReducer.js";
import moment from "moment";
import "moment/locale/de";
import bugsnagClient from "./BugsnagClient";
import { PlausibleProvider } from "./contexts/PlausibleProvider";
import { Toaster } from "react-hot-toast";

const queryClient = new QueryClient();

const App = () => {
  const [auth, dispatchAuth] = useReducer(authReducer, {
    isAuthenticated: false,
    user: null,
    token: null,
  });
  const [isEmployee, setEmployee] = useState();

  useEffect(() => {
    dispatchAuth({ type: "AUTH" });
    moment.locale("de");
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      // Setze benutzerdefinierte Metadaten für Bugsnag
      bugsnagClient.setUser(auth?.user?.user?.id);
      bugsnagClient.addMetadata("user", { id: auth?.user?.user?.id });
    }
    setEmployee(auth?.user?.user?.is_employee);
  }, [auth]);

  const ErrorBoundary = bugsnagClient
    .getPlugin("react")
    .createErrorBoundary(React);
  return (
    <ErrorBoundary>
      {isEmployee === false ? (
        <PlausibleProvider>
          <QueryClientProvider client={queryClient}>
            <AuthContext.Provider value={{ auth, dispatchAuth }}>
              <Routes />
            </AuthContext.Provider>
          </QueryClientProvider>
        </PlausibleProvider>
      ) : (
        <QueryClientProvider client={queryClient}>
          <AuthContext.Provider value={{ auth, dispatchAuth }}>
            <Routes />
          </AuthContext.Provider>
        </QueryClientProvider>
      )}
      <Toaster position="top-right" />
    </ErrorBoundary>
  );
};

export default App;

import moment from "moment";
import CampaignStatusBadge from "../../../components/badges/CampaignStatusBadge";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";

const BookingList = ({ bookings }) => {
  return (
    <>
      <div className="space-y-4">
        {bookings.map((booking) => (
          <Card className="bg-white hover:bg-gray-50">
            <Link
              to={`/bookings/${booking.hash}`}
              key={booking.id}
              className="flex justify-between items-center "
            >
              <div className="flex items-center space-x-4">
                <div
                  className="h-12 w-12 rounded-full border border-gray-200 bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: booking?.is_sea
                      ? `url(/img/google-logo-240-be.png)`
                      : `url(${booking?.motive_url})`,
                    backgroundSize: "70%",
                  }}
                  alt=""
                />
                <div>
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {booking.title}
                  </p>
                  <p className="mt-1 flex text-sm leading-5 text-gray-500">
                    {moment(booking.start).format("DD.MM.YYYY")} -{" "}
                    {moment(booking.end).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-sm text-gray-500">
                  <CampaignStatusBadge status={booking.status} />
                </p>
              </div>
            </Link>
          </Card>
        ))}
      </div>
    </>
  );
};

export default BookingList;

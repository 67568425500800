import React from "react";

const UpdateErrorMessage = () => {
  return (
    <div className="my-4 bg-red-100 rounded-lg text-red-700 p-4 border border-red-700">
      <p className="font-bold">Kampagne konnte nicht freigegeben werden</p>
      <p className="text-sm">
        Leider gab es ein Problem beim Freigeben Ihrer Kampagne. Bitte versuchen
        Sie es später erneut.
      </p>
      <p className="text-sm">
        Sollte der Fehler länger auftreten, kontaktieren Sie bitte Ihren
        Ansprechpartner.
      </p>
    </div>
  );
};

export default UpdateErrorMessage;

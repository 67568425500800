import React, { useEffect, useState } from "react";
import Modal from "../../../components/modals/Modal";
import SnippetPreview from "./SnippetPreview";
import useSnippets from "../../../hooks/useSnippets";
import { Disclosure } from "@headlessui/react";
import ChevronUpIcon from "@heroicons/react/20/solid/ChevronUpIcon";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import { FormRow } from "./EditSeaAdModal";
import Input from "../../../components/inputs/Input";
import Button from "../../../components/buttons/Button";

const EditSnippetModal = ({ snippets, show, onClose, onSave }) => {
  const [snippetsToEdit, setSnippetsToEdit] = useState(snippets);
  useEffect(() => {
    if (snippets?.length > 0) {
      setSnippetsToEdit(snippets);
    }
  }, [snippets]);
  const { groupedSnippets } = useSnippets(snippetsToEdit);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(snippetsToEdit);
  };
  const handleUpdateSnippet = (position, name, value) => {
    setSnippetsToEdit((prev) =>
      prev.map((snippet) => {
        if (snippet.position === parseInt(position) && snippet.name === name) {
          return { ...snippet, value };
        }
        return { ...snippet };
      })
    );
  };

  const handleResetToDefault = () => {
    setSnippetsToEdit((prev) =>
      prev.map((snippet) => {
        return { ...snippet, value: snippet.default };
      })
    );
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="min-w-max max-w-3xl">
        <p className="font-bold">Snippets bearbeiten</p>
        <div className="my-4 border border-gray-200 rounded-lg p-4 max-w-3xl ">
          <SnippetPreview snippets={snippetsToEdit} />
        </div>
        <form onSubmit={handleSubmit}>
          <ol>
            {Object.keys(groupedSnippets).map((key, groupIndex) => {
              let snippetGroup = groupedSnippets[key];
              return (
                <li className="border-t border-gray-200 py-4 first:border-0">
                  <Disclosure defaultOpen={groupIndex === 0}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex items-center space-x-2">
                          <p className="font-bold text-sm">
                            {`Snippet ${groupIndex + 1} : ` +
                              (snippetGroup?.some((p) =>
                                p.name?.includes("header")
                              )
                                ? snippetGroup?.find((p) =>
                                    p.name?.includes("header")
                                  )?.value
                                : "")}
                          </p>
                          {open ? (
                            <ChevronUpIcon className="h-4 w-4" />
                          ) : (
                            <ChevronDownIcon className="h-4 w-4" />
                          )}
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-gray-500 divide-y divide-gray-200 space-y-2 mt-4">
                          <FormRow label={"Titel"}>
                            <p>
                              {
                                snippetGroup?.find((p) =>
                                  p.name?.includes("header")
                                )?.value
                              }
                            </p>
                          </FormRow>
                          {snippetGroup
                            .filter((snippet) =>
                              snippet.name?.includes("value")
                            )
                            .sort()
                            .map((snippet, index) => {
                              let value = snippet.value;
                              return (
                                <div className="pt-2" key={snippet.name}>
                                  <FormRow
                                    textColor={
                                      value?.length > 25 || value?.length === 0
                                        ? "text-red-500"
                                        : null
                                    }
                                    label={
                                      "Wert " +
                                      (index + 1) +
                                      " (" +
                                      value?.length +
                                      "/" +
                                      25 +
                                      ")"
                                    }
                                  >
                                    <Input
                                      value={value}
                                      onChange={(value) =>
                                        handleUpdateSnippet(
                                          key,
                                          snippet.name,
                                          value
                                        )
                                      }
                                    />
                                  </FormRow>
                                </div>
                              );
                            })}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </li>
              );
            })}
          </ol>
          <p className="p-4 text-sm text-gray-500 bg-warmgrey-50 mt-4 text-center rounded-lg">
            Das Bearbeiten der Snippets gilt global für alle Anzeigen.
          </p>

          <div className="mt-8 flex justify-end items-center w-full space-x-4">
            <div>
              <Button
                variant={"text"}
                label="Abbrechen"
                type="button"
                onClick={onClose}
              />
            </div>
            <div>
              <Button
                label="Ursprungsversion"
                type="button"
                onClick={handleResetToDefault}
                variant={"secondary"}
              />
            </div>
            <div>
              <Button
                label="Übernehmen"
                type="submit"
                disabled={snippetsToEdit.some(
                  (snippet) => !snippet.value || snippet.value.length > 25
                )}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditSnippetModal;

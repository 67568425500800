import React from "react";

const TextArea = ({
  value,
  onChange,
  placeholder,
  rows,
  name,
  defaultValue,
  disabled,
  required,
}) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <textarea
      id={name}
      name={name}
      defaultValue={defaultValue}
      rows={rows}
      required={required}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
      className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
        ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
        focus:ring-primary sm:text-sm sm:leading-6 ${disabled ? "bg-gray-100" : ""}`}
    />
  );
};

export default TextArea;

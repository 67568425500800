import { Fragment, useEffect, useState } from "react";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import AuthContext from "../../contexts/AuthContext";
import React, { useContext } from "react";
import AdminPanel from "../../components/AdminPanel";

import Logo from "../../img/stw3_logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const { auth, dispatchAuth } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.isAuthenticated && auth?.user) {
      setUser({
        name: auth?.user?.user?.first_name + " " + auth?.user?.user?.last_name,
        company: auth?.user?.customer?.company,
      });
    }
  }, [auth]);

  const navItems = [
    {
      name: "Dashboard",
      href: "/dashboard",
      current: location.pathname === "/dashboard",
    },
    /*     {
      name: "Angebote",
      href: "/offer",
      current: location.pathname === "/offer",
    }, */
    {
      name: "Display Kampagnen",
      href: "/display",
      current: location.pathname === "/display",
    },
    {
      name: "Suchmaschinen Kampagnen",
      href: "/search",
      current: location.pathname === "/search",
    },
  ];
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 xl:px-0">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/dashboard" className="w-32 block">
                    <img className="h-8 w-auto" src={Logo} alt="Stellwerk3" />
                  </Link>
                </div>
                {auth?.isAuthenticated && (
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {navItems.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "border-primary text-gray-900"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              {auth?.isAuthenticated && user && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <span className="text-gray-500 text-sm font-medium hidden sm:inline">
                    {user.company}
                  </span>
                  {/* Profile dropdown */}
                  <Popover as="div" className="relative ml-3">
                    <div>
                      <Popover.Button className="relative flex rounded-full bg-white text-sm focus:outline-none">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user Popover</span>
                        <UserCircleIcon
                          className="h-6 w-6 text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="absolute right-0 mt-2 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[100]">
                        <p className="truncate px-3.5 py-3" role="none">
                          <span
                            className="block text-xs text-gray-500"
                            role="none"
                          >
                            Angemeldet als
                          </span>
                          <span
                            className="mt-0.5 font-semibold text-sm"
                            role="none"
                          >
                            {user.name}
                          </span>
                        </p>
                        <hr />

                        <button
                          className="hover:bg-slate-50 block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          onClick={() => {
                            navigate("/profile");
                          }}
                        >
                          Profildaten
                        </button>

                        <button
                          className="hover:bg-slate-50 block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          onClick={() => {
                            dispatchAuth({ type: "LOGOUT" });
                            navigate("/login");
                          }}
                        >
                          Abmelden
                        </button>

                        <hr />
                        {auth?.user.is_admin ? (
                          <div className="px-3.5 py-3 bg-slate-50">
                            <AdminPanel />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
              )}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2">
              {navItems.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? "bg-slate-50 border-primary text-primary"
                      : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700",
                    "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

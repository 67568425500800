import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useChannelData } from "../../hooks/useChannelData";
import Card from "../../components/Card";
import useBannerColumns from "./tableColumns/useBannerColumns";
import Table from "../../components/Table";
import usePlacementColumns from "./tableColumns/usePlacementColumns";
import CreativeReport from "./components/CreativeReport";
import CreativePreviewModal from "../../components/modals/CreativePreviewModal";
import { StarIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

function ReportCreatives() {
  const { id } = useParams();
  const { data: bannerData, isLoading } = useChannelData(id);
  const { columns } = useBannerColumns();
  const { columns_placement } = usePlacementColumns();
  const [selectedCreative, setSelectedCreative] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [supportedPlacements, setSupportedPlacements] = useState([]);
  const [premiumPlacement, setPremiumPlacement] = useState([]);

  useEffect(() => {
    if (bannerData?.channels) {
      let adGroups = [];
      let socialAdGroups = [];
      for (const channel in bannerData.channels) {
        let channelData = bannerData.channels[channel];
        if (channelData.adgroups) {
          adGroups = adGroups.concat(channelData.adgroups);
        } else if (channelData.adsets) {
          socialAdGroups = socialAdGroups.concat(channelData.adsets);
        }
      }

      let ads = adGroups
        .flatMap((adGroup) => adGroup?.ads)
        ?.sort((a, b) => a.title?.localeCompare(b.title));

      var placements = ads.filter(
        (banner) =>
          banner.title !== "Billboard" &&
          banner.title !== "Mobile Medium-Rectangle"
      );

      placements = placements.reverse();
      placements = placements.sort((a, b) => {
        if (
          a.title === "Mobile 4:1/6:1 Banner" ||
          a.title === "Mobile Ad (320 x 50)"
        ) {
          return -1;
        } else if (
          b.title === "Mobile 4:1/6:1 Banner" ||
          b.title === "Mobile Ad (320 x 50)"
        ) {
          return 1;
        } else {
          return 0;
        }
      });
      setSupportedPlacements(placements);

      let premiumPlacement = ads.filter(
        (banner) =>
          banner.title === "Billboard" ||
          banner.title === "Mobile Medium-Rectangle"
      );
      if (
        premiumPlacement.length === 2 &&
        premiumPlacement[1].title !== "Billboard"
      ) {
        // Vertausche die Positionen, falls das erste Element nicht "Billboard" ist
        [premiumPlacement[1], premiumPlacement[0]] = [
          premiumPlacement[0],
          premiumPlacement[1],
        ];
      }

      setPremiumPlacement(premiumPlacement);

      /* setPremiumPlacement(
        ads.filter((banner) => banner.title === "Mobile Medium Rectangle")
      ); */
    }
  }, [bannerData]);

  // Sortiere die Banner für tabelle
  const sortedBanner = [...supportedPlacements, ...premiumPlacement];

  //Für die Webemittel anzeige
  var reversedBanner = [...supportedPlacements, ...premiumPlacement];
  reversedBanner = reversedBanner.reverse();

  const calculateSums = (banners) => {
    return banners.reduce(
      (acc, banner) => {
        acc.impressions += banner.impressions;
        acc.clicks += banner.clicks;
        return acc;
      },
      { impressions: 0, clicks: 0 }
    );
  };
  const premiumSums = calculateSums(premiumPlacement);
  const supportedSums = calculateSums(supportedPlacements);

  // Berechne die Klickrate für jede Gruppe
  const calculateClickRate = (sums) => sums.clicks / sums.impressions || 0;
  // Vorbereitung der Daten für die Tabelle

  const placementData = [
    {
      title: (
        <span className="font-semibold">
          Primärplatzierungen
          <StarIcon className="inline h-5 w-5 text-gray-500 ml-2" />
        </span>
      ),
      clicks: premiumSums.clicks,
      impressions: premiumSums.impressions,
      clickrate: calculateClickRate(premiumSums),
    },
    {
      title: "Unterstützende Platzierungen",
      clicks: supportedSums.clicks,
      impressions: supportedSums.impressions,
      clickrate: calculateClickRate(supportedSums),
    },
  ];

  return (
    <>
      <div className="pb-5 pt-5">
        <Card
          title={
            <span className="flex items-center">
              Platzierungen
              <div
                data-tooltip-id="my-tooltip"
                data-tooltip-html="Umfeld eines digitalen Werbemittels auf einem Werbeträger<br> 
                <b>Primärplatzierungen</b>
Einblendung von großformatigen 
aufmerksamkeitsstarken Werbemitteln
		<br> <br> 
   <b> Unterstützende Platzierungen:</b> Einblendung von mittelgroßen & kleinformatigen 
Werbeformaten oder Text/Bild-Kombinationen
 "
              >
                <BiInfoCircle className="h-6 w-6 ml-1 text-gray-500" />{" "}
              </div>
              <Tooltip
                className="my-tooltip-class"
                //#111827 gray 900
                //#6b7280 gray 500
                style={{
                  backgroundColor: "rgba(254, 254, 254, 1.5)",
                  opacity: "100%",
                  color: "#111827",
                  fontSize: "12px",
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
                id="my-tooltip"
              />
            </span>
          }
        >
          <div className="-mx-5 overflow-x-auto">
            <Table
              isLoading={isLoading}
              columns={columns_placement}
              data={placementData}
              cardPadding
              initialSortBy={{
                id: "impressions",
                desc: false,
              }}
            />
          </div>
        </Card>

        <div className="py-5">
          <Card title={"Bannerformate"}>
            <div className="-mx-5 overflow-x-auto">
              <Table
                isLoading={isLoading}
                columns={columns}
                data={sortedBanner}
              />
            </div>
          </Card>
        </div>
        <Card title={"Werbemittel"}>
          <div className="lg:divide-y divide-stw3-gray-200 space-y-4">
            {isLoading ? (
              <>
                {Array(4)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index} className="pt-4">
                      <CreativeReport isLoading={true} />
                    </div>
                  ))}
              </>
            ) : (
              reversedBanner.map((creative) => (
                <div key={creative.url} className="pt-4">
                  <CreativeReport
                    creative={creative}
                    onPreview={setSelectedCreative}
                  />
                </div>
              ))
            )}
          </div>
        </Card>
      </div>
      {selectedCreative && (
        <CreativePreviewModal
          creative={selectedCreative}
          onClose={() => setSelectedCreative(null)}
        />
      )}
    </>
  );
}

export default ReportCreatives;

import React, { useState, useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { updateUser } from "../hooks/useUserData";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const ProfileForm = () => {
  const { auth } = useContext(AuthContext);

  // Initialisiere den State für das zu bearbeitende Feld und die Formulardaten
  const [editingField, setEditingField] = useState(null);
  const [formData, setFormData] = useState({
    Anrede: auth?.user?.user.salutation,
    Vorname: auth?.user?.user.first_name,
    Nachname: auth?.user?.user.last_name,
    email: auth?.user?.user.email,
    password: null,
  });

  const [message, setMessage] = useState();
  const [errorMessage, setError] = useState();

  const SalutationForm = [
    { name: "Herr" },
    { name: "Frau" },
    { name: "Divers" },
  ];

  // Funktion zum Bearbeiten eines Feldes
  const handleEditClick = (field) => {
    setEditingField(field);
  };
  const handleCancel = () => {
    setEditingField(null);
  };

  // Funktion zum Aktualisieren der Formulardaten
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // Funktion zum Speichern der Änderungen
  const handleSave = (e, field) => {
    e.preventDefault();
    const updateData = {};
    if (field === "Anrede") {
      updateData.salutation = formData[field];
    } else if (field === "Vorname") {
      updateData.first_name = formData[field];
    } else if (field === "Nachname") {
      updateData.last_name = formData[field];
    } else if (field === "email") {
      updateData.email = formData[field];
    } else if (field === "password") {
      var alert_string = "";
      const setnewPassword = formData[field];
      if (setnewPassword.length < 8) {
        alert_string =
          alert_string + "Das Passwort muss mindestens 8 Zeichen lang sein! ";
      }
      if (!/[a-z]/.test(setnewPassword)) {
        alert_string =
          alert_string + "\n In ihrem Passwort fehlt ein Kleinbuchstabe!";
      }
      if (!/[A-Z]/.test(setnewPassword)) {
        alert_string =
          alert_string + "\n In ihrem Passwort fehlt ein Großbuchstabe!";
      }
      if (!/[0-9]/.test(setnewPassword)) {
        alert_string = alert_string + "\n In ihrem Passwort fehlt eine Zahl!";
      }
      if (!/[^a-zA-Z0-9]/.test(setnewPassword)) {
        alert_string =
          alert_string + "\n In ihrem Passwort fehlt ein Sonderzeichen!";
      }
      if (
        setnewPassword.includes(
          "(" || ")" || "=" || "[" || "]" || "{" || "}" || "/"
        )
      ) {
        alert_string =
          alert_string +
          "\n Das Passwort darf keine der folgenden Zeichen enthalten: (, ), =, [, ], {, }, /";
      }
      if (alert_string !== "") {
        alert(alert_string);
        return;
      }
      updateData.password = formData[field];
    }
    updateUser(auth.token, auth.user.user.id, updateData)
      .then((res) => {
        setMessage("Ihre Daten konnten erfolgreich geändert werden");
        setTimeout(() => {
          setMessage("");
        }, 5000);
      })
      .catch((error) => {
        console.error("Fehler beim Aktualisieren der Daten:", error);
        setError("Beim Speichern der Daten ist ein Fehler aufgetreten");
      });

    setEditingField(null);
  };

  /*   const handleSave = (e) => {
    e.preventDefault();
    //setEditingField(null);
    // Hier könnte die Logik zum Speichern der Daten hinzugefügt werden
    formData["password"] == null
      ? updateUser(auth.token, auth.user.user.id, {
          salution: formData["Anrede"],
          first_name: formData["Vorname"],
          last_name: formData["Nachname"],
          email: formData["email"],
        }).then((res) => {
          setMessage("Ihre Daten konnten erfolgreich geändert werden");
          setTimeout(() => {
            setMessage("");
          }, 5000);
        })
      : updateUser(auth.token, auth.user.user.id, {
          salution: formData["Anrede"],
          first_name: formData["Vorname"],
          last_name: formData["Nachname"],
          email: formData["email"],
          password: formData["password"],
        })
          .then((res) => {
            setMessage("Ihre Daten konnten erfolgreich geändert werden");
            setTimeout(() => {
              setMessage("");
            }, 5000);
          })
          .catch((error) => {
            console.error("Fehler beim Aktualisieren der Daten:", error);
            setMessage("Beim Speichern der Daten ist ein Fehler aufgetreten");
          });
  }; */
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleInputChange(e);
    }
  };

  return (
    <>
      {/*       <Disclosure className="border-b-0 shadow-sm">
        <div className="h-20">
          <img
            src={Logo}
            alt="Firmenlogo"
            className="mb-6 ml-24 w-40 h-20 object-contain"
          />
        </div>
      </Disclosure> */}
      <div className="max-w-7xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
        {message && (
          <div className="fixed top-4 right-4 mt-4 p-2 bg-green-100 text-gray-700 rounded-md shadow-lg z-40">
            {message}
          </div>
        )}
        {message && (
          <div className="fixed top-4 right-4 mt-4 p-2 bg-red-100 text-gray-700 rounded-md shadow-lg z-40">
            {errorMessage}
          </div>
        )}
        <h2 className="text-2xl font-semibold mb-4">Persönliche Daten</h2>
        <form>
          {["Anrede", "Vorname", "Nachname", "email", "password"].map(
            (field) => (
              <div
                key={field}
                className={`grid grid-cols-3 items-center justify-between my-6 pb-3 ${
                  field === "password" ? "border-transparent" : "border-b"
                } `}
              >
                <div className="flex flex-col col-span-1 items-start">
                  <label className="block text-sm font-medium text-gray-500 capitalize mt-1">
                    {field}
                  </label>
                </div>
                <div className="flex flex-col col-span-1 items-start">
                  {field === "Anrede" ? (
                    <select
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      disabled={editingField !== field}
                      className={`mt-1 block w-full max-w-xs px-3 py-2 border sm:text-sm sm:leading-6 ${
                        editingField === field
                          ? "block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-primary focus:ring-2 focus:ring-inset focus:ring-primary "
                          : "border-transparent text-gray-900 placeholder:text-black"
                      } `}
                    >
                      <option value="Herr">Herr</option>
                      <option value="Frau">Frau</option>
                      <option value="Divers">Divers</option>
                    </select>
                  ) : (
                    // <Field>
                    <input
                      type={field === "password" ? "password" : "text"}
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      disabled={editingField !== field}
                      className={`mt-1 block w-full max-w-xs px-3 py-2 border sm:text-sm sm:leading-6 ${
                        editingField === field
                          ? " block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-primary placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-primary"
                          : "border-transparent"
                      }`}
                    />
                    //  </Field>
                  )}
                </div>
                <div className="flex flex-col col-span-1 items-end">
                  <button
                    type="button"
                    onClick={() => handleEditClick(field)}
                    className="ml-2 p-2 mt-1 bg-white text-primary rounded-md  flex items-center space-x-2"
                  >
                    <PencilSquareIcon className="h-4 w-4" />{" "}
                    <span className=" ">Bearbeiten</span>
                  </button>
                </div>
                {editingField === field && (
                  <>
                    <div className="flex flex-col col-span-1 items-center"></div>
                    <div className="flex flex-col col-span-1 items-start ">
                      <div className="flex space-x-6">
                        <button
                          onClick={(e) => handleSave(e, field)}
                          className="mt-4 p-2 px-6 text-white bg-primary rounded-md hover:bg-primary"
                        >
                          Speichern
                        </button>
                        <button
                          //type="button"
                          onClick={handleCancel}
                          className="mt-4 p-2 px-6 bg-white text-gray-400 font-medium rounded-md hover:bg-gray-50"
                        >
                          Abbrechen
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col col-span-1 items-center"></div>
                  </>
                )}
              </div>
            )
          )}
        </form>
      </div>
    </>
  );
};

export default ProfileForm;

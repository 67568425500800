import React from "react";

const GenericErrorMessage = () => {
  return (
    <div
      className={`container  shadow-sm sm:rounded-lg  p-5 border border-primary bg-stw3-gray-100 `}
    >
      <div className="flex items-center justify-center p-4">
        <div className="text-center">
          <p className="font-bold">Unerwarteter Fehler</p>
          <p className="mt-2 text-sm">
            Leider können bestimmte Daten momentan nicht geladen
            <br />
            werden. Wir setzen alles daran, diese unvorhergesehene
            <br />
            Panne so schnell wie möglich zu beheben.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GenericErrorMessage;

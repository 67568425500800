/* eslint-disable no-unused-vars */
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
//import mobiletemplate from "./../../img/300_250.png";

const CreativeOverview = ({ ad, onClick }) => {
  //const [iframeSrc, setIframeSrc] = useState(ad.url); // Neue Zustandsvariable für den Iframe-`src`

  const iFrameWrapperRef = useRef(null);
  const iFrameScaleRef = useRef(null);

  const [height, setHeight] = useState(ad.height);
  const [width, setWidth] = useState(ad.width);

  const [percentageHeight, setPercentageHeight] = useState("100%");
  const [percentageWidth, setPercentageWidth] = useState("100%");
  const handleIframeLoad = (e) => {};

  useEffect(() => {
    if (iFrameWrapperRef.current && ad) {
      setPercentageWidth("100%");
      setPercentageHeight("100%");
      let factor = iFrameWrapperRef.current.clientWidth / width;
      if (height > width) {
        factor = iFrameWrapperRef.current.clientHeight / height;
      }
      if (factor > 1) {
        factor = 1;
      }
      setPercentageWidth(factor * width);
      setPercentageHeight(factor * height);

      iFrameScaleRef.current.style.transform = "scale(" + factor + ") ";
      iFrameScaleRef.current.style.transformOrigin = "top left";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iFrameWrapperRef, ad]);
  const [isMobileBanner, setIsMobileBanner] = useState(false);

  return (
    <div
      ref={iFrameWrapperRef}
      className="relative creative-ref"
      style={{
        height: percentageHeight,
        width: percentageWidth,
      }}
    >
      <div ref={iFrameScaleRef} className="relative creative-ref">
        <iframe
          scrolling="no"
          //key={new Date().getTime()}
          onLoad={handleIframeLoad}
          className=""
          src={ad.url}
          width={width}
          height={height}
          title={ad.title}
        ></iframe>
      </div>

      {onClick && (
        <button
          className="absolute -right-3 -top-3  bg-white p-1 rounded-full shadow-lg z-50"
          onClick={() => onClick(ad)}
        >
          <MagnifyingGlassIcon className="h-4 w-4  " />
        </button>
      )}
      {onClick && (
        <button
          className="absolute w-full h-full inset-0 z-40"
          onClick={() => onClick(ad)}
        />
      )}
    </div>
  );
};

export default CreativeOverview;

import axios from "axios";
import { useQuery } from "react-query";

export const useCustomer = (token, customerId) => {
  // useQuery-Hook, der die fetchData-Funktion aufruft
  return useQuery(["customer", customerId], () =>
    getCustomer(token, customerId)
  );
};

const getCustomer = async (token, customerId) => {
  // Token aus dem lokalen Speicher holen

  try {
    // HTTP GET-Anfrage mit Axios
    const { data } = await axios.get(
      `${process.env.REACT_APP_COREAPI_URL}/customers/${customerId}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error("Fehler beim Abrufen der Kampagnendaten", error);
    throw error; // Fehler werfen, um ihn in der aufrufenden Komponente zu behandeln
  }
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import useDailyColums from "./tableColumns/useDailyColums";
import { useChannelData } from "../../hooks/useChannelData";
import Card from "../../components/Card";
import useAdvertiserColumns from "./tableColumns/useAdvertiserColumns";
import Table from "../../components/Table";
import { useReach } from "../../hooks/useReach";
import { useDailyInfo } from "../../hooks/useDailyInfo";
import { useDeviceData } from "../../hooks/useDeviceData";
import DevicePieChart from "./components/DevicePieChart";
import { useGoalSetting } from "../../hooks/useGoalSetting";
import useGoalColumns from "./tableColumns/useGoalColumns";
import Spinner from "../../components/loading/Spinner";

function ReportAdvertiser() {
  const { id } = useParams();
  const { data: deviceData, isLoading: devicesLoading } = useDeviceData(id);
  const { data: campaignData, isLoading: campaignLoading } =
    useSingleCampaignData(id);
  const { data: goalData, isLoading: goalLoading } = useGoalSetting(id);

  const { data: channelData, isLoading: channelDataLoading } =
    useChannelData(id);
  const { data: reachData, isLoading: reachLoading } = useReach(
    id,
    `?start=${campaignData?.start}&end=${campaignData?.end}`
  );
  const { data: dailyData, isLoading: dailyDataLoading } = useDailyInfo(
    id,
    `?start=${campaignData?.start}&end=${campaignData?.end}`
  );
  const [advertiserData, setAdvertiserData] = useState([]);
  const { columns } = useAdvertiserColumns(advertiserData?.length);
  const { dailycolumns } = useDailyColums();
  const { goalcolumns } = useGoalColumns();
  const [dailyInfo, setDailyInfo] = useState([]);
  const [deviceDistribution, setDeviceDistribution] = useState([]);
  const [goal, setGoal] = useState([]);

  useEffect(() => {
    if (channelData && campaignData && goalData) {
      let data = [];
      for (const channel in channelData?.channels) {
        console.log(campaignData.targeting);
        // Standardwerte setzen, falls beide Null sind
        if (
          campaignData?.targeting?.age?.from === null &&
          campaignData?.targeting?.age?.to === null
        ) {
          campaignData.targeting.age.from = 18;
          campaignData.targeting.age.to = 100;
        }

        // Konvertierung in Zahlen
        const fromAge = parseInt(campaignData?.targeting?.age?.from, 10);
        const toAge = parseInt(campaignData?.targeting?.age?.to, 10);

        // Alters-Text definieren
        let ageText;
        if (toAge === 100 || toAge === 65) {
          ageText = `Alter: ab ${fromAge}`;
        } else {
          ageText = `Alter: ${fromAge}-${toAge}`;
        }

        // Titel bestimmen
        let title;
        if (channel === "fb") {
          title = "Facebook";
        } else if (channel === "ig") {
          title = "Instagram";
        } else {
          title = "Premium Local Audience";
        }

        // Daten hinzufügen
        data.push({
          ...channelData?.channels[channel],
          title: title,
          age: ageText,
        });
      }

      console.log(campaignData.targeting);
      let transformedData = [];
      let devices = Object.values(deviceData?.adn ?? {})[0];
      for (const device in devices) {
        let allClicks = devices["mobile"].clicks + devices["desktop"]?.clicks;
        let allImpressions =
          devices["mobile"]?.impressions + devices["desktop"]?.impressions;

        let mobileClicks = devices["mobile"].clicks / allClicks;
        let mobileImpressions = devices["mobile"].impressions / allImpressions;

        let desktopClicks = devices["desktop"].clicks / allClicks;
        let desktopImpressions =
          devices["desktop"].impressions / allImpressions;
        data.push({
          ...deviceData?.adn[device],
          title: device,
          clicks:
            device === "mobile"
              ? Math.round(campaignData?.sum?.clicks * mobileClicks)
              : Math.round(campaignData?.sum?.clicks * desktopClicks), //devices[device].clicks,
          impressions:
            device === "mobile"
              ? Math.round(campaignData.sum?.impressions * mobileImpressions)
              : Math.round(campaignData.sum?.impressions * desktopImpressions), // devices[device].impressions,
          clickrate: devices[device].ctr,
        });

        transformedData.push({
          ...deviceData?.adn[device],
          device: device,
          clicks:
            device === "mobile"
              ? Math.round(campaignData?.sum?.clicks * mobileClicks)
              : Math.round(campaignData?.sum?.clicks * desktopClicks), //devices[device].clicks,
          impressions:
            device === "mobile"
              ? Math.round(campaignData.sum?.impressions * mobileImpressions)
              : Math.round(campaignData.sum?.impressions * desktopImpressions), // devices[device].impressions,
          clickrate: devices[device].ctr,
        });
      }
      setAdvertiserData(data);
      setDeviceDistribution(transformedData);
    }
    let goalDataArray = [];

    //Das macht so keinen sinn!
    //channel nochmal komplett überprüfen!
    for (let channel in channelData?.channels) {
      if (
        campaignData?.targeting?.age?.from === null &&
        campaignData?.targeting?.age?.to === null
      ) {
        campaignData.targeting.age.from = "ab 18 ";
        campaignData.targeting.age.to = "";
      }
      if (goalData?.total?.target) {
        goalDataArray.push({
          title:
            channel === "fb"
              ? "Facebook"
              : channel === "ig"
              ? "Instagram"
              : "Premium Local Audience",
          impressions: campaignData?.sum?.impressions,
          goal: goalData?.total?.target,
          difference: campaignData?.sum?.impressions - goalData?.total?.target,
          differencePercentage:
            (campaignData?.sum?.impressions / goalData?.total?.target) * 100,
        });
      }

      setGoal(goalDataArray);
    }
  }, [channelData, campaignData, deviceData, goalData]);

  useEffect(() => {
    if (dailyData?.adn && reachData) {
      let reach = Object.values(reachData.adn)[0];
      let daily = Object.values(dailyData.adn)[0];
      const mergedData = daily.map((dailyItem) => {
        const reachItem = reach.find(
          (reachEl) => reachEl.day === dailyItem.day
        );
        return {
          ...dailyItem,
          ...(reachItem && { reach: reachItem.reach }),
        };
      });
      setDailyInfo(mergedData);
    }
  }, [dailyData, reachData]);

  useEffect(() => {
    if (deviceData) {
      let transformedData = [];
      let data = Object.values(deviceData.adn)[0];
      for (const device in data) {
        transformedData.push({
          device: device,
          ...data[device],
        });
      }
      //setDeviceDistribution(transformedData);
    }
  }, [deviceData]);

  return (
    <div className="space-y-4">
      <Card title={<span className="flex items-center">Kanäle</span>}>
        <div className="-mx-5 overflow-x-auto">
          <Table
            columns={columns}
            data={advertiserData}
            isLoading={channelDataLoading}
            cardPadding
            initialSortBy={{
              id: "impressions",
              desc: true,
            }}
          />
        </div>
      </Card>
      <Card title="Endgeräte">
        {devicesLoading ||
        campaignLoading ||
        channelDataLoading ||
        goalLoading ? (
          <div className="flex items-center justify-center w-full h-[400px]">
            <Spinner />
          </div>
        ) : (
          <div className="grid md:grid-cols-2 gap-4 pt-8">
            <>
              <DevicePieChart
                data={deviceDistribution}
                dataKey={"impressions"}
                title="Sichtkontakte"
              />
              <DevicePieChart
                data={deviceDistribution}
                dataKey={"clicks"}
                title="Klicks"
              />
            </>

            {/* Darauf achten grid col auf 3 zu setzen 
          <DevicePieChart
            data={deviceDistribution}
            dataKey={"ctr"}
            title="Klickrate"
          /> */}
          </div>
        )}
      </Card>

      <Card title={"Tage"}>
        <div className="-mx-5 overflow-x-auto">
          <Table
            isLoading={reachLoading || dailyDataLoading}
            columns={dailycolumns}
            data={dailyInfo}
            cardPadding
            initialSortBy={{
              id: "day",
              desc: false,
            }}
          />
        </div>
      </Card>
      {goal?.length > 0 ? (
        <Card title={"Soll/Ist Abgleich Sichtkontakte"}>
          <div className="-mx-5 overflow-x-auto">
            <Table
              isLoading={reachLoading || dailyDataLoading || goalLoading}
              columns={goalcolumns}
              data={goal}
              cardPadding
            />
          </div>
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ReportAdvertiser;

import React, { useContext } from "react";
import { useCampaignData } from "../../hooks/useCampaignData";
import AuthContext from "../../contexts/AuthContext";
import CampaignList from "./campaignComponents/CampaignList";

const Campaigns = () => {
  const { auth } = useContext(AuthContext);
  const { data: allCampaigns, isLoading: allCampaignsLoading } =
    useCampaignData(auth.token);

  const allDisplayCampaigns = allCampaigns?.data?.filter(
    (campaign) => campaign.seaOnly !== true
  );
  const allSearchCampaigns = allCampaigns?.data?.filter(
    (campaign) => campaign.seaOnly === true
  );

  return (
    <>
      <div className="mt-8">
        <h1 className="font-bold text-xl mt-8">Ihre Display Kampagnen</h1>

        <div className="mt-4 md:mt-8">
          <CampaignList
            campaigns={allDisplayCampaigns ?? []}
            isLoading={allCampaignsLoading}
            seaOnly={"true"}
          />
        </div>
        <h1 className="font-bold text-xl mt-8">
          Ihre Suchmaschinen-Marketing Kampagnen
        </h1>
        <div className="mt-4 md:mt-8">
          <CampaignList
            campaigns={allSearchCampaigns ?? []}
            isLoading={allCampaignsLoading}
            seaOnly={"true"}
          />
        </div>
      </div>
    </>
  );
};

export default Campaigns;

import React from "react";
import useFormatter from "../../../hooks/useFormatter";
import { Tooltip } from "react-tooltip";

const useAdvertiserColumns = (rowCount) => {
  const { decimalFormatter } = useFormatter();

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "title",
        Cell: ({ value }) =>
          value === "mobile" || value === "desktop"
            ? (value = <span className="indent-1 pl-3">{value}</span>)
            : (value = value),
        /* Footer: () => (
          <p className="font-bold text-sm">{rowCount} Werbeträger</p>
        ), */
      },
      {
        Header: "Zielgruppe",
        accessor: "age",
        Cell: ({ value }) => value,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Anzahl von Klicks auf das Werbemittel"
            >
              Klicks
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "clicks",
        align: "right",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Anzahl der Einblendungen des 
digitalen Werbemittels 
"
            >
              Sichtkontakte
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "impressions",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        align: "right",
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Das Verhältnis von Klicks zu Sichtkontakten
"
            >
              Klickrate
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "clicks",
        id: "clickrate",
        Cell: ({ row, value }) =>
          value && row.original.impressions
            ? ((value / row.original.impressions) * 100)
                ?.toFixed(2)
                .replace(/\./g, ",") + " %"
            : "-",
        align: "right",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowCount]
  );

  return { columns };
};

export default useAdvertiserColumns;

import React, { useContext } from "react";
import NavBar from "../components/navbar/NavBar";
import { Outlet } from "react-router-dom";
import { useUser } from "../hooks/useUserData";
import AuthContext from "../contexts/AuthContext";
import Spinner from "../components/loading/Spinner";

const OpenRoutes = () => {
  const { auth } = useContext(AuthContext);

  // verify if valid token was in local storage
  const { isLoading } = useUser(auth.token);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <NavBar />
      <div className="container mx-auto my-2 pb-12">
        <Outlet />
      </div>
    </>
  );
};

export default OpenRoutes;

import { React, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import MapComponent from "../MapComponent";
import useServiceAPI from "../../hooks/useServiceAPI";
import Spinner from "../loading/Spinner";
import { bbox } from "@turf/turf";
import mapboxgl from "mapbox-gl";
import ReactDOMServer from "react-dom/server";
import useFormatter from "../../hooks/useFormatter";
import useScreenSize from "../../hooks/useScreenSize";
import moment from "moment";

const CampaignMap = ({ height, roundedBottom, zoomUi }) => {
  const ref = useRef(null);
  const { id } = useParams();
  const { data: campaignData, isLoading } = useSingleCampaignData(id);
  const { getGeoData } = useServiceAPI();
  const geoData = useRef(null);
  const hoveredPolygonId = useRef(null);
  const { isWindowSmallerThan } = useScreenSize();

  const handleMapReset = () => {
    if (geoData.current && ref.current) {
      let boundingBox = bbox(geoData.current);
      ref.current.fitBounds(boundingBox, {
        padding: 20,
      });
    }
  };
  if (isLoading)
    return (
      <div
        className={`w-full flex items-center justify-center  bg-white ${
          roundedBottom ? "rounded-sm" : "rounded-sm"
        } `}
        style={{ height: height + "px" }}
      >
        <Spinner />
      </div>
    );
  const handleMapLoad = () => {
    if (ref.current) {
      var popup = new mapboxgl.Popup({
        closeButton: false,
      });
    }

    getGeoData(campaignData.geo.data).then((response) => {
      let forceDatabasePopulation = moment(campaignData?.start).isBefore(
        "2024-08-12"
      );
      let geoJsonData = {
        type: "FeatureCollection",
        features: response.data.map((postcode) => {
          let campaignPostcode = campaignData.geo.data.find(
            (c) => c.postcode === postcode.postcode
          );
          return {
            type: "Feature",
            id: postcode.postcode,
            properties: {
              plz: postcode.postcode,
              name: postcode.name,
              population:
                forceDatabasePopulation && campaignPostcode?.population
                  ? campaignPostcode.population
                  : postcode.population,
              population_online:
                forceDatabasePopulation && campaignPostcode?.population_online
                  ? campaignPostcode.population_online
                  : postcode.population_online,
            },
            geometry: JSON.parse(postcode.json),
          };
        }),
      };
      geoData.current = geoJsonData;
      ref.current.addSource("main", { type: "geojson", data: geoJsonData });

      ref.current.addLayer({
        id: "main-fill",
        type: "fill",
        source: "main", // reference the data source
        paint: {
          "fill-color": "#671B34",
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.5,
            0.25,
          ],
        },
      });
      ref.current.addLayer({
        id: "main-line",
        type: "line",
        source: "main",
        paint: {
          "line-color": "#671B34",
          "line-width": 0.7,
          "line-opacity": 0.7,
        },
      });
      const setPopup = (e) => {
        if (e.features.length > 0) {
          popup
            .setLngLat(e.lngLat)
            .setHTML(
              ReactDOMServer.renderToString(
                <PostalCodePopup feature={e.features[0]} />
              )
            )
            .addTo(ref.current);

          if (hoveredPolygonId.current !== null) {
            ref.current.setFeatureState(
              { source: "main", id: hoveredPolygonId.current },
              { hover: false }
            );
          }
          hoveredPolygonId.current = e.features[0].id;
          ref.current.setFeatureState(
            { source: "main", id: hoveredPolygonId.current },
            { hover: true }
          );
        }
      };

      if (isWindowSmallerThan("md")) {
        ref.current.on("click", "main-fill", (e) => {
          setPopup(e);
        });
      } else {
        ref.current.on("mousemove", "main-fill", (e) => {
          setPopup(e);
        });
        ref.current.on("mouseleave", "main-fill", () => {
          popup.remove();

          if (hoveredPolygonId.current !== null) {
            ref.current.setFeatureState(
              { source: "main", id: hoveredPolygonId.current },
              { hover: false }
            );
          }
          hoveredPolygonId.current = null;
        });
      }

      // When the user moves their mouse over the state-fill layer, we'll update the
      // feature state for the feature under the mouse.

      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.

      var boundingBox = bbox(geoJsonData);
      ref.current.fitBounds(boundingBox, {
        padding: 20,
      });
    });
  };

  return (
    <div>
      <MapComponent
        zoomUi={zoomUi}
        onMapReset={handleMapReset}
        ref={ref}
        lati={campaignData.customer.lat}
        long={campaignData.customer.lng}
        className="rounded"
        height={height}
        onLoad={handleMapLoad}
        roundedBottom={roundedBottom}
      />
    </div>
  );
};
export default CampaignMap;

const PostalCodePopup = ({ feature }) => {
  const { decimalFormatter } = useFormatter();
  const properties = feature.properties;
  return (
    <div className="text-sm opacity-80">
      <p className="text-base ">{properties?.plz + " " + properties?.name}</p>
      <div className="flex justify-between space-x-4 mt-4">
        <p className="">Einwohner</p>
        <p className="font-bold">
          {decimalFormatter.format(properties?.population)}
        </p>
      </div>

      {/* <div className="flex justify-between space-x-4 ">
        <p className="">Online</p>
        <p className="font-bold">
          {decimalFormatter.format(properties?.population_online)}
        </p>
      </div> */}
    </div>
  );
};

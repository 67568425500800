import React, { useEffect, useState } from "react";
import "../css/Timeline.css";
import moment from "moment";
import useFormatter from "../hooks/useFormatter";
import LoadingBar from "./loading/LoadingBar";

const GoalLine = ({ impressions, goalImpressions, isLoading }) => {
  const [progress, setProgress] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const { decimalFormatter } = useFormatter();

  useEffect(() => {
    const percent = (impressions / goalImpressions) * 100;
    setProgress(percent);
  }, [impressions, goalImpressions]);

  return (
    <div className="mt-3">
      <span className="text-gray-500 text-sm"> Sichtkontakte</span>
      <div
        className="timeline-container mt-1 mb-1"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`timeline-progress ${isLoading && "animate-pulse"}`}
          style={{ width: `${isLoading ? 60 : progress}%` }}
        ></div>
        {isHovered && (
          <div className="tooltip">{decimalFormatter.format(impressions)}</div>
        )}
      </div>
      <div className="flex w-full justify-between text-sm ">
        <div className=" ">
          0
          {isLoading ? (
            <LoadingBar height="h-5" width={"w-32"} />
          ) : (
            <span></span>
          )}
        </div>
        <div className="text-right">
          {isLoading ? (
            <LoadingBar height="h-5" width={"w-32"} />
          ) : (
            <span className="">{decimalFormatter.format(goalImpressions)}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoalLine;

import React, { useEffect, useState } from "react";
import "../css/Timeline.css";
import moment from "moment";
import LoadingBar from "./loading/LoadingBar";

const Timeline = ({ startDate, endDate, isLoading }) => {
  const [progress, setProgress] = useState(0);
  const [remainingDays, setRemainingDays] = useState(0);
  useEffect(() => {
    const start = moment(startDate);
    const end = moment(endDate);
    const now = moment();
    const compareToday = moment().format("YYYY-MM-DD");
    const compareEnd = moment(endDate).format("YYYY-MM-DD");
    // const compareStart = moment(startDate).format("YYYY-MM-DD");
    const runtime = end.diff(start, "days");
    const elapsedDays = now.diff(start, "days");
    const daysToGo = end.diff(now, "days") + 1;

    if (now.isBefore(start)) {
      setRemainingDays(0);
      setProgress(0);
    } else if (now.isAfter(end)) {
      setRemainingDays(0);
      setProgress(100);
    } else if (compareToday == compareEnd) {
      setProgress(97);
    } /* else if (compareToday == compareStart) {
      setProgress(3);
    }  */ else {
      const percent = (elapsedDays / runtime) * 100;
      setRemainingDays(daysToGo);
      setProgress(percent);
    }
  }, [startDate, endDate]);

  return (
    <div>
      <span className="text-gray-500 text-sm"> Laufzeit</span>
      <div className="timeline-container mt-1 mb-1">
        <div
          className={`timeline-progress ${isLoading && "animate-pulse"}`}
          style={{ width: `${isLoading ? 60 : progress}%` }}
        ></div>
      </div>
      <div className="flex w-full justify-between text-sm">
        <div className=" ">
          {isLoading ? (
            <LoadingBar height="h-5" width={"w-32"} />
          ) : (
            <span className="text-sm">
              {moment(startDate).format("DD.MM.YYYY")}
            </span>
          )}
        </div>
        {false && (
          <div className="text-gray-500 ">
            Restlaufzeit:
            <span className="text-gray-500"> {remainingDays} Tage </span>
          </div>
        )}
        <div className=" text-right">
          {isLoading ? (
            <LoadingBar height="h-5" width={"w-32"} />
          ) : (
            <span className="">{moment(endDate).format("DD.MM.YYYY")}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Timeline;

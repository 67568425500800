import CampaignStatusBadge from "../../../components/badges/CampaignStatusBadge";
import Card from "../../../components/Card";
import Spinner from "../../../components/loading/Spinner";
import BookingList from "./BookingList";

const DashboardBookingItem = ({ bookings, isLoadingBookings }) => {
  if (isLoadingBookings) {
    return (
      <Card className="mt-4 flex items-center px-6 py-6 bg-white animate-pulse">
        <div className="w-1/3">
          <div className="h-4 w-24 leading-6 rounded-lg bg-slate-200 mb-3" />
          <div className="mt-1 h-3 w-32 leading-5 rounded-lg bg-slate-200" />
        </div>
        <div className="flex w-1/3 items-center justify-center">
          <Spinner />
          <p className="text-slate-700 text-sm ml-2">
            Prüfe auf bereitstehende Freigaben...
          </p>
        </div>
        <div className="flex w-1/3 justify-end">
          <CampaignStatusBadge status={"in_coordination"} />
        </div>
      </Card>
    );
  }

  return (
    <>
      {bookings?.length > 0 ? (
        <div className="mt-4">
          <BookingList bookings={bookings} isLoading={isLoadingBookings} />
        </div>
      ) : (
        <Card className="mt-4 flex justify-center items-center px-6 py-9 bg-white">
          <div>
            <p className="text-sm leading-6 text-gray-400">
              Sie haben derzeit keine Kampagnen zur Freigabe.
            </p>
          </div>
        </Card>
      )}
    </>
  );
};

export default DashboardBookingItem;

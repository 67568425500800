import React from "react";
import Card from "../../../components/Card";
import LoadingBar from "../../../components/loading/LoadingBar";
const KpiCard = ({ label, kpi, icon, description, isLoading }) => {
  return (
    <Card>
      <div>
        <div className="flex justify-between">
          <div>
            <p className="text-gray-500 text-sm ">{label}</p>
            <div className="mt-2">
              {isLoading ? (
                <LoadingBar height={"h-8"} width={"w-20"} type={"headline"} />
              ) : (
                <p className="text-3xl normal">{kpi}</p>
              )}
            </div>
          </div>
          {React.cloneElement(icon, { className: "h-8 w-8 text-primary" })}
        </div>
        <div className="mt-8">
          {isLoading ? (
            <LoadingBar height={"h-6"} width={"w-64"} />
          ) : (
            <p className="text-sm ">{description}</p>
          )}
        </div>
      </div>
    </Card>
  );
};

export default KpiCard;

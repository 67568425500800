import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../img/stw3_logo.png";
import AuthContext from "../contexts/AuthContext";
import axios from "axios";
import Button from "../components/buttons/Button";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { dispatchAuth } = useContext(AuthContext);
  const location = useLocation();

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_COREAPI_URL;
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      axios
        .post(`${apiUrl}/login`, { username: username, password: password })
        .then((response) => {
          if (response?.data?.success) {
            dispatchAuth({
              type: "LOGIN",
              payload: response?.data?.data,
            });
            if (location?.state?.from) {
              navigate(location?.state?.from);
              return;
            }
            navigate("/");
          } else {
            alert("Fehler beim login");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            alert("Bitte überprüfen Sie Ihre Zugangsdaten");
          } else {
            alert("Fehler beim login. Bitte versuchen Sie es später erneut");
          }
        });
    } catch (error) {
      alert("Fehler beim login. Bitte versuchen Sie es später erneut");
    }
  };

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center ">
      <div className="px-8 sm:px-0 sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className=" text-center text-2xl font-bold leading-9 mx-auto h-10 w-auto"
          src={Logo}
          alt="Stellwerk3 GmbH"
        />
        <form onSubmit={handleSubmit}>
          <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-sm">
            <label
              className="block text-sm font-medium leading-6 text-gray-900"
              htmlFor="email"
            >
              Email Addresse
            </label>
            <input
              id="username"
              type="text"
              placeholder="Ihre email Adresse..."
              className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label
              className="block text-sm font-medium leading-6 text-gray-900"
              htmlFor="password"
            >
              Passwort
            </label>
            <input
              id="password"
              type="password"
              placeholder="Ihr Passwort..."
              className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between mt-6">
            <Button label="Einloggen" type="submit" />
            <div className="text-primary">
              <Link to="/passwordReset/forgotpassword">
                Passwort vergessen?
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;

import React, { useMemo } from "react";
import useFormatter from "../../hooks/useFormatter";
const SummationFooter = ({ rows, kpi }) => {
  const { decimalFormatter } = useFormatter();
  const total = useMemo(
    () =>
      rows?.reduce((sum, row) => (parseInt(row?.values[kpi]) ?? 0) + sum, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows]
  );

  return (
    <div>
      <p className="font-bold">
        {total ? decimalFormatter.format(total) : "-"}
      </p>
    </div>
  );
};

export default SummationFooter;

import React, { useEffect, useState } from "react";
import Modal from "../../../components/modals/Modal";
import SitelinkPreview from "./SitelinkPreview";
import { Disclosure } from "@headlessui/react";
import ChevronUpIcon from "@heroicons/react/20/solid/ChevronUpIcon";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import { FormRow } from "./EditSeaAdModal";
import Input from "../../../components/inputs/Input";
import Button from "../../../components/buttons/Button";

const EditSitelinkModal = ({
  show,
  onClose,
  onSave,
  sitelinks,
  selectedSitelinkIndex,
}) => {
  const [sitelinksToEdit, setSitelinksToEdit] = useState(sitelinks);

  useEffect(() => {
    if (sitelinks) {
      setSitelinksToEdit(sitelinks);
    }
  }, [sitelinks, show]);

  const handleUpdateSitelink = (index, key, value) => {
    setSitelinksToEdit((prev) => {
      let newGrouped = [...prev];
      newGrouped[index] = newGrouped[index].map((p) => {
        if (p.name === key) {
          return { ...p, value };
        }
        return p;
      });
      return newGrouped;
    });
  };

  const sitelinkAttributes = [
    { label: "Überschrift", name: "sitelink_title", maxLength: 25 },
    { label: "Textzeile 1", name: "sitelink_text_1", maxLength: 35 },
    { label: "Textzeile 2", name: "sitelink_text_2", maxLength: 35 },
    { label: "Deeplink", name: "sitelink_deeplink" },
  ];

  const handleResetToDefault = () => {
    setSitelinksToEdit((prev) => {
      return prev.map((sitelink) =>
        sitelink.map((p) => {
          if (p.default) {
            return { ...p, value: p.default };
          }
          return p;
        })
      );
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(sitelinksToEdit);
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="min-w-max max-w-3xl">
        <p className="font-bold">Sitelinks bearbeiten</p>
        <div className="my-4 border border-gray-200 rounded-lg p-4 max-w-3xl ">
          <SitelinkPreview sitelinks={sitelinksToEdit} />
        </div>
        <form onSubmit={handleSubmit}>
          <ol className="">
            {sitelinksToEdit?.map((sitelink, index) => (
              <li
                key={"sitelink" + index}
                className="border-t border-gray-200 py-4 first:border-0"
              >
                <Disclosure
                  defaultOpen={index === parseInt(selectedSitelinkIndex)}
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center space-x-2">
                        <p className="font-bold text-sm">
                          {`Sitelink ${index + 1} : ` +
                            sitelink?.find((p) => p.name === "sitelink_title")
                              ?.value}
                        </p>
                        {open ? (
                          <ChevronUpIcon className="h-4 w-4" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4" />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel className="text-gray-500 divide-y divide-gray-200 space-y-2 mt-4">
                        {sitelinkAttributes.map((attribute) => {
                          let value = sitelink.find(
                            (p) => p.name === attribute.name
                          )?.value;
                          return (
                            <div className="pt-2" key={attribute.label + index}>
                              <FormRow
                                textColor={
                                  value?.length > attribute.maxLength || value?.length === 0
                                    ? "text-red-500"
                                    : null
                                }
                                label={
                                  attribute?.maxLength
                                    ? `${attribute.label} (${value?.length}/${attribute.maxLength})`
                                    : attribute.label
                                }
                              >
                                <Input
                                  value={value}
                                  onChange={(value) =>
                                    handleUpdateSitelink(
                                      index,
                                      attribute.name,
                                      value
                                    )
                                  }
                                />
                              </FormRow>
                            </div>
                          );
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </li>
            ))}
          </ol>
          <p className="p-4 text-sm text-gray-500 bg-warmgrey-50 mt-4 text-center rounded-lg">
            Das Bearbeiten der Sitelinks gilt global für alle Anzeigen.
          </p>

          <div className="mt-8 flex justify-end items-center w-full space-x-4">
            <div>
              <Button
                variant={"text"}
                label="Abbrechen"
                type="button"
                onClick={onClose}
              />
            </div>
            <div>
              <Button
                label="Ursprungsversion"
                type="button"
                onClick={handleResetToDefault}
                variant={"secondary"}
              />
            </div>
            <div>
              <Button
                label="Übernehmen"
                type="submit"
                disabled={sitelinksToEdit.some((p) =>
                  p.some(
                    (param) =>
                      !param.value ||
                      param.value.length >
                      sitelinkAttributes.find((a) => a.name === param.name)
                        ?.maxLength
                  )
                )}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditSitelinkModal;

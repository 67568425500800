import React, { useContext } from "react";
import Card from "../../../components/Card";
import LoadingBar from "../../../components/loading/LoadingBar";
import moment from "moment";
import AuthContext from "../../../contexts/AuthContext";

const Header = ({ isLoading, booking }) => {
  const { auth } = useContext(AuthContext);
  return (
    <div className="md:grid grid-cols-3 gap-8 mt-8 space-y-4 md:space-y-0">
      <div className="col-span-2">
        <Card className={"h-full"}>
          {isLoading ? (
            <div>
              <LoadingBar height={"h-9"} width={"w-1/4"} type={"headline"} />
              <div className="mt-2">
                <LoadingBar height={"h-4"} width={"w-2/3"} type={"headline"} />
              </div>
              <div className="flex items-center space-x-4 text-base font-medium mt-4">
                <span className="text-gray-500">Laufzeit:</span>
                <LoadingBar height={"h-4"} width={"w-32"} />
              </div>
            </div>
          ) : (
            <>
              <div className="flex items-center space-x-4">
                {booking?.is_sea && (
                  <div
                    className="h-10 w-10 rounded-full border border-gray-200 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(/img/google-logo-240-be.png)`,
                      backgroundSize: "70%",
                    }}
                    alt=""
                  />
                )}
                <div className="flex flex-col">
                  <p className="font-bold text-xl">{booking?.company}</p>
                  <p className="font-semibold text-base">{booking?.title}</p>
                </div>
              </div>
              <p className="sm:flex items-center sm:space-x-4 text-base font-medium mt-4">
                <div className="text-gray-500">Laufzeit:</div>
                <div>
                  {moment(booking?.start).format("DD.MM.YYYY") +
                    " - " +
                    moment(booking?.end).format("DD.MM.YYYY")}
                </div>
              </p>
              {booking?.is_sea ? (
                <p className="whitespace-pre-line text-gray-500 mt-4">
                  {auth?.isAuthenticated
                    ? `Wir haben für Ihre Suchmaschinen-Kampagne optimal angepasste Anzeigenentwürfe erstellt.\n Sie können diese hier einsehen, bei Bedarf anpassen und freigeben.`
                    : "Wir haben für Ihre Suchmaschinen-Kampagne optimal angepasste Anzeigenentwürfe erstellt.\n Sie können diese hier einsehen."}
                </p>
              ) : (
                <div className="sm:flex sm:space-x-4  text-base font-medium mt-4">
                  <div className="text-gray-500">Deeplink:</div>
                  <a
                    href={booking?.deeplink}
                    target="_blank"
                    rel="noreferrer"
                    className="break-words whitespace-pre-line max-w-[80%]"
                  >
                    {booking?.deeplink}
                  </a>
                </div>
              )}
            </>
          )}
        </Card>
      </div>
      <div className="h-full">
        <Card className={"relative h-full"}>
          <div className="flex flex-col justify-between h-full">
            <p className="text-xl font-bold">Zeitplan</p>
            <div className="h-2/3 w-4 bg-[#E3E3E3] rounded-t-full absolute bottom-0 ml-2 z-10"></div>
            <div className="mt-8 ">
              <div className="h-8 w-8 bg-[#F2F2F2] rounded-full z-50 shadow-lg absolute "></div>
              <div className="h-8 w-8 bg-primary rounded-full z-50 shadow-lg absolute mt-16"></div>
              <div className="ml-12">
                <div className="text-base">
                  {isLoading ? (
                    <LoadingBar width="w-32" height="h-6" type={"headline"} />
                  ) : (
                    <p className="font-bold ">
                      bis{" "}
                      {booking?.approval_date
                        ? moment(booking.approval_date).format("DD.MM.YYYY")
                        : "-"}
                    </p>
                  )}
                  <p className="font-medium">Werbemittel freigeben</p>
                </div>
                <div className="text-base mt-4">
                  {isLoading ? (
                    <LoadingBar width="w-32" height="h-6" type={"headline"} />
                  ) : (
                    <p className="font-bold ">
                      {moment(booking?.start).format("DD.MM.YYYY")}
                    </p>
                  )}
                  <p className="font-medium">Kampagnenstart</p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Header;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import {
  ExclamationCircleIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import NavTabs from "../../components/navtabs/index";
import Timeline from "../../components/Timeline";
import GoalLine from "../../components/GoalLine";
import Card from "../../components/Card";
import CampaignStatusBadge from "../../components/badges/CampaignStatusBadge";
import LoadingBar from "../../components/loading/LoadingBar";
import MotiveImageCard from "./components/MotiveImageCard";
import CampaignMap from "../../components/maps/CampaignMap";
import HouseIcon from "../../components/icons/HouseIcon";
import UserGroupIcon from "../../components/icons/UserGroupIcon";
import MapIcon from "../../components/icons/MapNavigationIcon";
import PictureIcon from "../../components/icons/PictureIcon";
import useFormatter from "../../hooks/useFormatter";
import { useGoalSetting } from "../../hooks/useGoalSetting";
import moment from "moment";
import StartAlert from "./components/StartAlert";
import { BiDownload } from "react-icons/bi";
import AuthContext from "../../contexts/AuthContext";
import { downloadCampaignReportingPdf } from "../../hooks/useCampaignData";
import Spinner from "../../components/loading/Spinner";
import { usePlausible } from "../../contexts/PlausibleProvider";

function DisplayReport() {
  const { id } = useParams();
  const location = useLocation();
  const { decimalFormatter } = useFormatter();
  const intervalRef = useRef(null);
  const plausible = usePlausible();
  const { auth } = useContext(AuthContext);
  const [pdfDownloadStatus, setPdfDownloadStatus] = useState();
  const tabs = [
    { name: "Übersicht", href: ``, icon: HouseIcon },
    { name: "Reichweite", href: `reach`, icon: UserGroupIcon },
    { name: "Kanäle", href: "advertiser", icon: Square3Stack3DIcon },
    { name: "Werbemittel", href: "creatives", icon: PictureIcon },
    { name: "Werbegebiet", href: "area", icon: MapIcon },
  ];

  const { data: goalData } = useGoalSetting(id);
  const { data: campaignData, isLoading: campaignLoading } =
    useSingleCampaignData(id);

  const oneDayafterStart = moment(campaignData?.start).add(1, "days");
  const today = moment();

  useEffect(() => {
    if (pdfDownloadStatus === "loading") {
      intervalRef.current = setInterval(handleDownloadPdf, 5000);
    } else if (
      pdfDownloadStatus === "success" ||
      pdfDownloadStatus === "error"
    ) {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfDownloadStatus]);

  const handleDownloadPdf = async () => {
    setPdfDownloadStatus("loading");
    downloadCampaignReportingPdf(auth.token, id).then((response) => {
      if (response?.status === 200) {
        setPdfDownloadStatus("success");
        if (plausible) {
          plausible.trackEvent("DownloadPDF", {
            props: {
              filename: response?.data?.data?.filename,
              userID: auth?.user?.user?.id,
              //campaignName: campaignData?.name,
              //customerID: campaignData?.customer?.id,
            },
          });
        }
        // download pdf
        const a = document.createElement("a");
        a.href = `data:application/pdf;base64,${response.data.data.file}`;
        a.download = response.data.data.filename;
        a.click();
        return;
      }
      if (response.status === 500 || response.status === 403) {
        setPdfDownloadStatus("error");
      }
    });
  };

  if (campaignData && campaignData?.seaOnly)
    return <Navigate to={`/campaigns/sea/${campaignData?.id}`} />;

  return (
    <>
      {/* Breadcrumb auskommentiert falls sich Meinung ändert margin-top: 10px ändern notfalls 
      <Breadcrumb /> 
      */}
      <div className="relative mt-8">
        <Card>
          <div className="flex justify-between">
            <div>
              {campaignLoading ? (
                <LoadingBar type="headline" height="h-8" width="w-32" />
              ) : (
                <dd className=" text-20 font-bold">
                  {campaignData?.name}
                  <dt className="text-18 font-medium mb-3">
                    {campaignData?.customer?.company}
                  </dt>
                </dd>
              )}
              <div className="flex justify-between mb-3">
                <div className="mr-4 text-sm ">
                  <span className="text-sm text-gray-500">
                    Start <br></br>
                  </span>
                  <span className="text-sm font-semibold">
                    {campaignLoading ? (
                      <LoadingBar height="h-6" width="w-32" />
                    ) : (
                      <span>
                        {moment(campaignData.start).format("DD.MM.YYYY")}
                      </span>
                    )}
                  </span>
                </div>
                <div className="mx-6 text-sm ">
                  <span className="text-sm text-gray-500">
                    Ende <br></br>
                  </span>
                  <span className="text-sm font-semibold">
                    {campaignLoading ? (
                      <LoadingBar height="h-6" width="w-32" />
                    ) : (
                      <span>
                        {moment(campaignData.end).format("DD.MM.YYYY")}
                      </span>
                    )}
                  </span>
                </div>
                <div className="mx-6 text-sm ">
                  <span className="text-sm text-gray-500">
                    Auswertung vom <br></br>
                  </span>
                  <span className="text-sm font-semibold">
                    {campaignLoading ? (
                      <LoadingBar height="h-6" width="w-32" />
                    ) : (
                      <span>{moment().format("DD.MM.YYYY")}</span>
                    )}
                  </span>
                </div>
                <div className="mx-6 text-sm">
                  <span className="text-sm text-gray-500">
                    Sichtkontakte <br></br>
                  </span>
                  <span className="text-sm font-semibold">
                    {campaignLoading ? (
                      <LoadingBar height="h-6" width="w-32" />
                    ) : (
                      <span>
                        {decimalFormatter.format(campaignData.sum.impressions)}
                      </span>
                    )}
                  </span>
                </div>
                <div className="mx-6 text-sm">
                  <span className="text-sm text-gray-500">
                    Reichweite<br></br>
                  </span>
                  <span className="text-sm font-semibold">
                    {campaignLoading ? (
                      <LoadingBar height="h-6" width="w-32" />
                    ) : (
                      <span>
                        {decimalFormatter.format(campaignData.sum.reach)}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              {/* Adresse des Kunden soll nicht im Report Sichtbar sein. Auskommentiert, falls sich Meinung ändert 
              
              
              {campaignLoading ? (
                <div className="mt-1">
                  <LoadingBar type="headline" height="h-6" width="w-64" />{" "}
                  <div className="mt-4">
                    <LoadingBar height="h-10" width="w-32" />{" "}
                  </div>
                </div>
              ) : (
                <div>
                  <dt className="text-xl ">{campaignData.name}</dt>
                  <dt className=" text-sm mt-4 ">
                    {campaignData?.customer?.street}{" "}
                    {campaignData?.customer?.streetnumber}
                    <br /> {campaignData?.customer?.zipcode}{" "}
                    {campaignData?.customer?.city}
                  </dt>
                </div>
              )} */}
            </div>
            {campaignLoading ? (
              <LoadingBar height="h-8" width="w-20" />
            ) : (
              <div>
                {["reach", "advertiser", "creatives", "area"].some((e) =>
                  location.pathname.includes(e)
                ) ? (
                  <div className=" grid-cols-2 gap-4 lg:grid ">
                    <div className="w-52 h-full border border-stw3-gray-200 rounded">
                      <MotiveImageCard
                        isLoading={campaignLoading}
                        motiveUrl={campaignData?.motive?.image}
                      />
                    </div>
                    <div className="border border-stw3-gray-200 rounded relative z-50">
                      <CampaignMap height={115} zoomUi={false} />
                      <div
                        className="absolute inset-0 text-sm font-medium text-white rounded"
                        style={{
                          backgroundImage: `linear-gradient(159deg, rgba(6, 6, 6, 0.69) 0%, rgba(0, 188, 212, 0) 35%, rgba(0, 188, 212, 0) 35%, rgba(238, 130, 238, 0) 100%)`,
                        }}
                      >
                        <p className="p-4">{campaignData?.customer?.city}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center space-x-8 ">
                    <button
                      className={`flex items-center space-x-2 text-primary text-sm ${
                        today < oneDayafterStart ? "hidden" : ""
                      }`}
                      onClick={handleDownloadPdf}
                    >
                      {pdfDownloadStatus === "loading" ? (
                        <Spinner />
                      ) : pdfDownloadStatus === "error" ? (
                        <ExclamationCircleIcon className="h-5 w-5" />
                      ) : (
                        <BiDownload className="h-5 w-5" />
                      )}
                      {pdfDownloadStatus === "loading" ? (
                        <p>generiere PDF</p>
                      ) : pdfDownloadStatus === "error" ? (
                        <p>PDF kann nicht erstellt werden</p>
                      ) : (
                        <p>PDF-Herunterladen</p>
                      )}
                    </button>

                    <CampaignStatusBadge
                      status={campaignData?.status}
                      startDate={campaignData?.start}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <Timeline
            isLoading={campaignLoading}
            startDate={campaignData?.start}
            endDate={campaignData?.end}
          />

          {goalData?.total?.target > 0 && (
            <GoalLine
              impressions={campaignData?.sum?.impressions}
              goalImpressions={goalData?.total?.target}
              isLoading={campaignLoading}
            />
          )}
        </Card>
      </div>
      <div className="mt-4">
        <StartAlert
          startDate={campaignData?.start}
          infoText={
            "Die Kampagne ist heute planmäßig gestartet und wird bereits im Geschäftsgebiet ausgespielt. Ab morgen werden hier täglich die aktuellen Zahlen und Ergebnisse angezeigt."
          }
        />
      </div>
      <div className=" mb-4 md:mb-0 md:mt-0">
        <NavTabs tabs={tabs} />
      </div>

      <Outlet />
    </>
  );
}

export default DisplayReport;

import { useContext } from "react";
import { useOffer } from "../../hooks/useOfferData";
import AuthContext from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import logo from "../../img/stw3_logo.png";
import PhoneIcon from "../../components/icons/PhoneIcon";
import LoadingBar from "../../components/loading/LoadingBar";

const Header = () => {
  const { auth } = useContext(AuthContext);
  const { hash } = useParams();
  const { data: offer, isLoading } = useOffer(hash, auth.token);

  return (
    <div className="lg:border-b border-axColdgray-100 -mx-8 pl-8 pr-2 max-lg:shadow-[0px_10px_51px_-15px_rgba(0,_0,_0,_0.1)]">
      <div className=" flex items-center justify-between container mx-auto py-4">
        <img src={logo} alt="Logo" className="w-auto h-11" />
        <div className="flex items-center space-x-2 hover:underline hover:text-primary max-lg:hidden">
          <PhoneIcon className="h-5 w-5" />
          {isLoading ? (
            <LoadingBar height={"h-5"} width={"w-40"} type="headline" />
          ) : (
            <a
              href={`tel:${offer?.vendor?.phone}`}
              className="font-semibold text-lg"
            >
              {offer?.vendor?.phone ?? "0221 / 42480-100"}
            </a>
          )}
        </div>
        <div className="lg:hidden text-xs w-40">
          <h3 className="font-bold">Ansprechpartner</h3>
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="flex flex-col justify-between">
              {isLoading ? (
                <LoadingBar height="h-5" width="w-44" />
              ) : (
                <p className="font-bold ">{offer?.vendor?.name ?? ""}</p>
              )}
              {isLoading ? (
                <div className="mt-1">
                  <LoadingBar height="h-4" width="w-32" />
                </div>
              ) : (
                <a
                  href={`mailto:${
                    offer?.vendor?.email ?? "info@stellwerk3.de"
                  }`}
                  className="block font-light hover:underline hover:text-primary"
                >
                  {offer?.vendor?.email ?? "info@stellwerk3.de"}
                </a>
              )}
              {isLoading ? (
                <div className="mt-1">
                  <LoadingBar height="h-4" width="w-32" />
                </div>
              ) : (
                <a
                  href={"tel:" + (offer?.vendor?.phone ?? "0221 / 42480-100")}
                  className="block font-light  hover:underline hover:text-primary"
                >
                  {offer?.vendor?.phone ?? "0221 / 42480-100"}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

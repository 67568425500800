import React, { useContext } from "react";
import { useOffer } from "../../../hooks/useOfferData";
import AuthContext from "../../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import BaseData from "../BaseData";
import Area from "../Area";
import Subjects from "../Subjects";
import SubmitArea from "../SubmitArea";
import Header from "../Header";

const Offer = () => {
  const { auth } = useContext(AuthContext);
  const { hash } = useParams();
  const { data: offer, isLoading } = useOffer(hash, auth.token);
  const offerSections = [<BaseData />, <Area />, <Subjects />];
  return (
    <div className="text-[#1E2938] bg-white px-8">
      <Header />
      <ol>
        {offerSections.map((section, index) => (
          <li
            key={index}
            className={`border-b border-axColdgray-100 py-6 md:py-12 ${index === 2 ? "border-b-0" : ""
              }`}
          >
            <div className="container mx-auto">{section}</div>
          </li>
        ))}
        <li className="container mx-auto py-12 pb-24">
          <SubmitArea />
        </li>
      </ol>
      <div className="text-center py-6 border-t border-axColdgray-100 text-axColdgray-500 text-sm">
        Stellwerk3 GmbH • Unter den Linden 4 • 72762 Reutlingen •
        <a href={"tel:+497121764460"} className="hover:underline">
          {" "}
          + 49 (0) 7121 764460
        </a>{" "}
        <a href={"mailto:info@stellwerk3.de"} className="hover:underline">
          • info@stellwerk3.de •
        </a>{" "}
        <a href="/impressum" target="_blank" className="hover:underline">
          Impressum •
        </a>{" "}
        <a href="/privacy" target="_blank" className="hover:underline">
          Datenschutz
        </a>
      </div>
    </div>
  );
};

export default Offer;

import CampaignStatusBadge from "../../components/badges/CampaignStatusBadge";
import Card from "../../components/Card";
import CampaignList from "../campaigns/campaignComponents/CampaignList";
import BookingList from "../bookings/bookingComponents/BookingList";

const SearchCampaignsItem = ({
  campaigns,
  bookings,
  isLoadingBookings,
  isLoadingCampaigns,
}) => {
  return (
    <>
      {isLoadingBookings ? (
        <>
          {[...Array(2)].map((_, index) => (
            <Card className="mt-4 flex items-center justify-between px-6 py-6 bg-white animate-pulse">
              <div className="flex w-1/3 items-center">
                <div className="h-12 w-12 rounded-full bg-slate-200 animate-pulse flex-shrink-0 mr-3"></div>
                <div className="flex-col items-center justify-center">
                  <div className="h-4 w-24 leading-6 rounded-lg bg-slate-200 mb-3" />
                  <div className="mt-1 h-3 w-32 leading-5 rounded-lg bg-slate-200" />
                </div>
              </div>

              <div className="flex w-1/3 justify-end">
                <CampaignStatusBadge status={"in_coordination"} />
              </div>
            </Card>
          ))}
        </>
      ) : (
        bookings?.length > 0 && (
          <div className="mt-4">
            <BookingList bookings={bookings} isLoading={isLoadingBookings} />
          </div>
        )
      )}
      {isLoadingCampaigns ? (
        <>
          {[...Array(3)].map((_, index) => (
            <Card className="mt-4 flex items-center justify-between px-6 py-6 bg-white animate-pulse">
              <div className="flex w-1/3 items-center">
                <div className="h-12 w-12 rounded-full bg-slate-200 animate-pulse flex-shrink-0 mr-3"></div>
                <div className="flex-col items-center justify-center">
                  <div className="h-4 w-24 leading-6 rounded-lg bg-slate-200 mb-3" />
                  <div className="mt-1 h-3 w-32 leading-5 rounded-lg bg-slate-200" />
                </div>
              </div>

              <div className="flex w-1/3 justify-end">
                <CampaignStatusBadge status={"active"} />
              </div>
            </Card>
          ))}
        </>
      ) : (
        campaigns?.length > 0 && (
          <div className="mt-4">
            <CampaignList
              campaigns={campaigns}
              isLoading={isLoadingCampaigns}
            />
          </div>
        )
      )}

      {bookings?.length === 0 && campaigns?.length === 0 && (
        <Card className="mt-4 flex justify-center items-center px-6 py-9 bg-white">
          <div>
            <p className="text-sm leading-6 text-gray-400">
              Sie haben derzeit keine Suchmaschinen Kampagnen.
            </p>
          </div>
        </Card>
      )}
    </>
  );
};

export default SearchCampaignsItem;

import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { useOffer } from "../../hooks/useOfferData";
import Spinner from "../../components/loading/Spinner";
import SeaOffer from "./sea/Index";
import DisplayOffer from "./display/Index";

const OfferOverview = () => {
  const { auth } = useContext(AuthContext);
  const { hash } = useParams();
  const { data: offer, isLoading } = useOffer(hash, auth.token);
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        {" "}
        <Spinner />
      </div>
    );
  }

  if (offer?.category === "seaonly") {
    return <SeaOffer />;
  }

  return <DisplayOffer />;
};

export default OfferOverview;

import React, { useContext } from "react";
import PenEditIcon from "../../../components/icons/PenEditIcon";
import AuthContext from "../../../contexts/AuthContext";

const SitelinkPreview = ({ sitelinks, onEdit, status }) => {
  const { auth } = useContext(AuthContext);

  return (
    <ol className="md:grid grid-cols-2 gap-1 place-items-start  ">
      {sitelinks?.map((sitelink, index) => (
        <button
          disabled={!auth?.isAuthenticated || status !== "in_coordination"}
          key={sitelink[0].value + index}
          className={`p-1 border border-transparent ${onEdit && auth?.isAuthenticated && status === "in_coordination" ? "hover:border-primary" : ""
            } rounded-lg pl-8 group w-full min-h-16 text-left relative`}
          onClick={onEdit ? () => onEdit(index) : null}
        >
          {onEdit && auth?.isAuthenticated && status === "in_coordination" && (
            <PenEditIcon
              className={
                "h-5 w-5 text-primary absolute right-3 top-3 invisible group-hover:visible"
              }
            />
          )}
          <p className="text-base text-[#1a0eab]">
            {sitelink?.find((p) => p.name === "sitelink_title")?.value}
          </p>
          <p className="text-sm text-[#434343] whitespace-pre-line break-words">
            {sitelink?.find((p) => p.name === "sitelink_text_1")?.value}
          </p>
          <p className="text-sm text-[#434343] whitespace-pre-line break-words ">
            {sitelink?.find((p) => p.name === "sitelink_text_2")?.value}
          </p>
        </button>
      ))}
    </ol>
  );
};

export default SitelinkPreview;

import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { useOffer } from "../../hooks/useOfferData";
import UsersIcon from "../../components/icons/UsersIcon";
import GlobeCursorIcon from "../../components/icons/GlobeCursorIcon";
import useServiceAPI from "../../hooks/useServiceAPI";
import useFormatter from "../../hooks/useFormatter";
import MapComponent from "../../components/MapComponent";
import MapInteraction from "../../components/maps/MapInteraction";
import LoadingBar from "../../components/loading/LoadingBar";
import useScreenSize from "../../hooks/useScreenSize";

const Area = () => {
  const { isWindowSmallerThan } = useScreenSize();

  const { auth } = useContext(AuthContext);
  const mapRef = useRef(null);
  const { hash } = useParams();
  const [isGeoDataLoading, setIsGeoDataLoading] = useState(true);
  const { data: offer, isLoading } = useOffer(hash, auth.token);
  const [geoData, setGeoData] = useState([]);
  const { getGeoData } = useServiceAPI();
  const mapInteractionRef = useRef(null);

  const { decimalFormatter } = useFormatter();

  useEffect(() => {
    if (mapRef.current && geoData?.data?.length > 0) {
      mapInteractionRef.current?.setPolygonsAndHover(geoData?.data);
    }
    if (mapRef.current && offer?.customer) {
      mapInteractionRef.current?.setMarker(
        offer?.customer?.lat,
        offer?.customer?.lng
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoData, mapRef.current, offer?.customer]);

  useEffect(() => {
    if (offer?.typemap?.postcodes?.length > 0) {
      getGeoData(offer?.typemap?.postcodes).then((response) => {
        setIsGeoDataLoading(false);
        if (!response) return;
        setGeoData(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer?.typemap?.postcodes]);

  return (
    <>
      <h2 className="md:hidden font-bold text-xl">Ihr Werbegebiet</h2>

      <div className="md:grid grid-cols-3">
        <div className="col-span-2 mt-4 lg:mt-0">
          <MapComponent
            // eslint-disable-next-line react/style-prop-object
            style="light"
            height={isWindowSmallerThan("lg") ? 250 : 500}
            lati={offer?.customer?.lat}
            long={offer?.customer?.lng}
            ref={mapRef}
          />
          <MapInteraction
            mapInstance={mapRef.current}
            ref={mapInteractionRef}
          />
        </div>
        <div className="order-first">
          <h2 className="hidden md:block font-bold text-xl">
            Ihr Werbegebiet
          </h2>

          <div className="grid grid-cols-2 md:block place-items-center md:place-items-start">
            <div className="flex items-center space-x-8 mt-8">
              <UsersIcon className={"h-9 w-9 flex-shrink-0"} />
              <div>
                {isLoading || isGeoDataLoading ? (
                  <LoadingBar type="headline" height="h-8" width="w-32" />
                ) : (
                  <p className="text-xl font-bold">
                    {decimalFormatter.format(geoData?.sum?.sum_population)}
                  </p>
                )}
                <p className="text-sm ">Einwohner</p>
              </div>
            </div>

            <div className="flex items-center space-x-8 mt-8">
              <GlobeCursorIcon className={"h-9 w-9 flex-shrink-0"} />
              <div>
                {isLoading || isGeoDataLoading ? (
                  <LoadingBar type="headline" height="h-8" width="w-32" />
                ) : (
                  <p className="text-xl font-bold">
                    {decimalFormatter.format(geoData?.sum?.sum_online)}
                  </p>
                )}
                <p className="text-sm ">Einwohner Online</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Area;

const useFormatter = () => {
  const decimalFormatter = new Intl.NumberFormat(undefined, {
    style: "decimal",
  });

  const currencyFormatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "EUR",
  });

  function formatEuroWithoutCents(value) {
    const integerPart = Math.round(value);

    // Hier formatieren wir nur die Ganzzahl ohne Nachkommastellen
    const formatted = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(integerPart);

    // Jetzt hängen wir „,- €“ an
    return `${formatted} €`;
  }

  return { decimalFormatter, currencyFormatter, formatEuroWithoutCents };
};

export default useFormatter;

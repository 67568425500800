import React, { useContext } from "react";
import { useOffer } from "../../hooks/useOfferData";
import { useParams } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import moment from "moment";
import DownloadIcon from "../../components/icons/DowloadIcon";
import Badge from "../../components/badges/Badge";
import LoadingBar from "../../components/loading/LoadingBar";
const BaseData = ({ isDisplay = false }) => {
  const { auth } = useContext(AuthContext);
  const { hash } = useParams();
  const { data: offer, isLoading } = useOffer(hash, auth.token);

  return (
    <div className="lg:grid grid-cols-3 gap-12">
      <div className="col-span-2 max-lg:border-b border-axColdgray-100 pb-12">
        {isLoading ? (
          <LoadingBar height={"h-7"} width="w-64" type="headline" />
        ) : (
          <p className="text-primary text-base leading-7 font-bold">
            {offer?.customer?.company}
          </p>
        )}
        {isDisplay ? (
          <>
            <h1 className="font-bold text-3xl">Regionale Online-Werbung</h1>
            <h5 className="pt-1 text-base max-lg:text-lg">{offer?.title}</h5>
          </>
        ) : (
          <h1 className="font-bold text-3xl">
            Regionales Suchmaschinen-Marketing
          </h1>
        )}

        <div className="mt-6 font-normal text-base ">
          {offer?.customer?.salutation && offer?.customer?.last_name ? (
            <p>
              {offer?.customer?.salutation === "Herr"
                ? "Sehr geehrter Herr"
                : "Sehr geehrte Frau"}{" "}
              {offer.customer.last_name}
              {","}
            </p>
          ) : (
            <p>Sehr geehrte Damen und Herren,</p>
          )}
          <p className="whitespace-pre-line mt-6">
            {isDisplay
              ? `vielen Dank für Ihr Interesse bezüglich regionaler Online-Werbemöglichkeiten bei Stellwerk3.\nIm Folgenden finden Sie alle relevanten Informationen zu Ihrem Angebot.`
              : `vielen Dank für Ihr Interesse bezüglich regionaler Online-Werbemöglichkeiten. Auf\n den folgenden Seiten finden Sie die Informationen zu den Online-Werbemöglichkeiten\nfür Ihr Einzugsgebiet.`}
          </p>
          <p className="mt-6">
            Bei Fragen stehen wir Ihnen jederzeit gerne zur Verfügung.
          </p>
          <div className="flex-row sm:flex-col justify-between items-end lg:block space-y-2">
            <div>
              <p className="mt-6">Herzliche Grüße</p>
              <p>Ihr Stellwerk3-Team</p>
            </div>
            {offer?.vendor?.signature && (
              <img
                alt="Unterschrift"
                src={offer.vendor.signature}
                className="h-14 w-auto lg:mt-4 "
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-12">
        <div className="lg:p-8 px-0 lg:border border-axColdgray-200">
          <span className="flex justify-between space-x-4">
            <h3 className="font-bold text-base max-lg:text-xl">
              Angebotsdetails
            </h3>
            {offer?.status === "accepted" && (
              <Badge color="green" label={"beauftragt"} circle={false} />
            )}
          </span>
          <ol className="divide-y divide-axColdgray-200 lg:space-y-1 py-2 text-base lg:text-sm">
            <li className="flex justify-between py-3 lg:py-1">
              <p>Angebotsnummer</p>
              {isLoading ? (
                <LoadingBar height="h-4" width={"w-32"} />
              ) : (
                <p className="font-bold">{offer?.offer_number}</p>
              )}
            </li>
            <li className="flex justify-between py-3 lg:py-1">
              <p className="">Erstellt am</p>
              {isLoading ? (
                <LoadingBar height="h-4" width={"w-32"} />
              ) : (
                <p className="font-bold">
                  {moment(offer?.created_at).format("DD.MM.YYYY")}
                </p>
              )}
            </li>
            {offer?.changed_at && (
              <li className="flex justify-between py-3 lg:py-1">
                <p className="">Geändert am</p>
                {isLoading ? (
                  <LoadingBar height="h-4" width={"w-32"} />
                ) : (
                  <p className="font-bold">
                    {moment(offer?.changed_at).format("DD.MM.YYYY")}
                  </p>
                )}
              </li>
            )}
            <li className="flex justify-between py-3 lg:py-1">
              <p>Gültig bis</p>
              {isLoading ? (
                <LoadingBar height="h-4" width={"w-32"} />
              ) : (
                <p className="font-bold">
                  {moment(offer?.valid_till).format("DD.MM.YYYY")}
                </p>
              )}
            </li>
          </ol>

          <div className="max-lg:hidden mt-6">
            <h3 className="font-bold text-base mt-6">Ihr Ansprechpartner</h3>
            <div className="lg:flex lg:items-center lg:justify-between pt-4">
              <div
                className={`flex  ${offer?.vendor?.image ? "space-x-5" : ""}`}
              >
                <div className="flex-shrink-0">
                  {isLoading ? (
                    <div className="h-[70px] w-[70px] animate-pulse bg-gray-300 rounded-full"></div>
                  ) : (
                    <>
                      {offer?.vendor?.image && (
                        <img
                          className=" h-[70px] w-[70px] rounded-full object-cover"
                          src={offer?.vendor?.image}
                          alt=""
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="flex flex-col justify-between">
                  {isLoading ? (
                    <LoadingBar height="h-5" width="w-44" />
                  ) : (
                    <p className="font-bold ">{offer?.vendor?.name ?? ""}</p>
                  )}
                  {isLoading ? (
                    <div className="mt-1">
                      <LoadingBar height="h-4" width="w-32" />
                    </div>
                  ) : (
                    <a
                      href={`mailto:${
                        offer?.vendor?.email ?? "info@stellwerk3.de"
                      }`}
                      className="block text-sm font-light hover:underline hover:text-primary"
                    >
                      {offer?.vendor?.email ?? "info@stellwerk3.de"}
                    </a>
                  )}
                  {isLoading ? (
                    <div className="mt-1">
                      <LoadingBar height="h-4" width="w-32" />
                    </div>
                  ) : (
                    <a
                      href={
                        "tel:" + (offer?.vendor?.phone ?? "0221 / 42480-100")
                      }
                      className="block  text-sm font-light  hover:underline hover:text-primary"
                    >
                      {offer?.vendor?.phone ?? "0221 / 42480-100"}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {offer?.has_pdf && (
          <a
            href={`${process.env.REACT_APP_COREAPI_URL}/offers/hash/${hash}/download-pdf`}
            className="max-lg:hidden hover:underline w-full justify-center mt-6 text-center border p-2 border-primary rounded-md text-primary text-base flex items-center space-x-4"
          >
            <DownloadIcon className="h-5 w-5" />
            <span>Angebot herunterladen</span>{" "}
          </a>
        )}
      </div>
    </div>
  );
};

export default BaseData;

import { useState } from "react";
/**
 *
 * Custom Hook to toggle Modals on and off by their name
 * check for 'modalRegistered' if modal should be displayed or not
 */
const useModal = () => {
  const [registeredModals, setRegisteredModals] = useState([]);
  const toggleModal = (modalName) => {
    registeredModals.includes(modalName)
      ? setRegisteredModals((prevState) => [
          ...prevState.filter((x) => x !== modalName),
        ])
      : setRegisteredModals((prevState) => [...prevState, modalName]);
  };

  const modalRegistered = (modalName) => {
    return registeredModals.includes(modalName);
  };

  return {
    toggleModal,
    modalRegistered,
  };
};

export default useModal;

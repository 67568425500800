import { Disclosure } from "@headlessui/react";
import React, { useContext, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { useOffer, acceptOffer } from "../../hooks/useOfferData";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import moment from "moment";
import toast from "react-hot-toast";
import { FaCheckCircle } from "react-icons/fa";

const SubmitArea = () => {
  const { auth } = useContext(AuthContext);
  const { hash } = useParams();
  const [loading, setLoading] = useState(false);
  const { data: offer, isLoading, refetch } = useOffer(hash, auth.token);
  const handleAcceptOffer = async () => {
    setLoading(true);
    let response = await acceptOffer(hash, auth.token);
    setLoading(false);

    if (response.success) {
      toast.success("Angebot wurde erfolgreich beauftragt");
      refetch();
    } else {
      toast.error("Angebot konnte nicht beauftragt werden");
    }
  };

  return (
    <div>
      <Disclosure
        as="div"
        className=""
        defaultOpen={offer?.status === "accepted"}
      >
        {({ open }) => (
          <>
            <Disclosure.Button className="p-4 text-lg font-bold flex items-center justify-between space-x-4 border border-axColdgray-100 w-full">
              <div className="flex items-center space-x-4">
                {" "}
                {offer?.status === "accepted" ? (
                  <span>Angebot beauftragt</span>
                ) : (
                  <span>Angebot beauftragen</span>
                )}
                {offer?.status === "accepted" ? (
                  <FaCheckCircle className="text-green-500 h-5 w-5" />
                ) : (
                  <>
                    {open ? (
                      <BiChevronUp className="h-6 w-6" />
                    ) : (
                      <BiChevronDown className="h-6 w-6" />
                    )}
                  </>
                )}
              </div>
            </Disclosure.Button>
            {offer?.status !== "accepted" ? (
              <Disclosure.Panel className="p-4 border border-axColdgray-100 border-t-0 text-base ">
                <p>
                  Wir freuen uns, wenn dieses Angebot Ihre Zustimmung findet.
                </p>
                <p>
                  Bei Rückfragen stehen wir Ihnen selbstverständlich jederzeit
                  zur Verfügung.{" "}
                </p>
                <p className="mt-6">
                  Das freibleibende Angebot hat eine Gültigkeit bis zum{" "}
                  {moment(offer?.valid_till).format("DD.MM.YYYY")}.
                </p>
                <p>
                  Alle ausgewiesenen Preise sind Netto-Preise und werden zzgl.
                  der gesetzlichen Mehrwertsteuer berechnet.
                </p>
                <p>Irrtümer und Änderungen vorbehalten.</p>
                {offer?.status !== "accepted" && (
                  <div className="mt-8 flex justify-end w-full">
                    <button
                      disabled={loading || isLoading}
                      onClick={handleAcceptOffer}
                      className={`w-full md:max-w-min whitespace-nowrap font-medium text-primary border border-primary px-8 py-2 rounded-md hover:underline text-base max-lg:text-lg ${
                        loading ? "opacity-60 " : ""
                      }`}
                    >
                      Angebot beauftragen
                    </button>
                  </div>
                )}
              </Disclosure.Panel>
            ) : (
              <Disclosure.Panel className="p-4 px-4 border border-axColdgray-100 border-t-0 text-base">
                <p>Die Beauftragung Ihres Angebots war erfolgreich.</p>
                <p>
                  {" "}
                  Sie werden in Kürze eine E-Mail mit der Auftragsbestätigung
                  inklusive aller wichtigen Informationen erhalten.
                </p>
                <p className="mt-6">
                  Sollten zwischenzeitlich Fragen auftreten, melden Sie sich
                  gerne bei Ihrem Ansprechpartner.
                </p>
              </Disclosure.Panel>
            )}
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default SubmitArea;

import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import Spinner from "../components/loading/Spinner";
import { useUser } from "../hooks/useUserData";
//import Avatar from "../img/icons/Avatar";
import NavBar from "../components/navbar/NavBar";

const AppRoute = () => {
  const { auth } = useContext(AuthContext);
  const { isLoading: userLoading } = useUser(auth.token);
  if (userLoading)
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <Spinner />
      </div>
    );

  if (!auth?.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <NavBar />
      <div className="container mx-auto my-2 pb-12">
        <Outlet />
      </div>
    </>
  );
};

export default AppRoute;

import React, { useState } from "react";
import Modal from "./Modal";
import { useBannerPreview } from "../../hooks/useServiceAPI";
import Spinner from "../loading/Spinner";
import CreativeOverview from "../creatives/CreativeOverview";

const CreativePreviewModal = ({ creative, onClose }) => {
  const { data: creativePreview, isLoading } = useBannerPreview(
    creative.creative_hash
  );
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    onClose();
  };

  console.log(creativePreview);

  return (
    <div>
      <Modal show={show} onClose={handleClose}>
        <div>
          <div className="flex items-center rounded justify-center ">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {creativePreview?.file ? (
                    <div dangerouslySetInnerHTML={{ __html: creativePreview.file }}></div>
                ) : creativePreview?.url ? (
                    <div>
                        <CreativeOverview ad={creative} />
                    </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreativePreviewModal;

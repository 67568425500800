import React from "react";

const Badge = ({ label, color, size, icon, bold, circle = true }) => {
  const getColor = () => {
    switch (color) {
      case "red":
        return "bg-red-100 text-red-700";
      case "green":
        return "bg-green-100 text-green-700";
      case "yellow":
        return "bg-yellow-100 text-yellow-700";
      case "orange":
        return "bg-orange-100 text-orange-700";
      case "blue":
        return "bg-blue-100 text-blue-700";
      case "gray":
        return "bg-gray-100 text-gray-700";
      case "indigo":
        return "bg-indigo-100 text-indigo-700";
      case "purple":
        return "bg-purple-100 text-purple-700";
      case "pink":
        return "bg-pink-100 text-pink-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  const getSize = () => {
    switch (size) {
      case "sm":
        return "px-4 py-2 text-sm";
      default:
        return "px-2 py-1 text-xs";
    }
  };

  const circleSize = size === "sm" ? "w-3 h-3" : "w-4 h-4";

  return (
    <span
      className={`inline-flex items-center gap-x-1.5 rounded-full ${getColor()} ${
        bold && "font-bold"
      } ${getSize()}`}
    >
      {circle && (
        <svg
          className={`fill-current ${circleSize}`}
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx={3} cy={3} r={3} />
        </svg>
      )}
      {icon && React.cloneElement(icon, { className: "h-4 w-4" })}
      {label}
    </span>
  );
};

export default Badge;

/* eslint-disable no-unused-vars */
import React, { useRef, forwardRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import ReactDOM from "react-dom/client";
import { BiTargetLock } from "react-icons/bi";

const MapComponent = forwardRef(
  (
    {
      lati,
      long,
      onLoad,
      height,
      roundedBottom = true,
      onMapReset,
      zoomUi = true,
      newZoom,
      style = "natural",
    },
    ref
  ) => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const mapContainer = useRef(null);

    const [lng, setLng] = useState(long ?? 9.2046);
    const [lat, setLat] = useState(lati ?? 48.4912);
    const [zoom, setZoom] = useState(newZoom ?? 9.5);

    useEffect(() => {
      if (ref.current) return; // initialize map only once
      ref.current = new mapboxgl.Map({
        container: mapContainer.current,
        style:
          style === "light"
            ? "mapbox://styles/mapbox/light-v11"
            : "mapbox://styles/bashd42/clrt91oof00i401pl7hsba234",
        center: [lng, lat],
        zoom: zoom,
      });
      ref.current.on("load", function () {
        ref.current.setLayoutProperty("settlement-major-label", "text-field", [
          "get",
          "name_de",
        ]);

        ref.current.resize();

        if (zoomUi) {
          ref.current.scrollZoom.disable();
          ref.current.addControl(
            new mapboxgl.NavigationControl({ showCompass: false })
          );
          if (onMapReset) {
            ref.current.addControl(
              new ResetMapControl(onMapReset),
              "top-right"
            );
          }
        }
        if (onLoad) {
          onLoad();
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        ref={mapContainer}
        className={roundedBottom ? "rounded" : "rounded"}
        style={{ height: height + "px" }}
      />
    );
  }
);
export default MapComponent;

class ResetMapControl {
  constructor(onClickCallback) {
    this.onClickCallback = onClickCallback;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    const CustomButton = () => {
      const handleLocalClick = () => {
        this.onClickCallback();
      };

      return (
        <button className="" onClick={handleLocalClick}>
          <BiTargetLock className="mapboxgl-ctrl-icon p-1 " />
        </button>
      );
    };

    const root = ReactDOM.createRoot(this._container);
    root.render(<CustomButton />);
  }

  handleClick = () => {
    this.onClickCallback();
  };

  onAdd(map) {
    this._map = map;
    return this._container;
  }

  onRemove() {
    if (this.container && this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
    this.map = undefined;
  }
}

import React, { useState, useEffect } from "react";
import { BiInfoCircle } from "react-icons/bi";

const StartAlert = ({ startDate, infoText }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const startDateObj = new Date(startDate);

    // Vergleiche nur das Datum, nicht die Zeit
    if (
      currentDate.getDate() === startDateObj.getDate() &&
      currentDate.getMonth() === startDateObj.getMonth() &&
      currentDate.getFullYear() === startDateObj.getFullYear()
    ) {
      setIsVisible(true);
    }
  }, [startDate]);
  if (!isVisible) return null;

  return (
    <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <BiInfoCircle className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">
            Die Kampagne ist heute planmäßig gestartet und wird bereits im
            Geschäftsgebiet ausgespielt. Ab morgen werden hier täglich die
            aktuellen Zahlen und Ergebnisse angezeigt.
          </p>
        </div>
      </div>
    </div>
  );
};
export default StartAlert;

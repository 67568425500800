import axios from "axios";
import { useMutation, useQuery } from "react-query";

export const useBooking = (token, hash) => {
  return useQuery({
    queryKey: ["booking", hash],
    queryFn: () => getBooking(token, hash),
    refetchOnWindowFocus: false,
  });
};

const getBooking = async (token, hash) => {
  try {
    // HTTP GET-Anfrage mit Axios
    const { data } = await axios.get(
      `${process.env.REACT_APP_COREAPI_URL}/bookings/${hash}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const useUpdateBooking = (token, hash) => {
  return useMutation((data) => updateBooking(token, hash, data));
};

const updateBooking = async (token, hash, bookingData) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_COREAPI_URL}/bookings/${hash}`,
      bookingData,
      {
        headers: {
          ContentType: "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

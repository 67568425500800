// src/bugsnag.js
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

import React from "react";

const bugsnagClient = Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
});
BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY });

export default bugsnagClient;

import { React, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  CustomToolTip,
  CustomXAxisTick,
} from "../pages/displayReport/components/LineChart";
import useFormatter from "../hooks/useFormatter";
import useResponsiveCharts from "../hooks/useResponsiveCharts";

const DynamicBarChart = ({ data }) => {
  const { decimalFormatter } = useFormatter();
  const { intervalCount } = useResponsiveCharts(data?.length);

  const renderCustomBarLabel = (props) => {
    const { x, width, value } = props;
    const fixedY = 20;
    return (
      <text
        x={x + width / 2}
        y={fixedY}
        fill="#111828"
        fontSize={12}
        textAnchor="middle"
        dy={-6}
      >
        {decimalFormatter.format(value)}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 30,
          right: 50,
          left: 40,
          bottom: 0,
        }}
        barSize={18}
      >
        <CartesianGrid stroke="#C6C6C6" y={999999999} />

        <XAxis
          interval={intervalCount}
          tickLine={false}
          dataKey="date"
          padding={{ left: 10, right: 10 }}
          height={60}
          tick={<CustomXAxisTick data={data} />}
        />
        <YAxis
          tickCount={4}
          dx={-20}
          domain={[0, "auto"]}
          stroke="#6B7280"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => decimalFormatter.format(value)}
        />
        <Tooltip content={<CustomToolTip />} />
        <Bar
          dataKey="reach"
          fill="#671B34"
          label={renderCustomBarLabel}
          isAnimationActive={false}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
//background={{ fill: "#E4E8EF" }}
export default DynamicBarChart;

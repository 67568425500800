import React from "react";
import { ReactComponent as Google } from "../img/channelicons/google.svg";
import { ReactComponent as Html5 } from "../img/channelicons/html5.svg";

const useChannelIcons = () => {
  const channelIcons = (icon, height) => {
    switch (icon) {
      case "sea":
        return <Google className={`${height ?? "h-5 "} w-auto`} />;
      case "html":
        return <Html5 className={`${height ?? "h-5"} w-auto text-red-500`} />;

      default:
        break;
    }
  };
  return { channelIcons };
};

export default useChannelIcons;

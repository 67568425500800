import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import Card from "../../components/Card";
import Table from "../../components/Table";
import useLocationColumns from "./tableColumns/useLocationColumns";
import CampaignMap from "../../components/maps/CampaignMap";
import LoadingBar from "../../components/loading/LoadingBar";
import useFormatter from "../../hooks/useFormatter";
import useServiceAPI from "../../hooks/useServiceAPI";
import moment from "moment";

function ReportLocation() {
  const { id } = useParams();
  const { decimalFormatter } = useFormatter();
  const { data: campaignData, isLoading } = useSingleCampaignData(id);
  const { getGeoData } = useServiceAPI();
  const { columns } = useLocationColumns();
  const [plzList, setPlzList] = useState([]);

  //extrahieren der PLZ Daten aus der API
  useEffect(() => {
    async function fetchData() {
      if (!campaignData?.geo?.data) {
        return;
      }
      try {
        const result = await getGeoData(
          campaignData.geo.data,
          moment(campaignData?.start).isAfter("2024-08-12")
        );
        setPlzList(result.data);
      } catch (error) {
        console.error("Ein Fehler ist aufgetreten:", error);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData]);

  //KPIS für unter die KArte
  const kpis = [
    { label: "PLZ Gebiete", value: campaignData?.geo?.data?.length },
    { label: "Einwohner", value: campaignData?.geo?.sum?.sum_population },
    // { label: "Einwohner 18-80", value: sumAge },
  ];
  return (
    <Card title={"Werbegebiet"}>
      <div className="my-8 ">
        <CampaignMap height={600} />
      </div>
      <div className="lg:grid grid-cols-2">
        <div className="  text-sm  ">
          <dd className="mt-1 font-semibold ">
            {isLoading ? (
              <LoadingBar height="h-4" width={"w-32"} type="headline" />
            ) : (
              campaignData?.customer?.company
            )}
          </dd>
          {isLoading ? (
            <div className="mt-2">
              <LoadingBar height="h-12" width={"w-32"} />
            </div>
          ) : (
            <dt className="   ">
              {campaignData?.customer?.street}{" "}
              {campaignData?.customer?.streetnumber}
              <br /> {campaignData?.customer?.zip}{" "}
              {campaignData?.customer?.city}
            </dt>
          )}
        </div>

        <ol className="grid grid-cols-3 lg:text-right gap-8 mt-4 lg:mt-0 ">
          <div className="grid-cols-1 col-span-1"></div>
          {kpis.map((kpi) => (
            <li key={kpi.label}>
              <div className="font-sm text-sm text-gray-500">{kpi.label}</div>
              {isLoading ? (
                <div className="mt-2">
                  <LoadingBar height="h-8" width={"w-12"} type={"headline"} />
                </div>
              ) : (
                <p className="font-normal text-3xl mt-2 ">
                  {decimalFormatter.format(kpi.value)}
                </p>
              )}
            </li>
          ))}
        </ol>
      </div>
      <div className="mt-4 -mx-5 overflow-x-auto">
        <Table
          columns={columns}
          data={campaignData && campaignData?.geo?.data ? plzList : []}
          isLoading={isLoading}
          initialSortBy={{ id: "population", desc: true }}
          cardPadding
        />
      </div>
    </Card>
  );
}

export default ReportLocation;

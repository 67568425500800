import React from "react";
import Creative from "../../../components/creatives/Creative";
import useFormatter from "../../../hooks/useFormatter";
import Button from "../../../components/buttons/Button";
import Spinner from "../../../components/loading/Spinner";
import LoadingBar from "../../../components/loading/LoadingBar";

const CreativeReport = ({ creative, onPreview, isLoading }) => {
  const { decimalFormatter } = useFormatter();
  if (!creative) {
    return null;
  }
  return (
    <div className="lg:grid grid-cols-3 gap-12 bg-white ">
      <div className=" col-span-2 rounded  p-12 flex items-center justify-center h-[300px] lg:h-[500px]">
        <div
          className="flex items-center justify-center h-[300px] lg:h-[500px]"
          style={{
            width:
              creative.title === "Mobile Medium-Rectangle"
                ? creative.width + 90 + "px"
                : creative.title === "Mobile Ad (320 x 50)"
                ? creative.width + 95 + "px"
                : creative.title === "Mobile 4:1/6:1 Banner"
                ? creative.width + 90 + "px"
                : creative.width + "px",
            height:
              creative.title === "Mobile Medium-Rectangle"
                ? creative.height + 300 + "px"
                : creative.title === "Mobile Ad (320 x 50)"
                ? creative.height + 200 + "px"
                : creative.title === "Mobile 4:1/6:1 Banner"
                ? creative.height + 200 + "px"
                : creative.height + "px", // Setzt die Höhe dynamisch
            backgroundImage:
              creative.title === "Mobile Medium-Rectangle"
                ? `url(/img/300_250.png)`
                : creative.title === "Mobile Ad (320 x 50)"
                ? `url(/img/320_50.png)`
                : creative.title === "Mobile 4:1/6:1 Banner"
                ? `url(/img/300_50.png)`
                : "none",
            backgroundSize: "cover",

            maxWidth: "100%", // Stellt sicher, dass der Container nicht über den Elterncontainer hinausgeht
            maxHeight: "100%", // Stellt sicher, dass der Container nicht über den Elterncontainer hinausgeht
          }}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <Creative ad={creative} onClick={() => onPreview(creative)} />
          )}
        </div>
      </div>
      <div className="py-8 text-sm">
        {isLoading ? (
          <LoadingBar height="h-6" width={"w-16"} type="headline" />
        ) : (
          <p className="text-gray-500">{creative?.title}</p>
        )}
        {isLoading ? (
          <div className="mt-1">
            <LoadingBar height={"h-6"} width="w-32" />
          </div>
        ) : (
          <p className="text-gray-900">
            {creative?.width + " x " + creative?.height} Pixel
          </p>
        )}
        <div className="mt-4 lg:mt-12 space-y-2 lg:space-y-6">
          <div>
            <p className="text-gray-500 ">Sichtkontakte</p>

            {isLoading ? (
              <LoadingBar height="h-8" width="w-16" type="headline" />
            ) : (
              <p className="font-normal text-3xl">
                {creative?.impressions
                  ? decimalFormatter.format(creative.impressions)
                  : "-"}
              </p>
            )}
          </div>
          <div>
            <p className="text-gray-500 ">Klicks</p>
            {isLoading ? (
              <LoadingBar height="h-8" width="w-16" type="headline" />
            ) : (
              <p className="font-normal text-3xl">
                {creative?.clicks
                  ? decimalFormatter.format(creative.clicks)
                  : "-"}
              </p>
            )}
          </div>

          {/* <div>
            <p className="text-gray-500 ">Klickrate</p>
            {isLoading ? (
              <LoadingBar height="h-8" width="w-16" type="headline" />
            ) : (
              <p className="font-normal text-3xl">
                {creative?.clicks && creative?.impressions
                  ? ((creative.clicks / creative.impressions) * 100)
                      .toFixed(2)
                      .replace(/\./g, ",") + " %"
                  : "-"}
              </p>
            )}
          </div> */}
        </div>
        {/*         <div className="mt-12 max-w-xs">
          <Button
            label="Vorschau"
            onClick={() => onPreview(creative)}
            disabled={!creative && !onPreview}
          />
        </div> */}
      </div>
    </div>
  );
};

export default CreativeReport;

import axios from "axios";
import { useContext } from "react";

import AuthContext from "../contexts/AuthContext";
import { useQuery } from "react-query";

export function useUser(token) {
  const { dispatchAuth } = useContext(AuthContext);
  return useQuery(["user", token], () => verifyUser(token), {
    retry: false,
    onSuccess: (data) => {
      dispatchAuth({ type: "USER", payload: data });
    },
  });
}
const verifyUser = async (token) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_COREAPI_URL}/verify`,
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data.data;
};

export const updateUser = async (token, userId, data) => {
  const { response } = await axios.put(
    `${process.env.REACT_APP_COREAPI_URL}/users/${userId}`,
    {
      ...data,
    },
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

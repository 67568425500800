import { useQuery } from "react-query";
import axios from "axios";

// API-URL aus Umgebungsvariablen holen
const apiUrl = process.env.REACT_APP_COREAPI_URL;

export const useBookingData = (token, customer_id) => {
  return useQuery(["bookingData", customer_id], () =>
    getBookings(token, customer_id)
  );
}

const getBookings = async (token, customer_id) => {
  try {
    const { data } = await axios.get(
      `${apiUrl}/customers/${customer_id}/bookings`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.data;
  }
  catch (error) {
    console.error("Fehler beim Abrufen der Buchungsdaten", error);
    return error.response;
  }
}
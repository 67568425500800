import React from "react";
import Card from "../../../components/Card";
import VendorCard from "../../../components/VendorCard";

const LoadingErrorMessage = () => {
  return (
    <div className="grid grid-cols-3 gap-8 mt-8">
      <div className="col-span-2">
        <Card className={"h-full"}>
          <p className="font-bold text-xl">
            Ihre Kampagnenfreigabe konnte nicht geladen werden
          </p>
          <p className="mt-2 text-gray-500 text-sm">
            Es gab ein unerwartetes Problem beim Laden Ihrer Kampagnenfreigabe.
            Bitte versuchen Sie es später erneut.
          </p>

          <p className="mt-4 text-gray-500 text-sm">
            Sollte der Fehler länger auftreten kontaktieren Sie bitte Ihren
            Ansprechpartner
          </p>
        </Card>
      </div>
      <VendorCard />
    </div>
  );
};

export default LoadingErrorMessage;

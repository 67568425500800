import React from "react";
import CustomerMap from "./maps/CustomerMap";

const CustomerBox = ({ user, customer }) => {
  return (
    <div className="md:grid grid-cols-3  mt-8 shadow-lg sm:rounded border-stw3-gray-200 bg-white">
      <div className="grid grid-col-3 col-span-3 bg-slate-100">
        <CustomerMap height={250} customer={customer} />
      </div>
    </div>
  );
};

export default CustomerBox;

import React, { useEffect, useState } from "react";
import LoadingBar from "./loading/LoadingBar";

const ReachProgressLine = ({ reach, population, isLoading }) => {
  const [progress, setProgress] = useState(0);

  var percentage = (reach / population) * 100;
  useEffect(() => {
    setProgress(percentage);
  }, [reach, population, percentage]);

  return (
    <div>
      <div className="flex relative bg-gray-300 rounded-full w-full h-3 my-2">
        <div
          className={` bg-green-600 h-full rounded-full transition duration-800 ease-out ${
            isLoading && "animate-pulse"
          }`}
          style={{ width: `${isLoading ? 2 : progress}%` }}
        ></div>
      </div>
      <div className="flex w-full justify-between text-sm text-gray-500 font-bold">
        <div className=" ">Reichweiten-Abdeckung</div>
        <div className=" text-right text-green-600">
          {isLoading ? (
            <LoadingBar height="h-5" width={"w-32"} />
          ) : (
            <span>{percentage.toFixed(0).replace(/\./g, ",")} %</span>
          )}
        </div>
      </div>
      <p className="mt-2 tracking-wider text-gray-500 text-sm">
        Anteil der Einwohner im Gebiet, die mit dieser Kampagne erreicht wurden
      </p>
    </div>
  );
};

export default ReachProgressLine;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../img/stw3_logo.png";

const InstructionForget = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="flex justify-center items-center h-screen ">
      <div className="w-full max-w-lg">
        <img
          className=" text-center font-bold leading-9 mx-auto h-10 w-auto mb-8"
          src={Logo}
          alt="Stellwerk3 GmbH"
        />
        <div className="shadow-md rounded text-center  leading-9 mb-8 bg-white">
          <form className="  mx-4 p-2 pt-6 pb-4 mb-4 bg-transparent ">
            <div className="mb-4 ">
              <label
                className="block text-sm text-left leading-6 text-gray-900 mb-2"
                htmlFor="email"
              >
                Ein Passwort wurde an ihr E-Mail-Postfach gesendet. Bitte
                überprüfen Sie Ihren Posteingang und folgen Sie den Anweisungen.
              </label>

              <div className="flex items-center justify-center mt-4">
                <button
                  id="backToLogin"
                  className="bg-primary hover:bg-primary text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={handleBackToLogin}
                >
                  Zurück zum Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InstructionForget;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useParams, useSearchParams } from "react-router-dom";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import NavTabs from "../../components/navtabs";
import Card from "../../components/Card";
import CampaignStatusBadge from "../../components/badges/CampaignStatusBadge";
import moment from "moment";
import useChannelIcons from "../../hooks/useChannelIcons";
import DatePicker from "../../components/DateRangePicker";
import LoadingBar from "../../components/loading/LoadingBar";
import HouseIcon from "../../components/icons/HouseIcon";
import MapNavigationIcon from "../../components/icons/MapNavigationIcon";
import BulletListIcon from "../../components/icons/BulletListIcon";
import KpiCard from "./components/KpiCard";
import EyeIcon from "../../components/icons/EyeIcon";
import CursorClickIcon from "../../components/icons/CursorClickIcon";
import useFormatter from "../../hooks/useFormatter";
import ArrowUpCircleIcon from "../../components/icons/ArrowUpCircleIcon";
import CupIcon from "../../components/icons/CupIcon";
import { downloadCampaignReportingPdf } from "../../hooks/useCampaignData";
import AuthContext from "../../contexts/AuthContext";
import { usePlausible } from "../../contexts/PlausibleProvider";
import Spinner from "../../components/loading/Spinner";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { BiDownload } from "react-icons/bi";

const SeaReport = () => {
  const { id } = useParams();
  const plausible = usePlausible();

  const { auth } = useContext(AuthContext);
  const { channelIcons } = useChannelIcons();
  const [searchParams, setSearchParams] = useSearchParams();
  let startDate = searchParams.get("start");
  const intervalRef = useRef(null);

  let endDate = searchParams.get("end");
  const [refresh, setRefresh] = useState(false);
  const campaignData = useRef(null);
  const [pdfDownloadStatus, setPdfDownloadStatus] = useState();

  const { decimalFormatter } = useFormatter();

  const tabs = [
    {
      name: "Übersicht",
      href: `/campaigns/sea/${campaignData.current?.id}`,
      icon: HouseIcon,
    },
    /* { name: "Suchanzeigen", href: `ads`, icon: LoupeTextIcon }, */
    { name: "Keywords", href: "keywords", icon: BulletListIcon },
    //{ name: "Sitelinks", href: "sitelinks", icon: ArrowTextEditIcon },
    { name: "Werbegebiet", href: "area", icon: MapNavigationIcon },
  ];

  useEffect(() => {
    if (pdfDownloadStatus === "loading") {
      intervalRef.current = setInterval(handleDownloadPdf, 5000);
    } else if (
      pdfDownloadStatus === "success" ||
      pdfDownloadStatus === "error"
    ) {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfDownloadStatus]);

  const handleDownloadPdf = async () => {
    setPdfDownloadStatus("loading");
    downloadCampaignReportingPdf(
      auth.token,
      id,
      `${startDate && endDate ? `?start=${startDate}&end=${endDate}` : ""}`
    ).then((response) => {
      if (response?.status === 200) {
        setPdfDownloadStatus("success");
        if (plausible) {
          plausible.trackEvent("download_sea_report_pdf", {
            props: {
              filename: response?.data?.data?.filename,
              userID: auth?.user?.user?.id,
              //campaignName: campaignData?.name,
              //customerID: campaignData?.customer?.id,
            },
          });
        }
        // download pdf
        const a = document.createElement("a");
        a.href = `data:application/pdf;base64,${response.data.data.file}`;
        a.download = response.data.data.filename;
        a.click();
        return;
      }
      if (response.status === 500 || response.status === 403) {
        setPdfDownloadStatus("error");
      }
    });
  };

  console.log(startDate, endDate);

  const checkDateRangeSelected = (start, end) => {
    return (
      start.format("YYYY-MM-DD") === startDate &&
      end.format("YYYY-MM-DD") === endDate
    );
  };

  //endDate = today ? today : endDate;
  const { data: campaign, isLoading: campaignLoading } = useSingleCampaignData(
    id,
    `?sea=true${
      startDate && endDate ? `&start=${startDate}&end=${endDate}` : ""
    }`
  );
  useEffect(() => {
    if (campaign && !campaignLoading && !campaignData.current) {
      campaignData.current = campaign;
      setRefresh(!refresh);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign, campaignLoading]);

  if (campaignData.current && !campaignData?.current?.seaOnly)
    return <Navigate to={`/campaigns/${campaign.id}`} />;

  const datePickerRanges = [
    {
      label: "Gesamte Laufzeit",
      range: () => ({
        startDate: new Date(moment(campaignData.current?.start)),
        endDate: new Date(moment(campaignData.current?.end)),
      }),
      isSelected:
        !startDate && !endDate
          ? () => {
              return true;
            }
          : () => {
              return checkDateRangeSelected(
                moment(campaignData.current?.start),
                moment()
              );
            },
    },
    {
      range: () => ({
        startDate: moment().subtract(30, "d").toDate(),
        endDate: moment().toDate(),
      }),
      label: "Letzte 30 Tage",
      isSelected: () => {
        return checkDateRangeSelected(moment().subtract(30, "d"), moment());
      },
    },
    {
      range: () => ({
        startDate: moment().startOf("quarter").toDate(),
        endDate: moment().endOf("quarter").toDate(),
      }),
      label: "Dieses Quartal",
      isSelected: () => {
        return checkDateRangeSelected(
          moment().startOf("quarter"),
          moment().endOf("quarter")
        );
      },
    },
    {
      range: () => ({
        startDate: moment().subtract(1, "Q").startOf("quarter").toDate(),
        endDate: moment().subtract(1, "Q").endOf("quarter").toDate(),
      }),
      label: "Letztes Quartal",
      isSelected: () => {
        return checkDateRangeSelected(
          moment().subtract(1, "Q").startOf("quarter"),
          moment().subtract(1, "Q").endOf("quarter")
        );
      },
    },
    {
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: moment().endOf("year").toDate(),
      }),
      label: "Dieses Jahr ",
      isSelected: () => {
        return checkDateRangeSelected(
          moment().startOf("year"),
          moment().endOf("year")
        );
      },
    },
    {
      range: () => ({
        startDate: moment().subtract(1, "Y").startOf("year").toDate(),
        endDate: moment().subtract(1, "Y").endOf("year").toDate(),
      }),
      label: "Letztes Jahr",
      isSelected: () => {
        return checkDateRangeSelected(
          moment().subtract(1, "Y").startOf("year"),
          moment().subtract(1, "Y").endOf("year")
        );
      },
    },
  ];
  const handleDateSelect = ({ start, end }) => {
    setSearchParams({
      ...searchParams,
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
    });
  };

  return (
    <>
      <div className="mt-4 md:mt-8">
        <Card>
          <div className="md:flex justify-between items-center">
            <div>
              {campaignLoading && !campaignData.current ? (
                <div className="space-y-2">
                  <LoadingBar height="h-12" width="w-32" type="headline" />
                  <LoadingBar height="h-4" width="w-64" />
                </div>
              ) : (
                <div>
                  <h1 className="font-semibold text-20">
                    {campaignData.current?.customer?.company}
                  </h1>
                  <h2 className="text-14 font-normal mt-1">
                    {campaignData.current?.name}
                  </h2>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between md:justify-normal mt-4 md:mt-0 space-x-4">
              <div className="md:text-right text-sm text-gray-500">
                <p>Auswertung vom</p>
                <p className="text-gray-900">{moment().format("DD.MM.YYYY")}</p>
              </div>
              {campaignLoading && !campaignData.current ? (
                <LoadingBar height="h-8" width="w-24" />
              ) : (
                <CampaignStatusBadge
                  status={campaignData.current?.status}
                  startDate={campaignData.current?.start}
                />
              )}
            </div>
          </div>
          <div className="mt-4 md:mt-8 md:flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <div className="rounded bg-gray-100 flex-shrink-0 p-3">
                {channelIcons("sea", "h-5 ")}
              </div>
              <div>
                <p className="text-gray-500 text-sm">Laufzeit</p>
                {campaignLoading && !campaignData.current ? (
                  <div className="mt-1">
                    <LoadingBar height="h-4" width="w-44" />
                  </div>
                ) : (
                  <p className="text-sm">
                    {moment(campaignData.current?.start).format("DD.MM.YYYY")} -{" "}
                    {moment(campaignData.current?.end).format("DD.MM.YYYY")}
                  </p>
                )}
              </div>
            </div>
            <div className="mt-4 md:mt-0 flex items-center space-x-4">
              <button
                className={`flex items-center space-x-2 text-primary text-sm invisible`}
                onClick={handleDownloadPdf}
              >
                {pdfDownloadStatus === "loading" ? (
                  <Spinner />
                ) : pdfDownloadStatus === "error" ? (
                  <ExclamationCircleIcon className="h-5 w-5" />
                ) : (
                  <BiDownload className="h-5 w-5" />
                )}
                {pdfDownloadStatus === "loading" ? (
                  <p>generiere PDF</p>
                ) : pdfDownloadStatus === "error" ? (
                  <p>PDF kann nicht erstellt werden</p>
                ) : (
                  <p>PDF-Herunterladen</p>
                )}
              </button>
              <DatePicker
                start={searchParams.get("start") ?? campaignData.current?.start}
                end={searchParams.get("end") ?? campaignData.current?.end}
                onChange={handleDateSelect}
                minDate={new Date(campaignData.current?.start)}
                maxDate={new Date()}
                definedRanges={datePickerRanges}
              />
            </div>
          </div>
        </Card>
        <div className="md:grid grid-cols-2 lg:grid-cols-4 space-y-4 md:space-y-0 md:gap-4 mt-10 ">
          <KpiCard
            isLoading={campaignLoading}
            label="Sichtkontakte"
            icon={<EyeIcon />}
            kpi={
              campaign?.sum?.impressions
                ? decimalFormatter.format(campaign?.sum?.impressions)
                : "-"
            }
            description={`Die Anzeigen wurden ${decimalFormatter.format(
              campaign?.sum?.impressions
            )} mal gesehen`}
          />

          <KpiCard
            isLoading={campaignLoading}
            label="An Top-Positionen"
            icon={<ArrowUpCircleIcon />}
            kpi={
              campaign?.sum?.top_impression_percentage
                ? decimalFormatter.format(
                    (
                      campaign?.sum?.impressions *
                      campaign?.sum?.top_impression_percentage
                    )?.toFixed(0)
                  )
                : "-"
            }
            description={`Die Anzeigen waren zu ${
              (campaign?.sum?.top_impression_percentage * 100)?.toFixed(0) +
              " %"
            } auf den Positionen 1-4`}
          />
          <KpiCard
            isLoading={campaignLoading}
            label="An Top-Position"
            icon={<CupIcon />}
            kpi={
              campaign?.sum?.top_impression_percentage
                ? decimalFormatter.format(
                    (
                      campaign?.sum?.impressions *
                      campaign?.sum?.top_impression_percentage_absolute
                    )?.toFixed(0)
                  )
                : "-"
            }
            description={`Die Anzeigen waren zu ${
              (
                campaign?.sum?.top_impression_percentage_absolute * 100
              )?.toFixed(0) + " %"
            } auf der Position 1`}
          />
          <KpiCard
            isLoading={campaignLoading}
            label="Klicks"
            icon={<CursorClickIcon />}
            kpi={
              campaign?.sum?.clicks
                ? decimalFormatter.format(campaign?.sum?.clicks)
                : "-"
            }
            description={`Die Anzeige wurde ${
              campaign?.sum?.clicks
                ? decimalFormatter.format(campaign?.sum?.clicks)
                : 0
            } mal angeklickt`}
          />
        </div>
      </div>

      <div className="mt-4 md:mt-8 mb-4 md:mb-0">
        <NavTabs tabs={tabs} />
      </div>
      <Outlet />
    </>
  );
};

export default SeaReport;

import { useQuery } from "react-query";
import axios from "axios";

// API-URL aus Umgebungsvariablen holen
const apiUrl = process.env.REACT_APP_COREAPI_URL;
export const useOffers = (token, customerId) => {
  // useQuery-Hook, der die fetchData-Funktion aufruft
  return useQuery(["offer", customerId], () =>
    getOffersByCustomerId(token, customerId)
  );
};

const getOffersByCustomerId = async (token, customerId) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_COREAPI_URL}/customers/${customerId}/offers`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error("Fehler beim Abrufen der Angebotsdaten", error);
    throw error;
  }
};

export const useOffer = (offerHash, token) => {
  // useQuery-Hook, der die fetchData-Funktion aufruft
  return useQuery(["offerData", offerHash], () =>
    getOfferByHash(offerHash, token)
  );
};

//Important is type for hook layer
const getOfferByHash = async (offerHash, token) => {
  // Fetch campaign data from the API using campaignId

  try {
    const { data } = await axios.get(`${apiUrl}/offers/hash/${offerHash}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return data.data; // Daten zurückgeben
  } catch (error) {
    console.error("Fehler beim Abrufen der Angebotsdaten", error);
    throw error; // Fehler werfen, um ihn in der aufrufenden Komponente zu behandeln
  }
};

export const acceptOffer = async (offerHash, token) => {
  try {
    const { data } = await axios.put(
      `${apiUrl}/offers/hash/${offerHash}/accept`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Fehler beim Annehmen des Angebots", error);
    return error.response;
  }
};

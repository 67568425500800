import React from "react";
import Badge from "./Badge";

const CampaignStatusBadge = ({ status, startDate }) => {
  const getLabel = () => {
    switch (status) {
      case "active":
        return "Aktiv";
      case "accepted":
        return "Bevorstehend";
      case "pending":
        return "Bevorstehend";
      case "in_coordination":
        return "Bereit zur Freigabe";
      case "finished":
        return "Beendet";
      case "rejected":
        return "Wird überarbeitet";
      default:
        return "";
    }
  };

  const getColor = () => {
    switch (status) {
      case "accepted":
        return "yellow";
      case "active":
        return "green";
      case "rejected":
        return "orange";
      case "pending":
        return "yellow";
      case "in_coordination":
        return "blue";
      case "finished":
        return null;
      default:
        return null;
    }
  };

  return <Badge label={getLabel()} color={getColor()} size={"sm"} bold />;
};

export default CampaignStatusBadge;

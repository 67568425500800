import React from "react";
import useFormatter from "../../../hooks/useFormatter";
import SummationFooter from "../../../components/tables/SummationFooter";
import { Tooltip } from "react-tooltip";

const usePlacementColumns = () => {
  const { decimalFormatter } = useFormatter();

  const columns_placement = React.useMemo(
    () => [
      {
        Header: "Platzierung",
        accessor: "title",
        Footer: <p className="font-bold">Gesamt</p>,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Anzahl von Klicks auf das Werbemittel"
            >
              Klicks
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "clicks",
        align: "right",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        Footer: ({ rows }) => <SummationFooter rows={rows} kpi="clicks" />,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Anzahl der Einblendungen des 
digitalen Werbemittels 
"
            >
              Sichtkontakte
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "impressions",
        align: "right",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        Footer: ({ rows }) => <SummationFooter rows={rows} kpi="impressions" />,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Das Verhältnis von Klicks zu Sichtkontakten
"
            >
              Klickrate
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "clickrate",
        align: "right",
        Cell: ({ value }) =>
          value ? (value * 100).toFixed(2).replace(/\./g, ",") + " %" : "-",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { columns_placement };
};

export default usePlacementColumns;

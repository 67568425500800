import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBooking } from "../../../hooks/useBookings";
import AuthContext from "../../../contexts/AuthContext";
import Card, { CardHeader } from "../../../components/Card";
import { HiMiniCheckCircle } from "react-icons/hi2";
import Button from "../../../components/buttons/Button";
import VendorCard from "../../../components/VendorCard";
import moment from "moment/moment";
import { useKeywordsByKeywordgroupId } from "../../../hooks/useKeywordgroups";
import LoadingBar from "../../../components/loading/LoadingBar";

const BookingConfirmation = () => {
  const { auth } = useContext(AuthContext);
  const { hash } = useParams();
  const navigate = useNavigate();
  const [keywordGroupId, setKeywordGroupId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const { data: booking, isLoading } = useBooking(auth?.token, hash);
  const { data: keywordGroup } = useKeywordsByKeywordgroupId(
    auth?.token,
    keywordGroupId,
    customerId
  );

  useEffect(() => {
    if (booking?.adtemplates?.length > 0) {
      setKeywordGroupId(booking?.adtemplates[0]?.keywordgroup_id);
    }
    if (booking?.customer_id) {
      setCustomerId(booking?.customer_id);
    }
  }, [booking]);
  const bulletPoints = [
    `Wir haben Ihre Freigabe erfolgreich erhalten.`,
    "Falls vor Ihrem Kampagnenstart Fragen aufkommen, melden Sie sich gerne.",
    "Wir betreuen Sie persönlich und kompetent während Ihrer Kampagne.",
    "Nach Ihrer Kampagne besprechen wir gemeinsam Ihre Erfolge und Ergebnisse.",
  ];

  return (
    <div className="lg:grid grid-cols-3 gap-4 mt-8">
      <div className="col-span-2 h-full">
        <Card className={"h-full"}>
          <CardHeader
            title="Vielen Dank für Ihre Freigabe!"
            description={
              <p>
                Ihre Kampagne <b>{booking?.title ?? ""}</b> wurde freigegeben.
                Sie erhalten in Kürze eine E-Mail mit <br />
                allen Daten zu Ihrer freigegebenen Kampagne.
              </p>
            }
          />
          <ol className="space-y-4">
            {bulletPoints.map((point) => (
              <li key={point} className="flex items-center space-x-4">
                <div>
                  <HiMiniCheckCircle className="text-green-500 h-6 w-6" />
                </div>
                <p className="text-gray-500 text-base whitespace-pre-line">
                  {point}
                </p>
              </li>
            ))}
          </ol>
          <div className="mt-12 max-w-xs">
            <Button
              type="button"
              variant="primary"
              label="weiter zur Startseite"
              onClick={() => navigate("/")}
            />
          </div>
        </Card>
      </div>
      <div className="flex flex-col h-full space-y-4">
        <Card className={"h-full"}>
          <CardHeader title="Kampagneninformationen" />
          <div className="space-y-2 divide-y divide-gray-200 text-sm">
            <div className="grid grid-cols-2 space-x-2">
              <p className="text-gray-500">Kampagnenthema</p>
              <p className="font-semibold">{booking?.title}</p>
            </div>
            <div className="grid grid-cols-2 space-x-2 pt-2">
              <p className="text-gray-500">Kampagnenlaufzeit</p>
              {isLoading ? (
                <LoadingBar height={"h-full"} width={"w-full"} />
              ) : (
                <p className="font-semibold">
                  {moment(booking?.start).format("DD.MM.YYYY")} -{" "}
                  {moment(booking?.end).format("DD.MM.YYYY")}
                </p>
              )}
            </div>
            <div className="grid grid-cols-2 space-x-2 pt-2">
              <p className="text-gray-500">Kampagneninhalte</p>
              {isLoading ? (
                <LoadingBar height={"h-12 "} width={"w-2/3"} />
              ) : (
                <div className="">
                  {booking?.adtemplates?.length > 0 && (
                    <p className="font-semibold">
                      {booking?.adtemplates?.length}{" "}
                      {booking?.is_sea
                        ? "Suchmaschinen-Anzeigen"
                        : "HTML-Banner"}
                    </p>
                  )}
                  {keywordGroup?.length > 0 && (
                    <p className="font-semibold mt-1">
                      {keywordGroup.length} Keywords
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </Card>

        <VendorCard />
      </div>
    </div>
  );
};

export default BookingConfirmation;

import React, { useEffect, useRef, useState } from "react";
import KpiCard from "./components/KpiCard";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import { useParams, useSearchParams } from "react-router-dom";
import useFormatter from "../../hooks/useFormatter";
import Card from "../../components/Card";
import SeaAd from "./components/SeaAd";
import { Disclosure } from "@headlessui/react";
import noDataImg from "../../img/no_data.jpeg";
import LoadingBar from "../../components/loading/LoadingBar";

const Overview = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get("start");
  const campaign = useRef([]);
  // eslint-disable-next-line no-unused-vars
  const [refresh, setRefresh] = useState(false);
  const endDate = searchParams.get("end");
  const [inactiveAds, setInactiveAds] = useState([]);
  const [activeAds, setActiveAds] = useState([]);
  const { decimalFormatter } = useFormatter();
  const [topAd, setTopAd] = useState(null);
  const { data: campaignData, isLoading } = useSingleCampaignData(
    id,
    `?sea=true${
      startDate && endDate ? `&start=${startDate}&end=${endDate}` : ""
    }`
  );
  useEffect(() => {
    if (campaignData) {
      campaign.current = campaignData;
      if (campaignData.channels.sea[0]?.adgroups?.length > 0) {
        let highestImpressionsAd = null;
        campaignData.channels.sea[0]?.adgroups.forEach((adgroup) => {
          adgroup.ads.forEach((ad) => {
            if (
              !highestImpressionsAd ||
              ad.impressions > highestImpressionsAd.impressions
            ) {
              highestImpressionsAd = ad;
            }
          });
        });
        setTopAd(highestImpressionsAd);
      }
      setRefresh((prev) => !prev);
    }
  }, [campaignData]);
  useEffect(() => {
    if (campaignData) {
      setActiveAds(
        campaignData.channels.sea[0].adgroups.flatMap((adgroup) =>
          adgroup.ads.filter(
            (ad) =>
              ad.status?.toLowerCase() === "enabled" ||
              ad.status?.toLowerCase() === "active"
          )
        )
      );

      setInactiveAds(
        campaignData.channels.sea[0].adgroups.flatMap((adgroup) =>
          adgroup.ads.filter(
            (ad) =>
              ad.status?.toLowerCase() === "paused" ||
              ad.status?.toLowerCase() === "disabled" ||
              ad.status === undefined ||
              ad.status === null
          )
        )
      );
    }
  }, [campaignData]);

  return (
    <>
      {/*  <div className="md:grid grid-cols-2 lg:grid-cols-3 space-y-4 md:space-y-0 md:gap-4 ">
        <div className="col-span-2 lg:col-span-1 md:grid space-y-4 md:space-y-0 grid-cols-2 lg:grid-cols-1 gap-4">
          <KpiCard
            isLoading={isLoading}
            label="An Top-Positionen"
            icon={<ArrowUpCircleIcon />}
            kpi={
              campaign.current?.sum?.top_impression_percentage
                ? (
                    campaign.current?.sum?.impressions *
                    campaign.current?.sum?.top_impression_percentage
                  )?.toFixed(0)
                : "-"
            }
            description={`Sie waren zu ${
              (campaign.current?.sum?.top_impression_percentage * 100)?.toFixed(
                0
              ) + " %"
            } auf den Positionen 1-4`}
          />
          <KpiCard
            isLoading={isLoading}
            label="An Top-Position"
            icon={<CupIcon />}
            kpi={
              campaign.current?.sum?.top_impression_percentage_absolute
                ? (
                    campaign.current?.sum?.top_impression_percentage_absolute *
                    100
                  )?.toFixed(0) + " %"
                : "-"
            }
            description={`Sie waren zu ${
              (
                campaign.current?.sum?.top_impression_percentage_absolute * 100
              )?.toFixed(0) + " %"
            } auf der ersten Position`}
          />
        </div>
        <div className="col-span-2">
          <CampaignmapWithStats isSea />
        </div> */}
      {/* <KpiCard
          isLoading={isLoading}
          label="Sichtkontakte"
          icon={<EyeIcon />}
          kpi={
            campaign.current?.sum?.impressions
              ? decimalFormatter.format(campaign.current?.sum?.impressions)
              : "-"
          }
          description={`Ihre Anzeigen wurden ${decimalFormatter.format(
            campaign.current?.sum?.impressions
          )} mal gesehen`}
        />
        <KpiCard
          isLoading={isLoading}
          label="Klicks"
          icon={<CursorClickIcon />}
          kpi={
            campaign.current?.sum?.clicks
              ? decimalFormatter.format(campaign.current?.sum?.clicks)
              : "-"
          }
          description={`Ihre Anzeige wurde ${
            campaign.current?.sum?.clicks
              ? decimalFormatter.format(campaign.current?.sum?.clicks)
              : 0
          } mal geklickt`}
        />
        <KpiCard
          isLoading={isLoading}
          label="Klickrate"
          icon={<StarIcon />}
          kpi={
            campaign.current?.sum?.ctr
              ? campaign.current?.sum?.ctr?.toFixed(2).replace(/\./g, ",") +
                " %"
              : "-"
          }
          description={`Ihre Anzeige wurde im Schnitt ${
            campaign.current?.sum?.ctr?.toFixed(2).replace(/\./g, ",") + " %"
          } mal geklickt`}
        /> 
      </div>*/}
      <div>
        <Card>
          {isLoading ? (
            <div className="text-center border-2 border-dashed  flex items-center justify-center h-64 rounded">
              <LoadingBar height="h-5" width={"w-32"} />
            </div>
          ) : (
            activeAds.length === 0 && (
              <div className="text-center border-2 border-dashed  flex items-center justify-center h-64 rounded">
                <div>
                  <div className="flex justify-center">
                    <img
                      src={noDataImg}
                      alt="no_data_found"
                      className="w-36 h-auto"
                    />
                  </div>
                  <h4 className="text-gray-300 font-bold text-center text-sm">
                    Leider konnten wir keine aktiven Anzeigen finden.
                  </h4>
                </div>
              </div>
            )
          )}
          <ol className="space-y-4 divide-y divide-gray-200">
            {activeAds
              .sort((a, b) => (a.impressions < b.impressions ? -1 : 1))
              .map((ad, index) => (
                <li className="pt-4 first:pt-0">
                  <SeaAd
                    ad={ad}
                    isLoading={isLoading}
                    index={index + 1}
                    key={"activeAd" + index}
                  />
                </li>
              ))}
          </ol>
        </Card>
        {inactiveAds.length > 0 && (
          <div className="mt-4">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`py-2 text-center text-primary  bg-white ${
                      open ? "rounded" : "rounded"
                    } shadow-md p-5 w-full flex justify-center`}
                  >
                    <p className="border-b border-primary max-w-fit font-semibold my-4 text-sm ">
                      Inaktive Anzeigen ({inactiveAds.length}){" "}
                      {open ? "ausblenden" : "einblenden"}
                    </p>
                  </Disclosure.Button>
                  <Disclosure.Panel className="bg-white px-5 pb-5 shadow-md rounded space-y-4">
                    {inactiveAds
                      .sort((a, b) => a.impressions > b.impressions)
                      .map((ad, index) => (
                        <SeaAd
                          ad={ad}
                          isLoading={isLoading}
                          key={"inactiveAd" + index}
                          index={activeAds.length + index + 1}
                        />
                      ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        )}
      </div>
    </>
  );
};

export default Overview;

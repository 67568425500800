import { React, useEffect, useRef } from "react";
import MapComponent from "../MapComponent";
import Spinner from "../loading/Spinner";
import { bbox } from "@turf/turf";
import useServiceAPI from "../../hooks/useServiceAPI";
import { useLocation } from "react-router-dom";
import MapInteraction from "./MapInteraction";

const CustomerMap = ({ height, roundedBottom, zoomUi, customer }) => {
  const ref = useRef(null);
  const mapInteractionRef = useRef(null);
  const geoData = useRef(null);
  const { getGeoData } = useServiceAPI();
  const location = useLocation();
  const isOfferPage = location.pathname.includes("/offer/");

  useEffect(() => {
    if (customer?.map) {
      getGeoData(customer?.map?.postcodes).then((response) => {
        if (!response) return;
        geoData.current = response.data;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (geoData.current?.length > 0 && ref.current) {
      mapInteractionRef.current.setPolygonsAndHover(geoData?.current);
    }
    if (customer?.lat && customer?.lng && ref.current) {
      mapInteractionRef.current.setMarker(customer.lat, customer.lng);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoData.current, ref.current, customer?.lat, customer?.lng]);

  const handleMapReset = () => {
    if (geoData.current && customer?.lat && customer?.lng) {
      let boundingBox = bbox(geoData.current);
      ref.current.fitBounds(boundingBox, {
        padding: 20,
      });
    }
  };
  if (!customer)
    return (
      <div
        className={`w-full flex items-center justify-center  bg-slate-100 ${
          roundedBottom ? "rounded-sm" : "rounded-sm"
        } `}
        style={{ height: height + "px" }}
      >
        <Spinner />
      </div>
    );

  return (
    <div className="relative shadow-lg sm:rounded border-stw3-gray-200">
      <MapComponent
        zoomUi={zoomUi}
        onMapReset={handleMapReset}
        ref={ref}
        lati={customer?.lat}
        long={customer?.lng}
        className="rounded z-40 w-full"
        height={height}
        roundedBottom={roundedBottom}
      />
      <MapInteraction mapInstance={ref.current} ref={mapInteractionRef} />

      {MapComponent && !isOfferPage && (
        <>
          <div className="customer-map-logo sm:rounded hidden sm:block">
            <img src={customer?.logo_url} alt="logo" />
          </div>
        </>
      )}
    </div>
  );
};
export default CustomerMap;

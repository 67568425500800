import { useEffect, useState } from "react";

const useSeaAdtemplateParameters = (parameters, deeplink) => {
  const [headline, setHeadline] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [deeplinkDomain, setDeeplinkDomain] = useState("");

  useEffect(() => {
    if (parameters?.length > 0) {
      setHeadline(
        parameters
          .filter((p) => p.name.includes("headline"))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((p) => p.value)
          .join(" | ")
      );
      let tmp = document.createElement("a");
      tmp.href = deeplink ?? "";
      setDeeplinkDomain("https://" + tmp.hostname);
      tmp = "https://" + tmp.hostname;
      let path = parameters.filter((p) => p.name.includes("path"));
      if (path.length > 1) {
        tmp +=
          "/" +
          path
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((p) => p.value)
            .join("/");
      } else if (path.length === 1) {
        tmp += "/" + path[0].value;
      }
      setUrl(tmp);

      setDescription(
        parameters
          .filter((p) => p.name.includes("description"))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((p) => p.value)
          .join("\n")
      );
    }
  }, [parameters, deeplink]);

  return { headline, url, description, deeplinkDomain };
};

export default useSeaAdtemplateParameters;

import React from "react";

const Input = ({
  onChange,
  value,
  name,
  placeholder,
  defaultValue,
  required,
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <input
      onChange={handleChange}
      name={name}
      id={name}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      required={required}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
    />
  );
};

export default Input;

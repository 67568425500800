import React, { useEffect, useState } from "react";
import CampaignMap from "./CampaignMap";
import { Link, useParams } from "react-router-dom";
import LoadingBar from "../loading/LoadingBar";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import useFormatter from "../../hooks/useFormatter";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import useServiceAPI from "../../hooks/useServiceAPI";

const CampaignmapWithStats = ({ mapHeight, isSea }) => {
  const { id } = useParams();
  const { data: campaign, isLoading } = useSingleCampaignData(id);
  const { getGeoData } = useServiceAPI();
  const { decimalFormatter } = useFormatter();
  const [plzList, setPlzList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (isLoading || !campaign.geo?.data) {
        return;
      }
      try {
        const result = await getGeoData(campaign?.geo?.data);
        setPlzList(result.data);
      } catch (error) {
        console.error("Ein Fehler ist aufgetreten:", error);
      }
    }
  });

  //Summe aller Einwohner 16-65 pro plz
  /*   const sumAge = plzList.reduce((acc, cur) => {
    return acc + cur.population_age_range;
  }, 0);
 */
  return (
    <div>
      <CampaignMap height={mapHeight ?? 220} roundedBottom={false} />
      <div className="container shadow-lg sm:rounded  h-full w-full p-5 bg-white hover:shadow-xl">
        <Link to={"area"}>
          <div className="flex justify-between items-center">
            <div className="flex space-x-12 sm:space-x-24">
              <div>
                <p className="text-gray-500 text-sm">Einwohner</p>
                {isLoading ? (
                  <LoadingBar height="h-10" width={"w-28"} type={"headline"} />
                ) : (
                  <p className="text-3xl font-normal mt-1">
                    {campaign?.geo?.sum?.sum_population
                      ? decimalFormatter.format(
                          campaign?.geo?.sum?.sum_population
                        )
                      : "-"}
                  </p>
                )}
              </div>
              {/*  <div>
                <p className="text-gray-500 text-sm">18-80 jährige</p>
                {isLoading ? (
                  <LoadingBar height="h-10" width={"w-28"} type={"headline"} />
                ) : (
                  <p className="text-3xl font-normal mt-1">
                    {sumAge ? decimalFormatter.format(sumAge) : "-"}
                  </p>
                )}
              </div> */}
              <div>
                <p className="text-gray-500 text-sm">PLZ-Gebiete</p>
                {isLoading ? (
                  <LoadingBar height="h-10" width={"w-28"} type={"headline"} />
                ) : (
                  <p className="text-3xl font-normal mt-1">
                    {campaign?.geo?.data?.length
                      ? decimalFormatter.format(campaign?.geo?.data?.length)
                      : "-"}
                  </p>
                )}
              </div>
            </div>
            <ChevronRightIcon className="h-10 w-10 text-stw3-gray-500" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CampaignmapWithStats;

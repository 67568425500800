import React, { useContext, useEffect, useState } from "react";
import Card from "./Card";
import LoadingBar from "./loading/LoadingBar";
import { useCustomer } from "../hooks/useCustomerData";
import AuthContext from "../contexts/AuthContext";

const VendorCard = () => {
  const { auth } = useContext(AuthContext);
  const { isLoading, data: customer } = useCustomer(
    auth?.token,
    auth?.user?.customer_id
  );

  const [vendorData, setVendorData] = useState(null);

  useEffect(() => {
    if (!isLoading && customer?.vendor) {
      setVendorData(customer.vendor);
    }
  }, [customer, isLoading]);

  if (isLoading) {
    return (
      <>
        <Card>
          <LoadingBar type="headline" width={"w-52"} height={"h-6"} />
          <div className="pt-7">
            <div className="sm:flex sm:items-center sm:justify-between space-x-4">
              <div className="flex-shrink-0">
                <div
                  className={"rounded-full w-20 h-20 bg-gray-200 animate-pulse"}
                ></div>
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left flex flex-col justify-evenly w-full space-y-2">
                <LoadingBar width={"w-32"} height="h-4" type="headline" />
                <LoadingBar width={"w-24"} height="h-4" />
                <LoadingBar width={"w-24"} height="h-4" />
              </div>
            </div>
          </div>
        </Card>
      </>
    );
  }

  if (!vendorData) {
    return (
      <>
        <Card>
          <h1 className="font-bold text-xl">Sie haben Fragen?</h1>
          <div className="bg-white pt-2">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                <div className="mt-2 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p className="text-sm font-medium text-gray-600">
                    Kontaktieren Sie uns gerne!
                  </p>{" "}
                  <p className="text-sm font-medium text-gray-600">
                    0221 / 42480-100
                  </p>
                  <p className="text-sm font-medium text-gray-600">
                    info@stellwerk3.de
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </>
    );
  }
  return (
    <>
      <Card>
        <h1 className="font-bold text-xl">Ihr Ansprechpartner</h1>
        <div className="pt-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                <img
                  className="mx-auto h-20 w-20 rounded-full"
                  src={vendorData?.img}
                  alt=""
                />
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="font-bold text-gray-900">
                  {vendorData.first_name} {vendorData.last_name}
                </p>
                <p className="text-sm font-medium text-gray-600">
                  {vendorData.phone}
                </p>

                <p className="text-sm font-medium text-gray-600">
                  {vendorData?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
export default VendorCard;

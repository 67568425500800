import React from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import LoginForm from "../pages/LoginForm";
import AppRoute from "../pages/AppRoute";
import Campaigns from "../pages/campaigns/index";
import DisplayReport from "../pages/displayReport/DisplayReport";
import Overview from "../pages/displayReport/Overview";
import SeaOverview from "../pages/seaReport/Overview";
import ReportReach from "../pages/displayReport/ReportReach";
import ReportAdvertiser from "../pages/displayReport/ReportAdvertiser";
import ReportCreatives from "../pages/displayReport/ReportCreatives";
import ReportLocation from "../pages/displayReport/ReportLocation";
import ActiveCampaigns from "../pages/campaigns/ActiveCampaigns";
import PendingCampaigns from "../pages/campaigns/PendingCampaigns";
import FinishedCampaigns from "../pages/campaigns/FinishedCampaigns";
import SeaReport from "../pages/seaReport/Index";
import Ads from "../pages/seaReport/Ads";
import Keywords from "../pages/seaReport/Keywords";
import Sitelinks from "../pages/seaReport/Sitelinks";
import NotFound from "../pages/NotFound";
import PasswordForget from "../pages/passwordReset/PasswordForget";
import Instruction from "../pages/passwordReset/InstructionPassword";
import SetNewPassword from "../pages/passwordReset/SetNewPassword";
import SuccessChange from "../pages/passwordReset/SuccessChange";
import Dashboard from "../pages/Dashboard";
import DisplayCampaigns from "../pages/DisplayCampaigns";
import SearchCampaigns from "../pages/SearchCampaigns";
import Impressum from "../pages/Impressum";
import Dsgvo from "../pages/Dsgvo";
import ProfileForm from "../pages/AccountInformation";
import MainLayout from "./MainLayout";
import Offer from "../pages/offer/Index";
import Offers from "../pages/offers/Index";
import BookingConfirmation from "../pages/booking/confirmation";
import OpenRoutes from "../pages/OpenRoutes";
import BookingOverview from "../pages/booking/Overview";
import BookingRejection from "../pages/booking/rejection";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/offer/:hash" element={<Offer />} />
        <Route element={<MainLayout />}>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/privacy" element={<Dsgvo />} />
          <Route
            path="/passwordReset/forgotpassword"
            element={<PasswordForget />}
          />
          <Route
            path="/passwordReset/forgotpassword/instruction"
            element={<Instruction />}
          />
          <Route path="/reset-password/:hash" element={<SetNewPassword />} />{" "}
          <Route path="/successChange" element={<SuccessChange />} />
          <Route element={<OpenRoutes />}>
            <Route path="/bookings/:hash" element={<BookingOverview />} />
            <Route
              path="/bookings/:hash/confirmation"
              element={<BookingConfirmation />}
            />
            <Route
              path="/bookings/:hash/rejection"
              element={<BookingRejection />}
            />
          </Route>
          <Route path="/" element={<AppRoute />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/display" element={<DisplayCampaigns />} />
            <Route path="/search" element={<SearchCampaigns />} />
            {<Route path="/offers" element={<Offers />} />}

            <Route path="/profile" element={<ProfileForm />} />

            <Route path="dashboard/privacy" element={<Dsgvo />} />
            <Route path="dashboard/impressum" element={<Impressum />} />

            <Route path="campaigns" element={<Campaigns />}>
              <Route index element={<ActiveCampaigns />} />
              <Route path="pending" element={<PendingCampaigns />} />
              <Route path="finished" element={<FinishedCampaigns />} />
            </Route>
            <Route path="/campaigns/:id" element={<DisplayReport />}>
              <Route index element={<Overview />} />
              <Route path="reach" element={<ReportReach />} />
              <Route path="advertiser" element={<ReportAdvertiser />} />
              <Route path="creatives" element={<ReportCreatives />} />
              <Route path="area" element={<ReportLocation />} />
            </Route>
            <Route path="/campaigns/sea/:id" element={<SeaReport />}>
              <Route index element={<SeaOverview />} />
              <Route path="ads" element={<Ads />} />
              <Route path="keywords" element={<Keywords />} />
              <Route path="sitelinks" element={<Sitelinks />} />
              <Route path="area" element={<ReportLocation />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;

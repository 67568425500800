import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { useOffer } from "../../hooks/useOfferData";
import moment from "moment";
import useFormatter from "../../hooks/useFormatter";
import { FaCheckCircle } from "react-icons/fa";
import LoadingBar from "../../components/loading/LoadingBar";

const Subjects = () => {
  const { auth } = useContext(AuthContext);

  const { hash } = useParams();
  const { data: offer, isLoading } = useOffer(hash, auth.token);
  const { decimalFormatter, formatEuroWithoutCents } = useFormatter();

  return (
    <div className="text-[#1E2938]">
      <h2 className="font-bold text-xl">Angebotsübersicht</h2>
      <div className="border border-axColdgray-100 mt-6">
        <div className="border-b border-axColdgray-100 p-4 font-bold text-lg">
          {isLoading ? (
            <LoadingBar type="headline" width="w-64" height="h-8" />
          ) : (
            <h3>
              {offer?.title} {" | "}{" "}
              {offer?.products?.length === 1
                ? "1 Thema"
                : offer?.products?.length + " Themen"}
            </h3>
          )}
        </div>

        <div className=" border-axColdgray-100  sm:pt-0">
          {/* 
        1) MOBILE LAYOUT (CARD STYLE)
        Hidden on sm+
      */}
          <ol className="sm:hidden divide-y divide-axColdgray-100">
            <li className="flex p-4 space-x-5">
              <p className="col-span-3">Geschäftsgebiet</p>
              {isLoading ? (
                <LoadingBar height="h-5" width="w-32" />
              ) : (
                <p className="whitespace-nowrap font-bold">
                  {offer?.customer?.company}
                </p>
              )}
            </li>
            <li className="p-4 flex space-x-12">
              <p className="col-span-3">Werbeträger</p>
              <p className="whitespace-nowrap font-bold">Google.de</p>
            </li>
            {isLoading
              ? /* Loading (Mobile) */
                Array(3)
                  .fill(0)
                  .map((_, i) => (
                    <li key={"mobile-loading-" + i} className="p-4 space-y-2">
                      <LoadingBar height="h-6" width="w-36" />
                      <LoadingBar height="h-6" width="w-32" />
                      <LoadingBar height="h-6" width="w-32" />
                      <LoadingBar height="h-6" width="w-32" />
                    </li>
                  ))
              : /* Data (Mobile) */
                offer?.products?.map((product, i) => (
                  <li key={product.title + i} className="p-4 text-base">
                    {/* Thema */}
                    <p className="font-bold">{product.title}</p>

                    {/* Laufzeit */}
                    <p className="mb-2">
                      {product.start && product.end
                        ? moment(product.start).format("DD.MM.YYYY") +
                          " - " +
                          moment(product.end).format("DD.MM.YYYY")
                        : product.start && product.runtime
                          ? product.runtime +
                            " Monate ab " +
                            moment(product.start).format("DD.MM.YYYY")
                          : product.runtime + " Monate"}
                    </p>
                    <div className="flex flex-row">
                      {/* Klickprognose */}
                      <div className="flex flex-col space-y-1">
                        <span className="font-bold">Klickprognose</span>
                        <span>
                          {decimalFormatter.format(product.clicks_min)} -{" "}
                          {decimalFormatter.format(product.clicks_max)}
                        </span>
                      </div>

                      {/* Budget */}
                      <div className="flex flex-col ml-8 space-y-1">
                        <span className=" font-bold">Gesamtbudget</span>
                        <span>
                          {formatEuroWithoutCents(
                            product.budget + product.setup
                          )}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
          </ol>

          {/* 
        2) DESKTOP LAYOUT (TABLE)
        Hidden on mobile 
      */}
          <div className="hidden sm:block w-full overflow-x-auto">
            <ol className="p-4 space-y-4 text-base border-b">
              <li className="sm:grid grid-cols-12 gap-4">
                <p className="col-span-3">Geschäftsgebiet</p>
                {isLoading ? (
                  <LoadingBar height="h-5" width="w-32" />
                ) : (
                  <p className="whitespace-nowrap font-bold">
                    {offer?.customer?.company}
                  </p>
                )}
              </li>
              <li className="sm:grid grid-cols-12 gap-4">
                <p className="col-span-3">Werbeträger</p>
                <p className="whitespace-nowrap font-bold">Google.de</p>
              </li>
            </ol>
            {isLoading ? (
              /* Loading (Desktop) */
              <table className="min-w-full text-left border-collapse">
                <thead className="text-base font-bold border-b border-axColdgray-100">
                  <tr>
                    <th className="py-2 px-4">Thema</th>
                    <th className="py-2 px-4">Laufzeit</th>
                    <th className="py-2 px-4 text-right">Klickprognose</th>
                    <th className="py-2 px-4 text-right">Gesamtbudget</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-axColdgray-100">
                  {Array(3)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={"desktop-loading-" + i} className="text-base">
                        <td className="py-2 px-4">
                          <LoadingBar height="h-6" width="w-36" />
                        </td>
                        <td className="py-2 px-4">
                          <LoadingBar height="h-6" width="w-32" />
                        </td>
                        <td className="py-2 px-4 text-right">
                          <LoadingBar height="h-6" width="w-32" />
                        </td>
                        <td className="py-2 px-4 text-right">
                          <LoadingBar height="h-6" width="w-32" />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              /* Data (Desktop) */
              <table className="min-w-full text-left border-collapse">
                <thead className="text-base font-bold border-b border-axColdgray-100">
                  <tr>
                    <th className="py-2 px-4">Thema</th>
                    <th className="py-2 px-4">Laufzeit</th>
                    <th className="py-2 px-4 text-right">Klickprognose</th>
                    <th className="py-2 px-4 text-right">Gesamtbudget</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-axColdgray-100">
                  {offer?.products?.map((product, i) => (
                    <tr key={product.title + i} className="text-base">
                      {/* Thema */}
                      <td className="py-2 px-4 font-medium">{product.title}</td>

                      {/* Laufzeit */}
                      <td className="py-2 px-4">
                        {product.start && product.end
                          ? moment(product.start).format("DD.MM.YYYY") +
                            " - " +
                            moment(product.end).format("DD.MM.YYYY")
                          : product.start && product.runtime
                            ? product.runtime +
                              " Monate ab " +
                              moment(product.start).format("DD.MM.YYYY")
                            : product.runtime + " Monate"}
                      </td>

                      {/* Klickprognose */}
                      <td className="py-2 px-4 text-right">
                        {decimalFormatter.format(product.clicks_min)} -{" "}
                        {decimalFormatter.format(product.clicks_max)}
                      </td>

                      {/* Gesamtbudget */}
                      <td className="py-2 px-4 text-right">
                        {formatEuroWithoutCents(product.budget + product.setup)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div className="border border-axColdgray-100 p-6 mt-6 text-base max">
        <div className="flex space-x-4 items-center max-lg:text-lg">
          <FaCheckCircle className="h-4 w-4 text-primary flex-shrink-0" />
          <h5 className="font-bold">Service & Kampagnenmanagment</h5>
        </div>
        <ol className="list-disc ml-6 mt-2 opacity-80">
          <li className="sm:whitespace-pre-line pl-2">
            {` Nutzung definierter und optimierter Keywordlisten und Suchwortkombinationen, erprobt unter Berücksichtigung\nvon Branche, Produkt, angestrebter Zielgruppe und Abrufpopularität`}
          </li>
          <li className="pl-2">
            Regionale Eingrenzung und Aussteuerung der Kampagne auf das
            gewünschte Geschäftsgebiet
          </li>
          <li className="pl-2">
            Erstellung von Textanzeigen (2 Textvorschläge pro Thema)
          </li>
          <li className="pl-2">
            Kampagnensetup, -aussteuerung und -management
          </li>
          <li className="pl-2">Monitoring der Kampagnenleistung</li>
          <li className="pl-2">
            Tagesaktuelles und transparentes Online-Reporting
          </li>
        </ol>
      </div>

      <div className="border border-axColdgray-100 p-6 mt-6 text-base ">
        <div className="flex space-x-4 items-center max-lg:text-lg">
          <FaCheckCircle className="h-4 w-4 text-primary flex-shrink-0" />
          <h5 className=" font-bold">
            Merkmale des Pakets für Suchmaschinenmarketing
          </h5>
        </div>
        <ol className="list-disc ml-6 mt-2 opacity-80">
          <li className="pl-2">
            Keywords: Definierte & optimierte Keywords inkl. Regionalisierung
          </li>
          <li className="pl-2">Aussteuerung der Textanzeigen: Google-Suche</li>
        </ol>
      </div>

      <div className="mt-6 border border-axColdgray-100 p-4 text-base">
        <h4 className="font-bold max-lg:text-lg">
          Suchmaschinen-Marketing – Regional
        </h4>
        <ol className=" mt-4 space-y-2 max-lg:divide-y divide-axColdgray-100">
          <li className="grid grid-cols-12 gap-4">
            <p className="col-span-6 lg:col-span-3  ">Geschäftsgebiet</p>
            {isLoading ? (
              <LoadingBar height="h-6" width="w-32" />
            ) : (
              <p className="whitespace-nowrap font-bold ">
                {offer?.customer?.company}
              </p>
            )}
          </li>
          <li className="grid grid-cols-12 gap-4 pt-2">
            <p className="col-span-6 lg:col-span-3 ">Werbeträger</p>
            <p className="whitespace-nowrap font-bold">Google.de</p>
          </li>

          <li className="grid grid-cols-12 gap-4 pt-2">
            <p className="col-span-6 lg:col-span-3 ">Werbemittel</p>
            <p className=" font-normal">
              <b>Google-Textanzeige</b>
            </p>
          </li>
          <li className="grid grid-cols-12 gap-4 pt-2">
            <p className="col-span-6 lg:col-span-3 ">Media Budget</p>
            {isLoading ? (
              <LoadingBar height="h-6" width="w-32" />
            ) : (
              <p className="whitespace-nowrap font-bold">
                {formatEuroWithoutCents(
                  offer?.products?.reduce(
                    (acc, product) => acc + product.budget,
                    0
                  )
                )}
              </p>
            )}
          </li>
          <li className="grid grid-cols-12 gap-4 pt-2">
            <p className="col-span-6 lg:col-span-3">Kampagnen-Management</p>
            {isLoading ? (
              <LoadingBar height="h-6" width="w-32" />
            ) : (
              <p className="whitespace-nowrap font-bold">
                {formatEuroWithoutCents(
                  offer?.products?.reduce(
                    (acc, product) => acc + product.setup,
                    0
                  )
                )}
              </p>
            )}
          </li>
        </ol>
      </div>

      <div className="max-lg:grid grid-cols-12 border border-axColdgray-100 border-t-0 flex justify-between p-4">
        <p className="col-span-6 max-lg:font-bold">Gesamtbetrag</p>
        {isLoading ? (
          <LoadingBar height="h-6" width="w-32" type="headline" />
        ) : (
          <p className="font-bold col-span-3">
            {formatEuroWithoutCents(offer?.price_total ?? 0)}
          </p>
        )}
      </div>
    </div>
  );
};

export default Subjects;

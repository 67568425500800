import React, { useContext } from "react";
import { useCampaignData } from "../hooks/useCampaignData";
import AuthContext from "../contexts/AuthContext";
import WelcomeBox from "../components/WelcomeBox";
import { useBookingData } from "../hooks/useBookingData";
import SearchCampaignsItem from "./seaCampaigns/SearchCampaignsItem";

const SearchCampaigns = () => {
  const { auth } = useContext(AuthContext);

  const { data: allCampaigns, isLoading: allCampaignsLoading } =
    useCampaignData(auth.token, "perPage=1000");
  const { data: bookings, isLoading: isLoadingBookings } = useBookingData(
    auth.token,
    auth.user.customer_id
  );

  const allSearchCampaigns = allCampaigns?.data?.filter(
    (campaign) => campaign.seaOnly === true
  );

  return (
    <>
      <div className="mt-8">
        <WelcomeBox
          title={`Ihre Suchmaschinen Kampagnen`}
          description={`Hier sehen Sie all Ihre Suchmaschinen-Marketing Kampagnen und deren wichtigsten Kennzahlen. Melden Sie sich gerne, falls Sie Fragen haben.`}
        />

        <h1 className="font-bold text-xl mt-8">Ihre Suchmaschinen Kampagnen</h1>
        <SearchCampaignsItem
          campaigns={allSearchCampaigns}
          bookings={bookings?.filter((booking) => booking?.is_sea)}
          isLoadingBookings={isLoadingBookings}
          isLoadingCampaigns={allCampaignsLoading}
        />
      </div>
    </>
  );
};

export default SearchCampaigns;

import axios from "axios";
import { useQuery } from "react-query";

const useServiceAPI = () => {
  const getGeoData = (postcodes, forceServiceApiPopulation) => {
    const postBody = postcodes?.map((postcode) => {
      return {
        key: postcode.postcode,
        country: postcode.country_code ? postcode.country_code : "DE",
      };
    });
    return axios
      .post(
        `${process.env.REACT_APP_SERVICE_API_URL}/geo/area`,
        {
          postcodes: postBody,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SERVICE_API_KEY}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        response.data.data.data = response.data.data.data.map((postcode) => {
          let givenPostcode = postcodes.find(
            (c) => c.postcode === postcode.postcode
          );

          postcode.population =
            forceServiceApiPopulation || !givenPostcode?.population
              ? postcode.population
              : givenPostcode.population;

          postcode.population_online =
            forceServiceApiPopulation || !givenPostcode?.population_online
              ? postcode.population_online
              : givenPostcode.population_online;
          postcode.selected = true;
          return postcode;
        });
        response.data.data.sum = {
          sum_population: response.data.data.data.reduce(
            (acc, cur) => acc + cur.population,
            0
          ),
          sum_online: response.data.data.data.reduce(
            (acc, cur) => acc + cur.population_online,
            0
          ),
        };

        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fillCreativeTemplate = (channel, format, data) => {
    return axios
      .post(
        `${process.env.REACT_APP_SERVICE_API_URL}/creatives/template/${channel}/${format}`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SERVICE_API_KEY}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        response.data.data.data = response.data.data.data.map((postcode) => {
          postcode.selected = true;
          return postcode;
        });
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { getGeoData, fillCreativeTemplate };
};

export const useBannerPreview = (creativeHash) => {
  // useQuery-Hook, der die fetchData-Funktion aufruft
  return useQuery(["bannerPreview", creativeHash], () =>
    getBannerPreview(creativeHash)
  );
};

const getBannerPreview = async (creativeHash) => {
  // Token aus dem lokalen Speicher holen

  try {
    // HTTP GET-Anfrage mit Axios
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVICE_API_URL}/creatives/${creativeHash}/iframe?background=false`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_SERVICE_API_KEY}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error("Fehler beim Abrufen der Kampagnendaten", error);
    throw error; // Fehler werfen, um ihn in der aufrufenden Komponente zu behandeln
  }
};

export default useServiceAPI;

import React, { useContext } from "react";
import { useCampaignData } from "../hooks/useCampaignData";
import { useCustomer } from "../hooks/useCustomerData";
import AuthContext from "../contexts/AuthContext";
import WelcomeBox from "../components/WelcomeBox";
import CustomerBox from "../components/CustomerBox";
import { useBookingData } from "../hooks/useBookingData";
import DashboardBookingItem from "./bookings/bookingComponents/DashboardBookingItem";
import DashboardCampaignItem from "./campaigns/campaignComponents/DashboardCampaignItem";

const Dashboard = () => {
  const { auth } = useContext(AuthContext);

  const { data: allCampaigns, isLoading: allCampaignsLoading } =
    useCampaignData(auth.token, "perPage=1000&status=active,pending");
  const { data: bookings, isLoading: isLoadingBookings } = useBookingData(
    auth.token,
    auth.user.customer_id
  );
  const { data: customer } = useCustomer(auth.token, auth?.user?.customer_id);

  // Filtern der aktiven Kampagnen
  const activeCampaigns = allCampaigns?.data?.filter(
    (campaign) => campaign.status === "active"
  );

  // Filtern der SEA-Kampagnen
  const activeSeaCampaigns = activeCampaigns?.filter(
    (campaign) => campaign.seaOnly === true
  );

  // Filtern der Display-Kampagnen
  const activeDisplayCampaigns = activeCampaigns?.filter(
    (campaign) => campaign.seaOnly !== true
  );
  return (
    <>
      <div className="mt-8">
        <WelcomeBox
          title={`Herzlich Willkommen ${auth?.user.user.first_name} ${auth?.user.user.last_name}!`}
          description={`Hier finden Sie die Übersicht Ihrer aktuellen und vergangenen regionalen Online-Kampagnen.
                        So haben Sie immer alle Informationen zu Ihren Schaltungen im Blick und können tagesaktuell 
                        auf die Ergebnisse Ihrer regionalen Online-Werbung zugreifen. 

                        Für Fragen zu Ihren Kampagnen oder weiteren Möglichkeiten stehen wir Ihnen jederzeit gern zur Verfügung. `}
        />
        {auth?.user.customer_id === 867 ||
        auth?.user.customer_id === 1414 ||
        auth?.user.customer_id === 9106 ||
        auth?.user.customer_id === 1 ||
        auth?.user.customer_id === 9108 ? (
          <></>
        ) : (
          <CustomerBox user={auth?.user} customer={customer} />
        )}

        {bookings?.length > 0 && !isLoadingBookings && (
          <>
            <h1 className="font-bold text-xl mt-8">Kampagnen zur Freigabe</h1>
            <DashboardBookingItem
              bookings={bookings}
              isLoadingBookings={isLoadingBookings}
            />
          </>
        )}

        <h1 className="font-bold text-xl mt-8">
          Ihre aktiven Display Kampagnen
        </h1>
        <DashboardCampaignItem
          campaigns={activeDisplayCampaigns}
          isLoading={allCampaignsLoading}
          seaOnly={false}
        />

        <h1 className="font-bold text-xl mt-8">
          Ihre aktiven Suchmaschinen Kampagnen
        </h1>
        <DashboardCampaignItem
          campaigns={activeSeaCampaigns}
          isLoading={allCampaignsLoading}
          seaOnly={true}
        />
      </div>
    </>
  );
};

export default Dashboard;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../img/stw3_logo.png";

const PasswordForget = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_COREAPI_URL;
  const handleBackToLogin = () => {
    navigate("/login");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/users/password-forgotten`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        navigate("./instruction");
        // Handle success case here
      } else {
        // Password reset request failed
        // Handle error case here
      }
    } catch (error) {
      // Handle error case here
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full max-w-lg">
        <img
          className=" text-center text-2xl font-bold leading-9 mx-auto h-10 w-auto mb-8"
          src={Logo}
          alt="Stellwerk3 GmbH"
        />
        <form onSubmit={handleSubmit} className="px-8 pt-6 pb-8">
          <div className="mb-4">
            <label
              className="block text-sm font-medium leading-6 text-gray-900"
              htmlFor="email"
            >
              Bitte geben sie ihre E-Mail Adresse ein
            </label>
            <input
              className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              id="email"
              type="email"
              placeholder="E-Mail Adresse"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between gap-3.5">
            <button
              id="backToLogin"
              className="flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={handleBackToLogin}
            >
              Zurück zum Login
            </button>
            <button
              id="resetPassword"
              className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-950"
              type="submit"
            >
              Passwort zurücksetzen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordForget;

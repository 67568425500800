import React, { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts";
import useFormatter from "../../../hooks/useFormatter";
import Card from "../../../components/Card";

const DevicePieChart = ({ data, dataKey, title }) => {
  const COLORS = ["#7D4356", "#E882A4"]; // Farben definieren
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    if (data) {
      let sum = data.reduce((acc, current) => acc + current[dataKey], 0);
      if (sum > 0) {
        setHasData(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" fontSize={12}>
        {`${(percent * 100).toFixed(1).replace(/\./g, ",")} %`}
      </text>
    );
  };
  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <text
            x="50%"
            y="5%"
            fill="#6B7280"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: "20px" }}
          >
            {title}
          </text>
          <Pie
            data={data}
            dataKey={dataKey}
            nameKey="device"
            outerRadius={100}
            innerRadius={50}
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.device === "desktop" ? COLORS.at(0) : COLORS.at(1)}
              />
            ))}
          </Pie>
          {!hasData && (
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              style={{ fontSize: "14px", color: "#BEBEBF" }}
            >
              Noch keine Daten verfügbar
            </text>
          )}
          <Tooltip content={<CustomToolTip dataKey={dataKey} />} />
        </PieChart>
      </ResponsiveContainer>
      {hasData && (
        <div className="flex items-center justify-center space-x-8 text-sm ">
          <div className="flex items-center space-x-2">
            <div className="bg-[#E882A4] rounded h-4 w-4"></div>
            <p className="text-gray-500">mobile</p>
          </div>
          <div className="flex items-center space-x-2">
            <div className="bg-[#7D4356] rounded h-4 w-4"></div>
            <p className="text-gray-500">desktop</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DevicePieChart;

const CustomToolTip = ({ dataKey, active, payload }) => {
  const { decimalFormatter } = useFormatter();
  if (active) {
    return (
      <div className="shadow-xl border rounded border-stw3-gray-200">
        <Card>
          <p className="text-stw3-gray-500 text-sm">
            {payload[0].name === "mobile" ? "mobile" : "desktop"}
          </p>
          <p className="font-semibold text-lg mt-2">
            {dataKey === "ctr"
              ? payload[0].value?.toFixed(2) + " %"
              : decimalFormatter.format(payload[0].value)}
          </p>
        </Card>
      </div>
    );
  }
  return null;
};

import { Link } from "react-router-dom";
import CampaignStatusBadge from "../../../components/badges/CampaignStatusBadge";
import Card from "../../../components/Card";
import Spinner from "../../../components/loading/Spinner";
import CampaignList from "./CampaignList";

const DashboardCampaignItem = ({ campaigns, isLoading, seaOnly }) => {
  if (isLoading) {
    return (
      <Card className="mt-4 flex items-center px-6 py-6 bg-white animate-pulse">
        <div className="flex w-1/3 items-center">
          <div className="h-12 w-12 rounded-full bg-slate-200 animate-pulse flex-shrink-0 mr-3"></div>
          <div className="flex-col items-center justify-center">
            <div className="h-4 w-24 leading-6 rounded-lg bg-slate-200 mb-3" />
            <div className="mt-1 h-3 w-32 leading-5 rounded-lg bg-slate-200" />
          </div>
        </div>
        <div className="flex w-1/3 items-center justify-center">
          <Spinner />
          <p className="text-slate-700 text-sm ml-2">
            Prüfe auf bereits bestehende Kampagnen...
          </p>
        </div>
        <div className="flex w-1/3 justify-end">
          <CampaignStatusBadge status={"active"} />
        </div>
      </Card>
    );
  }

  return (
    <>
      {campaigns?.length > 0 ? (
        <div className="mt-4">
          <CampaignList
            campaigns={campaigns}
            isLoading={isLoading}
            seaOnly={seaOnly}
          />
        </div>
      ) : (
        <Card className="mt-4 flex justify-center items-center px-6 py-9 bg-white">
          <div>
            {seaOnly === true ? (
              <p className="text-sm leading-6 text-gray-400">
                Sie haben derzeit keine aktiven SEA Kampagnen.
              </p>
            ) : (
              <p className="text-sm leading-6 text-gray-400">
                Sie haben derzeit keine aktiven Display Kampagnen.
              </p>
            )}
          </div>
        </Card>
      )}
      {seaOnly === true ? (
        <div className="text-right">
          <Link to="/search" className="text-primary mt-4 block text-sm">
            Alle Kampagnen anzeigen
          </Link>
        </div>
      ) : (
        <div className="text-right">
          <Link to="/display" className="text-primary mt-4 block text-sm">
            Alle Kampagnen anzeigen
          </Link>
        </div>
      )}
    </>
  );
};

export default DashboardCampaignItem;

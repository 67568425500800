import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Select from "../select/Select";

const NavTabs = ({ tabs }) => {
  const { search } = useLocation();
  const [mobileSelectOptions, setMobileSelectOptions] = useState([]);
  const [defaultMobileSelect, setDefaultMobileSelect] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (tabs?.length > 0) {
      setMobileSelectOptions(
        tabs.map((tab) => ({ label: tab.name, value: tab.href }))
      );
      let defaultSelect = tabs.filter((tab) =>
        location.pathname?.includes(tab.href)
      );
      if (defaultSelect.length > 0) {
        setDefaultMobileSelect({
          label: defaultSelect.at(-1).name,
          value: defaultSelect.at(-1).href,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMobileSelect = (href) => {
    navigate(href);
  };

  return (
    <>
      <div className="hidden md:block pb-3">
        <div className="border-b border-gray-500 ">
          <nav className="-mb-px flex space-x-14" aria-label="Tabs">
            {tabs?.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href + search}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "border-b-2 border-b-primary  text-primary  font-bold"
                      : "text-gray-500 hover:border-gray-500 hover:text-gray-500 group  border-b-2 border-transparent "
                  } py-4  inline-flex items-center w-full max-w-max relative`
                }
                end
              >
                {tab.icon && (
                  <tab.icon
                    className={`${
                      tab.name === "Reichweite"
                        ? "-ml-0.5 mr-2 h-7 w-7"
                        : "-ml-0.5 mr-2 h-5 w-5"
                    }`}
                    aria-hidden="true"
                  />
                )}

                <span>{tab.name}</span>
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div className="md:hidden">
        <Select
          options={mobileSelectOptions}
          onSelect={handleMobileSelect}
          defaultSelect={defaultMobileSelect}
        />
      </div>
    </>
  );
};

export default NavTabs;

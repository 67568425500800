import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { useOffer } from "../../hooks/useOfferData";
import moment from "moment";
import useFormatter from "../../hooks/useFormatter";
import { FaCheckCircle } from "react-icons/fa";
import LoadingBar from "../../components/loading/LoadingBar";
import { Disclosure } from "@headlessui/react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const SubjectsDisplay = () => {
  const { auth } = useContext(AuthContext);

  const { hash } = useParams();
  const { data: offer, isLoading } = useOffer(hash, auth.token);
  const { decimalFormatter, formatEuroWithoutCents } = useFormatter();
  const [campaignSum, setCampaignSum] = useState();
  const [impressionSum, setImpressionSum] = useState();
  const [isOpen, setIsOpen] = useState(offer?.status === "accepted");

  useEffect(() => {
    if (offer?.products?.length > 0) {
      let sum = 0;
      offer.products.forEach((product) => {
        sum += product.grouped_campaigns_count;
      });
      setCampaignSum(sum);
    }
  }, [offer]);

  useEffect(() => {
    if (offer?.products?.length > 0) {
      let sum = 0;
      offer.products.forEach((product) => {
        sum += product.impressions_campaign * product.grouped_campaigns_count;
      });
      setImpressionSum(sum);
    }
  }, [offer]);

  const getAgeRange = (ageFrom, ageTo) => {
    if (ageFrom !== null && ageTo !== null) {
      return `Alter von ${ageFrom} bis ${ageTo} Jahre`;
    } else if (ageFrom !== null) {
      return `Alter ab ${ageFrom} Jahren`;
    } else if (ageTo !== null) {
      return `Alter bis ${ageTo} Jahre`;
    } else {
      return "Keine Altersbeschränkung";
    }
  };

  const getFirstSum = (placements) => {
    let sum = 0;
    placements.forEach((placement) => {
      if (
        placement.title === "Billboard" ||
        placement.title === "Mobile Medium-Rectangle"
      ) {
        sum += placement.campaign_target_value;
      }
    });
    return sum;
  };

  const getSecondSum = (placements) => {
    let sum = 0;
    placements.forEach((placement) => {
      if (
        placement.title !== "Billboard" &&
        placement.title !== "Mobile Medium-Rectangle"
      ) {
        sum += placement.campaign_target_value;
      }
    });
    return sum;
  };

  function roundToThousands(value) {
    return Math.round(value / 1000) * 1000;
  }

  const getProductionSum = () => {
    let sum = 0;
    offer?.production_costs?.forEach((cost) => {
      sum += cost.price * cost.count;
    });
    return sum;
  };

  return (
    <div className="text-[#1E2938]">
      <h2 className="font-bold text-xl">Angebotsübersicht</h2>
      <div className="border border-axColdgray-100 mt-6">
        <div className="border-b border-axColdgray-100 p-4 font-bold text-lg">
          {isLoading ? (
            <LoadingBar type="headline" width="w-64" height="h-8" />
          ) : (
            <h3>{offer?.title}</h3>
          )}
        </div>
        <div className="px-5 pt-5">
          <h5 className="text-base font-bold">Premium Local Audience</h5>
          <ol className="list-disc ml-6 mt-2 opacity-80">
            <li className="sm:whitespace-pre-line pl-2">
              Regionaltargeting auf Basis von Profildaten
            </li>
            <li className="pl-2">
              Regionale Schärfe des Selektionsverfahrens: fünfte PLZ-Stelle
            </li>
            <li className="pl-2">
              Platzierung: AGMA-geprüftes Werbe-Umfeld mit
              Premium-Platzierungsmix
            </li>
          </ol>
        </div>
        <ol className="p-5 space-y-4 lg:w-2/5 max-lg:divide-y divide-axColdgray-100">
          <li className="flex lg:justify-evenly justify-between gap-4">
            <p className="lg:flex-1  ">Buchungsinhalt</p>
            {isLoading ? (
              <LoadingBar height="h-5" width="w-32" className="lg:flex-1" />
            ) : (
              <p className="whitespace-nowrap font-bold lg:flex-1 ">
                {campaignSum === 1 ? offer?.title : `${campaignSum} Kampagnen`}
              </p>
            )}
          </li>
          <li className="flex lg:justify-evenly justify-between gap-4 max-lg:pt-4">
            <p className="lg:flex-1">Sichtkontakte gesamt</p>
            <p className="whitespace-nowrap font-bold lg:flex-1">
              {decimalFormatter.format(impressionSum)}
            </p>
          </li>
        </ol>
      </div>
      {offer?.production_costs?.length > 0 && (
        <div className="border border-axColdgray-100 p-5 mt-6">
          <div className="flex space-x-4 items-center">
            <FaCheckCircle className="h-4 w-4 text-primary flex-shrink-0" />
            <h5 className="text-base font-bold">Werbemittelerstellung</h5>
          </div>
          <ol className="list-disc ml-6 mt-2 text-base opacity-80">
            {offer?.production_costs?.map((cost, index) => (
              <li className="sm:whitespace-pre-line pl-2">
                {`Produktion von ${cost?.count} ${
                  cost?.count === 1 ? "Set" : "Sets"
                } für ${cost?.count !== 1 ? "jeweils" : ""} ${
                  cost?.price
                },- EUR mit ${
                  cost?.count !== 1 ? "je" : ""
                } 6 HTML5-Werbemittlen`}
              </li>
            ))}
            <li className="pl-2">
              inklusive 2 Korrekturläufen (jeder weitere Korrekturlauf 140,- EUR
              pro Format)
            </li>
          </ol>
        </div>
      )}
      <div className="border border-axColdgray-100 text-base p-5 mt-6">
        <div className="flex space-x-4 items-center">
          <FaCheckCircle className="h-4 w-4 text-primary flex-shrink-0" />
          <h5 className="font-bold">Kampagnen- und Ad-Server-Management</h5>
        </div>
        <ol className="list-disc ml-6 mt-2  opacity-80">
          <li className="sm:whitespace-pre-line pl-2">
            Präzise Aussteuerung und Auswertung der Kampagne über den AdServer
          </li>
          <li className="pl-2">
            Auf Ihr Kampagnenziel abgestimmter Platzierungs- und Format-Mix
          </li>
          <li className="pl-2">
            Kontinuierliches Monitoring und Optimierung der
            Kampagnen-Performance
          </li>
          <li className="pl-2">
            Tagesaktuelles, transparentes und aussagekräftiges Online-Reporting
          </li>
        </ol>
      </div>
      <div className="border-b border-axColdgray-100 py-6 lg:py-12 w-full left-0 right-0 absolute" />
      <Disclosure
        as="div"
        className="lg:mt-48 mt-24 text-base"
        defaultOpen={offer?.status === "accepted"}
      >
        {({ open }) => {
          setIsOpen(open);
          let counter = 1;
          return (
            <>
              <Disclosure.Button
                className={`p-4 text-xl font-bold flex items-center justify-between space-x-4 border ${
                  open ? "border-b-0" : ""
                } border-axColdgray-100 w-full`}
              >
                <div className="flex items-center space-x-4">
                  <span>Kampagnenübersicht</span>
                  <>
                    {open ? (
                      <BiChevronUp className="h-6 w-6" />
                    ) : (
                      <BiChevronDown className="h-6 w-6" />
                    )}
                  </>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="space-y-5 text-base">
                {offer?.products?.map((product, index) => {
                  const currentCounter = counter;
                  counter += product.grouped_campaigns_count;
                  return (
                    <div
                      key={index}
                      className="border border-axColdgray-100 p-4"
                    >
                      <div>
                        {/* Kampagnen-Überschrift */}
                        <h5 className="font-bold pb-2">
                          {product.grouped_campaigns_count === 1
                            ? "Kampagne " + currentCounter
                            : "Kampagne " +
                              currentCounter +
                              " bis " +
                              (currentCounter +
                                product.grouped_campaigns_count -
                                1)}
                        </h5>
                        {product.grouped_campaigns_count > 1 && (
                          <p className="text-sm text-gray-400 -mt-2">
                            je Kampagne
                          </p>
                        )}

                        {/* Allgemeine Produktinfos */}
                        <div className=" max-lg:space-y-4 my-4 text-base max-lg:divide-y divide-axColdgray-100">
                          <div className="flex justify-between lg:grid grid-cols-10 gap-4 ">
                            <p className="lg:col-span-3">Laufzeit</p>
                            <p className="col-span-7 font-bold">
                              {product.start !== null
                                ? moment(product.start).format("DD.MM.YYYY") +
                                  " - " +
                                  moment(product.end).format("DD.MM.YYYY")
                                : product.runtime + " Tage"}
                            </p>
                          </div>
                          <div className="flex justify-between lg:grid grid-cols-10 gap-4 pt-4">
                            <p className="col-span-3">Zielgruppenselektion</p>
                            <p className="col-span-7 font-bold">
                              {getAgeRange(product.age_from, product.age_to)}
                            </p>
                          </div>
                          <div className="flex lg:grid grid-cols-10 gap-4 pt-4">
                            <p className="flex-1 lg:col-span-3">
                              Kosten zzgl. Werbemittelerstellung*
                            </p>
                            <p className="flex-1 lg:col-span-7 font-bold items-center max-lg:flex justify-end">
                              {formatEuroWithoutCents(
                                product.price_wo_creative
                              )}
                            </p>
                          </div>
                        </div>
                        <hr className="border-t border-axColdgray-100 my-6" />

                        {/* Tabelle mit erster Spalte nur ab md sichtbar */}
                        <div className="overflow-x-auto w-full">
                          <div className="lg:hidden">
                            <p className="font-bold">Werbeträger</p>
                            <p className="py-2">Premium Local Audience</p>
                          </div>
                          <table className="min-w-full w-full text-left border-collapse ">
                            <thead className=" font-bold">
                              <tr>
                                {/* Hide first column below md */}
                                <th className="py-2 lg:w-4/10 hidden lg:table-cell">
                                  Werbeträger
                                </th>
                                <th className="py-2  lg:w-2/10">Werbemittel</th>
                                <th className="py-2  lg:w-2/10">Format (px)</th>
                                <th className="py-2  lg:w-2/10 text-right">
                                  Sichtkontakte
                                </th>
                              </tr>
                            </thead>
                            <tbody className="lg:text-base">
                              <tr>
                                <td className="py-2 hidden lg:table-cell">
                                  Premium Local Audience
                                </td>
                                <td className="py-2 ">Billboard</td>
                                <td className="py-2 ">970x250</td>
                                <td className="py-2  text-right">
                                  {decimalFormatter.format(
                                    roundToThousands(
                                      getFirstSum(product.placements)
                                    )
                                  )}
                                </td>
                              </tr>

                              <tr className="border-b border-axColdgray-100">
                                <td className="py-2 pb-6  hidden lg:table-cell"></td>
                                <td className="py-2 pb-6 ">
                                  Mobile Medium Rectangle
                                </td>
                                <td className="py-2 pb-6 ">300x250</td>
                                <td className="py-2 pb-6  text-right"></td>
                              </tr>
                              <tr>
                                <td className="py-2 pt-6 hidden lg:table-cell">
                                  Premium Local Audience
                                </td>
                                <td className="py-2 pt-6">Medium Rectangle</td>
                                <td className="py-2 pt-6">300x250</td>
                                <td className="py-2 pt-6 text-right">
                                  {decimalFormatter.format(
                                    roundToThousands(
                                      getSecondSum(product.placements)
                                    )
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="py-2  hidden lg:table-cell"></td>
                                <td className="py-2 ">Skyscaper</td>
                                <td className="py-2 ">160x600</td>
                                <td className="py-2  text-right"></td>
                              </tr>
                              <tr>
                                <td className="py-2  hidden lg:table-cell"></td>
                                <td className="py-2 ">Superbanner</td>
                                <td className="py-2 ">728x90</td>
                                <td className="py-2  text-right"></td>
                              </tr>
                              <tr>
                                <td className="py-2  hidden lg:table-cell"></td>
                                <td className="py-2 ">Mobile Ad</td>
                                <td className="py-2 ">320x50</td>
                                <td className="py-2  text-right"></td>
                              </tr>
                              <tr>
                                <td className="py-2 pb-6  hidden lg:table-cell"></td>
                                <td className="py-2 pb-6">Mobile Ad</td>
                                <td className="py-2 pb-6">300x50</td>
                                <td className="py-2 pb-6 text-right"></td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr className="font-bold border-t border-axColdgray-100 lg:text-base">
                                <td className=" py-2 pt-6" colspan="2">
                                  Sichtkontakte Gesamt
                                </td>
                                <td className="py-2 pt-6 hidden md:table-cell"></td>
                                <td className="py-2 pt-6 text-right">
                                  {decimalFormatter.format(
                                    product.impressions_campaign
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Disclosure.Panel>
            </>
          );
        }}
      </Disclosure>
      {isOpen && offer?.discount !== 0 && (
        <p className="my-2 text-gray-400 italic">{`*Zusätzlich erhalten Sie ${decimalFormatter.format(
          offer?.discount_percent
        )} % Volumenrabatt (s. Kostenübersicht)`}</p>
      )}
      <div className="border-b border-axColdgray-100 py-6 lg:py-12 w-full left-0 right-0 absolute" />
      <div
        className={`${
          offer?.discount === 0 ? "lg:mt-48 mt-24" : "lg:mt-56 mt-28"
        } lg:text-base`}
      >
        <h2 className="font-bold text-xl">Leistungsaufstellung</h2>
        <div className="border border-axColdgray-100 mt-6">
          <div className="border-b border-axColdgray-100 p-4 font-bold text-lg">
            <h3>Kostenübersicht</h3>
          </div>
          <ol className="p-4 space-y-4 border-b border-axColdgray-100">
            <li className="flex justify-between lg:grid grid-cols-2 gap-4">
              <p className="">Kampagnenkosten</p>
              <p className="whitespace-nowrap text-right items-center max-lg:flex ">
                {formatEuroWithoutCents(
                  offer?.price_total + offer?.discount - offer?.production_sum
                )}
              </p>
            </li>
            {offer?.discount !== 0 && (
              <li className="lg:grid grid-cols-2 gap-4 flex justify-between">
                <p className="">
                  Volumenrabatt (
                  {offer?.discount !== 0
                    ? decimalFormatter.format(offer?.discount_percent) + " %"
                    : null}
                  )
                </p>

                <p className="whitespace-nowrap text-right max-lg:flex items-center">
                  - {formatEuroWithoutCents(offer?.discount)}
                </p>
              </li>
            )}
          </ol>

          {offer?.discount !== 0 && (
            <div className="p-6 lg:grid grid-cols-2 gap-4 border-b border-axColdgray-100 flex justify-between">
              <p className="italic">Zwischensumme</p>
              <p className="whitespace-nowrap text-right italic max-lg:font-semibold ">
                {formatEuroWithoutCents(
                  offer?.price_total - offer?.production_sum
                )}
              </p>
            </div>
          )}
          {offer?.production_costs?.length > 0 && (
            <div className="p-6 lg:grid grid-cols-2 gap-4 border-b border-axColdgray-100 flex justify-between">
              <p>Kosten der Werbemittelerstellung</p>
              <p className="whitespace-nowrap text-right  max-lg:flex items-center">
                {decimalFormatter.format(getProductionSum())} €
              </p>
            </div>
          )}
          <div className="p-6 lg:grid grid-cols-2 gap-4 font-bold flex justify-between">
            <p>Gesamtbetrag</p>
            <p className="whitespace-nowrap text-right">
              {formatEuroWithoutCents(offer?.price_total)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectsDisplay;

// src/context/PlausibleProvider.js

import React, { createContext, useContext, useEffect, useState } from "react";
import Plausible from "plausible-tracker";

// Erstelle einen Kontext für den Plausible-Tracker
const PlausibleContext = createContext(null);

// PlausibleProvider-Komponente
export const PlausibleProvider = ({ children }) => {
  const [plausible, setPlausible] = useState(null);

  useEffect(() => {
    // Plausible Tracker initialisieren
    const plausibleInstance = Plausible({
      domain: "app.stellwerk3.de",
    });
    setPlausible(plausibleInstance);

    plausibleInstance.trackPageview();
  }, []);

  return (
    <PlausibleContext.Provider value={plausible}>
      {children}
    </PlausibleContext.Provider>
  );
};

// Custom Hook, um den Plausible-Tracker zu verwenden
export const usePlausible = () => {
  return useContext(PlausibleContext);
};

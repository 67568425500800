// Table.js

import React from "react";
import { useTable, useSortBy } from "react-table";
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";

export default function Table({
  columns,
  data,
  cardPadding,
  isLoading,
  initialSortBy,
}) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    footerGroups,
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          initialSortBy ?? {
            id: "id",
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  return (
    <>
      <table className={`min-w-full  divide-gray-400 `} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="border-b border-stw3-gray-200"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    title: "",
                  })}
                  scope="col"
                  className={`py-3.5 font-normal  text-gray-500  ${
                    cardPadding ? "px-5" : "px-3"
                  }`}
                >
                  <span
                    className={`flex items-center text-sm ${
                      column.align === "right"
                        ? "justify-end"
                        : column.align === "center"
                        ? "justify-center"
                        : ""
                    } `}
                  >
                    {column.render("Header")}
                    <span className="ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronDownIcon className="h-4 w-4 " />
                        ) : (
                          <ChevronUpIcon className="h-4 w-4" />
                        )
                      ) : (
                        <ChevronUpDownIcon className="h-4 w-4 " />
                      )}
                    </span>
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y" {...getTableBodyProps()}>
          {isLoading &&
            Array(10)
              .fill(0)
              .map((_, i) => (
                <tr className="odd:bg-stw3-gray-100 border-stw3-gray-200">
                  {columns.map((column) => (
                    <td
                      className={` py-2.5  text-sm font-sm    ${
                        cardPadding ? "px-5" : "px-3"
                      }`}
                    >
                      <div
                        className={`flex ${
                          column.align === "right"
                            ? "justify-end"
                            : column.align === "center"
                            ? "justify-center"
                            : ""
                        }`}
                      >
                        <p className="h-5 w-24 rounded bg-stw3-gray-400 animate-pulse  "></p>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
          {!isLoading &&
            rows.length > 0 &&
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="odd:bg-stw3-gray-100 border-stw3-gray-200  ">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={`whitespace-nowrap py-2.5  text-sm text-gray-900  ${
                          cell.column.align === "right"
                            ? "text-right"
                            : cell.column.align === "center"
                            ? "text-center"
                            : ""
                        }  ${cardPadding ? "px-5" : "px-3"}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>

        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td
                  {...column.getFooterProps()}
                  className={`pt-4 border-t border-stw3-gray-200 text-sm  ${
                    cardPadding ? "px-5" : "px-3"
                  }
                ${
                  column.align === "right"
                    ? "text-right"
                    : column.align === "center"
                    ? "text-center"
                    : ""
                }
                `}
                >
                  {isLoading ? (
                    <div
                      className={`flex  ${
                        column.align === "right"
                          ? "justify-end"
                          : column.align === "center"
                          ? "justify-center"
                          : ""
                      }`}
                    >
                      {" "}
                      <p className="h-5 w-24 rounded bg-stw3-gray-200 animate-pulse  "></p>
                    </div>
                  ) : (
                    column.render("Footer")
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>{" "}
      {!isLoading && rows.length === 0 && (
        <div className="flex items-center justify-center text-center w-full h-full p-32">
          <p className="text-stw3-gray-500 font-medium">
            Zurzeit sind keine Daten verfügbar.
          </p>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useRef } from "react";

const CheckBox = ({ onChange, checked, required }) => {
  const checkBoxRef = useRef(null);

  useEffect(() => {
    const checkbox = checkBoxRef.current;
    if (checkbox) {
      const validateCheckbox = () => {
        if (checkbox.checked) {
          // Wenn die Checkbox angehakt ist, ist sie gültig
          checkbox.setCustomValidity("");
        } else {
          // Wenn die Checkbox nicht angehakt ist, setze die Fehlermeldung
          checkbox.setCustomValidity(
            "Klicken Sie dieses Kästchen an, wenn Sie fortfahren möchten."
          );
        }
      };

      validateCheckbox();

      checkbox.addEventListener("change", validateCheckbox);

      return () => {
        checkbox.removeEventListener("change", validateCheckbox);
      };
    }
  }, []);

  return (
    <input
      type="checkbox"
      required={required}
      ref={checkBoxRef}
      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
    />
  );
};

export default CheckBox;

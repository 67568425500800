import React, { useMemo } from "react";
import useFormatter from "../../../hooks/useFormatter";
import SummationFooter from "../../../components/tables/SummationFooter";

const useLocationColumns = () => {
  const { decimalFormatter } = useFormatter();

  const columns = useMemo(
    () => [
      {
        Header: "Postleitzahl",
        accessor: "postcode",
        Cell: ({ row, value }) => value,
        Footer: () => <p className="font-semibold">Gesamt</p>,
      },

      {
        Header: "Orte",
        accessor: "name",
        Footer: (info) => (
          <p className="font-semibold">
            {decimalFormatter.format(info.rows.length)}
          </p>
        ),
      },
      {
        Header: "Einwohner",
        accessor: "population",
        align: "right",
        Cell: ({ value }) => decimalFormatter.format(value),
        Footer: ({ rows }) => <SummationFooter rows={rows} kpi="population" />,
      },
      /*  {
        Header: "Einwohner 18-80",
        accessor: "population_age_range",
        align: "right",
        Cell: ({ value }) => decimalFormatter.format(value),
        Footer: ({ rows }) => (
          <SummationFooter rows={rows} kpi="population_age_range" />
        ),
      }, */
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { columns };
};

export default useLocationColumns;

import React, { useMemo } from "react";
import useFormatter from "../../../hooks/useFormatter";
import Badge from "../../../components/badges/Badge";

const useSitelinkTableColumns = (sum) => {
  const { decimalFormatter } = useFormatter();

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "text",
        Cell: ({ value, row }) => (
          <div
            className={`${
              row.original.Status !== "ENABLED" && " text-gray-500"
            } font-semibold flex space-x-4`}
          >
            {row.original.url ? (
              <a
                target="_blank"
                href={row.original.url}
                className="hover:underline"
                rel="noreferrer"
              >
                {value}
              </a>
            ) : (
              value
            )}
            {/*             {row.original.Status !== "ENABLED" && (
              <Badge label="Inaktiv" color="yellow" />
            )} */}
          </div>
        ),
        Footer: () => {
          return <p className="font-semibold">Summe</p>;
        },
      },
      {
        Header: "Klicks",
        accessor: "clicks",
        align: "right",
        Cell: ({ value, row }) => (
          <p
            className={`${
              row.original.Status !== "ENABLED" && " text-gray-500"
            } `}
          >
            {value ? decimalFormatter.format(value) : "-"}
          </p>
        ),
        Footer: () => {
          return (
            <p className="font-semibold">
              {sum?.clicks ? decimalFormatter.format(sum.clicks) : "-"}
            </p>
          );
        },
      },
      {
        Header: "Sichtkontakte",
        accessor: "impressions",
        align: "right",
        Cell: ({ value, row }) => (
          <p
            className={`${
              row.original.Status !== "ENABLED" && " text-gray-500"
            } `}
          >
            {value ? decimalFormatter.format(value) : "-"}
          </p>
        ),
        Footer: () => {
          return (
            <p className="font-semibold">
              {sum?.clicks ? decimalFormatter.format(sum.impressions) : "-"}
            </p>
          );
        },
      },
      /* {
        Header: "Klickrate",
        align: "right",
        accessor: "ctr",
        Cell: ({ value, row }) => (
          <p
            className={`${
              row.original.Status !== "ENABLED" && " text-gray-500"
            } `}
          >
            {value
              ? parseFloat(value)?.toFixed(2).replace(/\./g, ",") + " %"
              : "-"}
          </p>
        ),
        Footer: () => {
          return (
            <p className="font-semibold">
              {sum?.ctr
                ? parseFloat(sum.ctr)?.toFixed(2).replace(/\./g, ",") + " %"
                : "-"}
            </p>
          );
        },
      }, */
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sum]
  );

  return { columns };
};

export default useSitelinkTableColumns;

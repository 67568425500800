import React from "react";
import { ImSpinner2 } from "react-icons/im";

const Button = ({
  label,
  iconBeforeLabel,
  iconAfterLabel,
  onClick,
  disabled,
  type,
  variant,
  isLoading,
}) => {
  const getButtonStyleByVariant = () => {
    switch (variant) {
      case "secondary":
        return "text-[#6C7585] border-[1.5px] border-[#6C7585] font-semibold px-6 ";
      case "text":
        return "text-semibold text-[#B1B7C3]";
      default:
        return "text-white bg-[#5A1B2E] font-bold px-6 ";
    }
  };

  return (
    <button
      type={type ?? "button"}
      className={`flex w-full justify-center rounded-md items-center focus:underline h-[35px] min-w-[135px] max-w-min whitespace-nowrap leading-[150%] text-sm  ${getButtonStyleByVariant()} ${
        disabled ? "opacity-80" : "hover:underline"
      }`}
      onClick={onClick && onClick}
      disabled={disabled || isLoading}
    >
      {iconBeforeLabel &&
        React.cloneElement(iconBeforeLabel, {
          className: "h-5 w-auto flex-shrink-0 mr-2",
        })}
      {isLoading && (
        <ImSpinner2 className={`text-white h-6 w-6  animate-spin`} />
      )}
      {!isLoading && label}
      {iconAfterLabel &&
        React.cloneElement(iconAfterLabel, { className: "h-5 w-auto ml-2" })}
    </button>
  );
};

export default Button;

import moment from "moment";
import React, { useState } from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ReferenceArea,
} from "recharts";
import Card from "../../../components/Card";
import useFormatter from "../../../hooks/useFormatter";
import useResponsiveCharts from "../../../hooks/useResponsiveCharts";
import useScreenSize from "../../../hooks/useScreenSize";

const LineChart = ({ data }) => {
  const dateThreeDaysAgo = data?.at(-3)?.date;
  const { decimalFormatter } = useFormatter();
  //const [animationEnd, setAnimationEnd] = useState(false);

  const { intervalCount } = useResponsiveCharts(data?.length);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <style>{`
      .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
      .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
            stroke-opacity: 0;
      }
      `}</style>
      <ComposedChart data={data}>
        <CartesianGrid stroke="#C6C6C6" y={999999999} />
        <XAxis
          interval={intervalCount}
          tickCount={1}
          tickLine={false}
          dataKey="date"
          tick={<CustomXAxisTick data={data} />}
        />
        <YAxis
          tickCount={4}
          dx={-10}
          domain={[0, "auto"]}
          stroke="#6B7280"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => decimalFormatter.format(value)}
        />
        <Tooltip content={<CustomToolTip />} />
        <Bar barSize={18} />
        {/* background={{ fill: "#E4E8EF" }} */}
        <defs>
          <filter id="shadow" height="130%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
            <feOffset dx="2" dy="2" result="offsetblur" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.2" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <Line
          // onAnimationEnd={() => setAnimationEnd(false)}
          isAnimationActive={false}
          type="monotone"
          dataKey="reach"
          stroke="#671B34"
          strokeWidth={4}
          dot={(props) => (
            <circle
              cx={props.cx}
              cy={props.cy}
              r={6}
              fill="#671B34"
              stroke="none"
            />
          )}
          style={{ filter: "url(#shadow)" }} // Wende den Schattenfilter an
        />
        {data?.length > 4 && (
          <ReferenceArea
            x1={dateThreeDaysAgo}
            x2={data[data.length - 1].date}
            y1={data[data.length - 3].reach * 0.3}
            y2={data[data.length - 1].reach * 1.3}
            fill={"green"}
            fillOpacity={0.15}
            ifOverflow="extendDomain"
            isFront={true}
            shape={
              <rect
                x={dateThreeDaysAgo}
                y={data[data.length - 3].reach * 0.3}
                width={data[data.length - 1].Datum - dateThreeDaysAgo}
                height={
                  (data[data.length - 3].reach * 0.3 -
                    data[data.length - 1].reach * 1.3) *
                  -1
                }
                rx={10}
                fill="green"
              />
            }
            label={
              <ReferenceAreaLabel
                show={true}
                reach={decimalFormatter.format(
                  data?.at(-1).reach - data?.at(-3).reach
                )}
              />
            }
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default LineChart;

const ReferenceAreaLabel = ({ viewBox, reach, show }) => {
  const { isWindowSmallerThan } = useScreenSize();
  if (!show) return <></>;
  return (
    <g className="block">
      <foreignObject
        x={
          isWindowSmallerThan("sm")
            ? viewBox.x
            : isWindowSmallerThan("md")
            ? viewBox.x + viewBox.width / 5
            : viewBox.x + viewBox.width / 3
        }
        y={viewBox.y + viewBox.height / 2}
        width={100}
        height={100}
      >
        <div>
          <p className="font-bold text-xl text-green-800">{` + ${reach}`}</p>
          <p className="text-green-700 font-light">Personen</p>
        </div>
      </foreignObject>
    </g>
  );
};

export const CustomToolTip = ({ payload }) => {
  const { decimalFormatter } = useFormatter();
  return (
    <div className="shadow-xl border rounded-lg border-stw3-gray-200">
      <Card>
        <p className="text-gray-500 text-sm">
          {moment(
            payload[0]?.payload?.date ?? payload[0]?.payload?.date
          ).format("dd DD.MM.YYYY")}
        </p>
        <p className="font-semibold text-lg mt-2">
          {decimalFormatter.format(
            payload[0]?.payload?.reach ?? payload[0]?.payload?.reach
          )}
        </p>
      </Card>
    </div>
  );
};

export const CustomXAxisTick = (props) => {
  const { x, y, payload } = props;
  const dateParts = moment(payload.value).format("dd. DD.MM.YY").split(" ");

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        textAnchor="middle"
        fill="#6B7280"
        fontSize={props.data.length < 10 ? 12 : 10}
        fontWeight={400}
      >
        {dateParts.map((part, index) => (
          <tspan x={0} dy={index === 1 ? 15 : 10} key={index}>
            {part}
          </tspan>
        ))}
      </text>
    </g>
  );
};

import React from "react";
import Spinner from "../../../components/loading/Spinner";

// MotiveImageCard component
// Input: isLoading, motiveUrl optional: motiveName
const MotiveImageCard = ({ isLoading, motiveUrl }) => {
  return (
    <div
      className="bg-cover bg-white bg-center  sm:rounded relative h-full w-full"
      alt="placeholder"
      style={
        isLoading
          ? {}
          : {
              backgroundImage: `url(${motiveUrl})`,
            }
      }
    >
      {/* <div className="text-white absolute inset-4 text-sm font-medium">
        {motiveName}
      </div> */}
      {isLoading && (
        <div className="flex items-center justify-center h-full">
          <Spinner />{" "}
        </div>
      )}
    </div>
  );
};

export default MotiveImageCard;

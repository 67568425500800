import React, { useContext } from "react";
import OfferList from "./OfferList";
import AuthContext from "../../contexts/AuthContext";
import { useOffers } from "../../hooks/useOfferData";
const Offer = () => {
  const { auth } = useContext(AuthContext);
  const { data, isLoading } = useOffers(auth.token, auth.user.customer_id);

  return (
    <OfferList
      offers={data}
      isLoading={isLoading}
      customerName={auth?.user?.customer?.company}
    />
  );
};

export default Offer;

import React, { useContext } from "react";
import { useBooking } from "../../hooks/useBookings";
import AuthContext from "../../contexts/AuthContext";
import Spinner from "../../components/loading/Spinner";
import { useParams } from "react-router-dom";
import Booking from "./Index";
import PLABooking from "./IndexPLA";
import LoadingErrorMessage from "./components/LoadingErrorMessage";

const BookingOverview = () => {
  const { hash } = useParams();
  const { auth } = useContext(AuthContext);
  const { data: booking, isLoading, isError } = useBooking(auth?.token, hash);
  if (isLoading)
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <Spinner />
      </div>
    );
  if (isError) {
    return <LoadingErrorMessage />;
  }
  if (booking?.is_sea) {
    return <Booking />;
  }

  return <PLABooking />;
};

export default BookingOverview;

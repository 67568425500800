import React, { useContext } from "react";
import PenEditIcon from "../../../components/icons/PenEditIcon";
import AuthContext from "../../../contexts/AuthContext";

const CalloutPreview = ({ callouts, onEdit, status }) => {
  const { auth } = useContext(AuthContext);
  return (
    <button
      disabled={!auth?.isAuthenticated || status !== "in_coordination"}
      onClick={onEdit ? () => onEdit() : null}
      className={`text-[#434343] w-full rounded-lg`}
    >
      <ol
        className={`relative group flex items-center gap-x-2 flex-wrap px-3  border border-transparent rounded-lg ${
          onEdit && auth?.isAuthenticated && status === "in_coordination"
            ? "hover:border-primary"
            : ""
        }`}
      >
        {callouts.map((callout, index) => (
          <li key={"callout" + index} className="text-sm py-1 ">
            <div className="flex items-center space-x-2">
              <p>{callout.value}</p>
              {index < callouts?.length - 1 && (
                <div className="h-1 w-1 bg-gray-500 rounded-full "></div>
              )}
            </div>
          </li>
        ))}

        {onEdit && auth?.isAuthenticated && status === "in_coordination" && (
          <PenEditIcon
            className={
              "h-5 w-5 text-primary absolute right-2 top-1 invisible group-hover:visible"
            }
          />
        )}
      </ol>
    </button>
  );
};

export default CalloutPreview;

import React from "react";
import Logo from "../../img/stw3_logo.png";
import Card from "../Card";

const PageErrorMessage = () => {
  return (
    <>
      <div className="bg-white border-b border-gray-200 shadow">
        <div className="container mx-auto bg-white">
          <div className="py-6 px-4 sm:px-0">
            <a href="/">
              <img src={Logo} alt="Logo" className="w-32" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Card>
          <div className="text-center py-32 flex items-center justify-center">
            <div>
              <h1 className="font-bold text-3xl">
                Leider ist etwas schiefgelaufen
              </h1>
              <p className="mt-12 ">
                Es tut uns leid, aber es scheint, als ob ein unerwarteter Fehler
                aufgetreten ist.
              </p>
              <p>
                Wir setzen alles daran, diese unvorhergesehene Panne so schnell
                wie möglich zu beheben,
              </p>
              <p className="mt-4">Wir danken Ihnen für Ihr Verständnis.</p>
              <a
                href="/dashboard"
                className="inline-flex items-center justify-center gap-x-2 rounded-md bg-primary px-3 py-2 w-full font-semibold text-white text-lg shadow-sm hover:shadow-lg  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-12 max-w-xs"
              >
                Zur Kampagnenübersicht
              </a>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default PageErrorMessage;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import ChevronUpIcon from "@heroicons/react/20/solid/ChevronUpIcon";
import Input from "../../../components/inputs/Input";
import Button from "../../../components/buttons/Button";
import LoadingBar from "../../../components/loading/LoadingBar";

const KeywordList = ({ keywords, isLoading }) => {
  const [keywordLists, setKeywordLists] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFullList, setShowFullList] = useState(false);
  const [fullListCount, setFullListCount] = useState(0);

  useEffect(() => {
    if (keywords?.length > 0) {
      handleSearchKeyword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords, showFullList]);

  const chunkArray = (array, size) => {
    const chunked = [];
    for (let i = 0; i < array.length; i += size) {
      chunked.push(array.slice(i, i + size));
    }
    return chunked;
  };

  const handleSearchKeyword = (e) => {
    if (e) {
      e.preventDefault();
    }
    let filteredKeywords = keywords || [];
    if (searchQuery !== "") {
      filteredKeywords = filteredKeywords.filter(
        (keyword) =>
          keyword.value &&
          keyword.value.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    const chunkedKeywords = chunkArray(filteredKeywords, 4);

    setFullListCount(filteredKeywords.length);

    let displayedKeywords = chunkedKeywords;
    if (chunkedKeywords.length > 5 && !showFullList) {
      displayedKeywords = chunkedKeywords.slice(0, 5);
    }

    setKeywordLists(displayedKeywords);
  };

  return (
    <>
      <form
        className="grid grid-cols-12 gap-2 mt-8 mb-8"
        onSubmit={handleSearchKeyword}
      >
        <div className="col-span-4">
          <Input
            placeholder={"Keywords suchen ..."}
            onChange={setSearchQuery}
            value={searchQuery}
          />
        </div>
        <div className="col-span-2">
          <Button label="Suchen" type="submit" disabled={!keywords} />
        </div>
      </form>
      <ul className="">
        {keywords?.length === 0 && (
          <div className="text-center p-4 bg-gray-50 rounded-lg text-sm">
            <p className="font-bold">
              Es gab ein Fehler beim Laden Ihrer Keywords
            </p>
            <p className="mt-2 text-gray-500">
              Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut
            </p>
          </div>
        )}
        {isLoading &&
          Array(5)
            .fill(0)
            .map((_, index) => (
              <li
                key={"keywordlistLoading" + index}
                className="py-4 border-t border-gray-200 -mx-4 px-4 text-gray-500 text-sm grid grid-cols-4 first:border-t-0 gap-x-6 place-content-center"
              >
                {Array(4)
                  .fill(0)
                  .map((_, index) => (
                    <LoadingBar
                      type="paragraph"
                      width="w-full"
                      height="h-4"
                      key={"keywordloading" + index}
                    />
                  ))}
              </li>
            ))}
        {!isLoading && keywordLists?.length > 0 && (
          <>
            {keywordLists.map((keywordGroup, index) => (
              <li
                key={"keywordlist" + index}
                className="py-4 border-t border-gray-200 -mx-4 px-4 text-gray-500 text-sm grid grid-cols-4 first:border-t-0 gap-x-6 place-content-center"
              >
                {keywordGroup.map((keyword) => (
                  <div key={keyword.id} className="flex items-center">
                    {keyword.value}
                  </div>
                ))}
              </li>
            ))}
          </>
        )}
        {!isLoading && keywordLists.length === 0 && (
          <div className="text-center p-4 bg-gray-50 rounded-lg text-sm">
            <p className="font-bold">Keine Keywords gefunden</p>
          </div>
        )}
        {(fullListCount > keywordLists.flat().length || showFullList) &&
          !isLoading && (
            <div className="border-t border-gray-200 pt-4 -mx-4 px-4">
              <div className="flex justify-center w-full">
                <button
                  className="flex items-center space-x-2 text-sm text-primary font-medium"
                  onClick={() => setShowFullList((prev) => !prev)}
                >
                  {showFullList ? (
                    <ChevronUpIcon className="h-4 w-4" />
                  ) : (
                    <ChevronDownIcon className="w-4 h-4" />
                  )}
                  {showFullList ? (
                    <p>Weniger anzeigen</p>
                  ) : (
                    <p>{`Alle ${fullListCount} Keywords anzeigen`}</p>
                  )}
                </button>
              </div>
            </div>
          )}
      </ul>
    </>
  );
};

export default KeywordList;

KeywordList.propTypes = {
  keywords: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

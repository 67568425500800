import React, { useEffect, useState } from "react";
import CalloutPreview from "./CalloutPreview";
import Modal from "../../../components/modals/Modal";
import { FormRow } from "./EditSeaAdModal";
import Input from "../../../components/inputs/Input";
import Button from "../../../components/buttons/Button";

const EditCalloutModal = ({ callouts, show, onClose, onSave }) => {
  const [calloutsToEdit, setCalloutsToEdit] = useState(callouts);
  useEffect(() => {
    if (callouts?.length > 0) {
      setCalloutsToEdit(callouts);
    }
  }, [callouts, show]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(calloutsToEdit);
  };

  const handleEdit = (index, value) => {
    const updatedCallouts = [...calloutsToEdit]; // flat copy of the array
    updatedCallouts[index] = { ...updatedCallouts[index], value }; // deep copy of the object & update the value
    setCalloutsToEdit(updatedCallouts);
  };

  const handleResetToDefault = () => {
    const resetedCallouts = calloutsToEdit.map((callout) => ({
      ...callout,
      value: callout.default,
    }));
    setCalloutsToEdit(resetedCallouts);
  };
  return (
    <>
      <Modal show={show} onClose={onClose}>
        <div className="min-w-max max-w-3xl">
          <p className="font-bold">Zusatzinhalte</p>
          <div className="my-4 border border-gray-200 rounded-lg p-4 max-w-3xl ">
            <CalloutPreview callouts={calloutsToEdit} />
          </div>
          <form onSubmit={handleSubmit}>
            <ol>
              {calloutsToEdit?.map((callout, index) => (
                <li
                  className="border-t border-gray-200 py-4 first:border-0"
                  key={"callout" + index}
                >
                  <FormRow
                    textColor={
                      callout?.value?.length > 25 ||
                      callout?.value?.length === 0
                        ? "text-red-500"
                        : null
                    }
                    label={`Zusatzinformation ${index + 1} (${
                      callout?.value?.length
                    }/${25})`}
                  >
                    <Input
                      value={callout?.value}
                      onChange={(value) => handleEdit(index, value)}
                      required
                    />
                  </FormRow>
                </li>
              ))}
            </ol>
            <p className="p-4 text-sm text-gray-500 bg-warmgrey-50 mt-4 text-center rounded-lg">
              Das Bearbeiten der Zusatzinformationen gilt global für alle
              Anzeigen.
            </p>
            <div className="mt-8 flex justify-end items-center w-full space-x-4">
              <div>
                <Button
                  label="Abbrechen"
                  type="button"
                  variant={"text"}
                  onClick={onClose}
                />
              </div>
              <div>
                <Button
                  label="Ursprungsversion"
                  type="button"
                  onClick={handleResetToDefault}
                  variant={"secondary"}
                />
              </div>
              <div>
                <Button
                  label="Übernehmen"
                  type="submit"
                  disabled={calloutsToEdit?.some(
                    (param) => !param.value || param.value.length > 25
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default EditCalloutModal;

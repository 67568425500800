import { useQuery } from "react-query";
import axios from "axios";

export const useDeviceData = (id) => {
  // useQuery-Hook, der die fetchData-Funktion aufruft
  return useQuery(["deviceData", id], () => getDeviceData(id));
};

//Important is type for hook layer
const getDeviceData = async (id) => {
  // Fetch campaign data from the API using campaignId

  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_COREAPI_URL}/campaigns/${id}/devices`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data.data; // Daten zurückgeben
  } catch (error) {
    console.error("Fehler beim Abrufen der Kampagnendaten", error);
    throw error; // Fehler werfen, um ihn in der aufrufenden Komponente zu behandeln
  }
};

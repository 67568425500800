import { bbox } from "@turf/turf";
import mapboxgl from "mapbox-gl";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import useScreenSize from "../../hooks/useScreenSize";
import useFormatter from "../../hooks/useFormatter";

const PostalCodePopup = ({ feature }) => {
  const { decimalFormatter } = useFormatter();
  const properties = feature.properties;
  return (
    <div className="text-sm opacity-80">
      <p className="text-base ">{properties?.plz + " " + properties?.name}</p>
      <div className="flex justify-between space-x-4 mt-4">
        <p>Einwohner</p>
        <p className="font-bold">
          {decimalFormatter.format(properties?.population)}
        </p>
      </div>
    </div>
  );
};

const MapInteraction = forwardRef(({ mapInstance }, ref) => {
  const hoveredPolygonId = useRef(null);
  const { isWindowSmallerThan } = useScreenSize();
  // Methoden, die wir nach außen bereitstellen wollen
  const setMarker = (lat, lng) => {
    if (!mapInstance) return; // noch nicht geladen
    new mapboxgl.Marker({ color: "red" })
      .setLngLat([lng, lat])
      .addTo(mapInstance);
  };

  const setPolygonsAndHover = (geoData) => {
    if (!mapInstance) return;
    if (!geoData) return;

    const popup = new mapboxgl.Popup({ closeButton: false });
    // FeatureCollection bauen
    let geoJsonData = {
      type: "FeatureCollection",
      features: geoData.map((postcode) => ({
        type: "Feature",
        id: postcode.postcode,
        properties: {
          plz: postcode.postcode,
          name: postcode.name,
          population: postcode.population,
        },
        geometry: JSON.parse(postcode.json),
      })),
    };
    try {
      // Quelle hinzufügen
      mapInstance.addSource("main", {
        type: "geojson",
        data: geoJsonData,
      });

      // Layer für Fill
      mapInstance.addLayer({
        id: "main-fill",
        type: "fill",
        source: "main",
        paint: {
          "fill-color": "#671B34",
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.5,
            0.25,
          ],
        },
      });

      // Layer für Outline
      mapInstance.addLayer({
        id: "main-line",
        type: "line",
        source: "main",
        paint: {
          "line-color": "#671B34",
          "line-width": 0.7,
          "line-opacity": 0.7,
        },
      });
    } catch (error) {}
    const setPopup = (e) => {
      if (e.features.length > 0) {
        popup
          .setLngLat(e.lngLat)
          .setHTML(
            ReactDOMServer.renderToString(
              <PostalCodePopup feature={e.features[0]} />
            )
          )
          .addTo(mapInstance);

        // Zuvor gehoveltes Polygon zurücksetzen
        if (hoveredPolygonId.current !== null) {
          mapInstance.setFeatureState(
            { source: "main", id: hoveredPolygonId.current },
            { hover: false }
          );
        }

        hoveredPolygonId.current = e.features[0].id;
        mapInstance.setFeatureState(
          { source: "main", id: hoveredPolygonId.current },
          { hover: true }
        );
      }
    };

    if (isWindowSmallerThan("md")) {
      mapInstance.on("click", "main-fill", setPopup);
    } else {
      mapInstance.on("mousemove", "main-fill", setPopup);
      mapInstance.on("mouseleave", "main-fill", () => {
        popup.remove();
        if (hoveredPolygonId.current !== null) {
          mapInstance.setFeatureState(
            { source: "main", id: hoveredPolygonId.current },
            { hover: false }
          );
        }
        hoveredPolygonId.current = null;
      });
    }

    // Map an Bounding Box anpassen
    const boundingBox = bbox(geoJsonData);
    mapInstance.fitBounds(boundingBox, { padding: 2 });
  };

  // useImperativeHandle macht die Methoden für den Parent nutzbar
  useImperativeHandle(ref, () => ({
    setMarker,
    setPolygonsAndHover,
  }));

  return null; // kein sichtbares Markup
});

export default MapInteraction;

import React, { useContext } from "react";
import PenEditIcon from "../../../components/icons/PenEditIcon";
import AuthContext from "../../../contexts/AuthContext";
import useSnippets from "../../../hooks/useSnippets";

const SnippetPreview = ({ snippets, onEdit, status }) => {
  const { auth } = useContext(AuthContext);
  const { groupedSnippets } = useSnippets(snippets);

  return (
    <button
      disabled={!auth?.isAuthenticated || status !== "in_coordination"}
      onClick={onEdit ? () => onEdit() : null}
      className={`text-[#434343] w-full rounded-lg`}
    >
      <ol
        className={`relative group  px-3 py-1  border border-transparent rounded-lg ${
          onEdit && auth?.isAuthenticated && status === "in_coordination"
            ? "hover:border-primary"
            : ""
        }`}
      >
        {Object.keys(groupedSnippets).map((key) => {
          let snippetGroup = groupedSnippets[key];

          return (
            <li key={"snippetGroup" + key} className="text-sm text-left">
              <span className="mr-2">
                {
                  snippetGroup?.find((snippet) =>
                    snippet.name?.includes("header")
                  )?.value
                }
                :
              </span>
              <span className="">
                <span>
                  {snippetGroup
                    .filter((snippet) => snippet.name?.includes("value"))
                    .map((snippet, index) => snippet.value)
                    .join(", ")}
                </span>
              </span>
            </li>
          );
        })}

        {onEdit && auth?.isAuthenticated && status === "in_coordination" && (
          <PenEditIcon
            className={
              "h-5 w-5 text-primary absolute right-2 top-1 invisible group-hover:visible"
            }
          />
        )}
      </ol>
    </button>
  );
};

export default SnippetPreview;

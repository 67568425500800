import React, { useEffect, useState } from "react";
import Modal from "../../../components/modals/Modal";
import SeaAdPreview from "./SeaAdPreview";
import useSeaAdtemplateParameters from "../../../hooks/useSeaAdtemplateParameters";
import Input from "../../../components/inputs/Input";
import TextArea from "../../../components/textareas/TextArea";
import Button from "../../../components/buttons/Button";

const EditSeaAdModal = ({ show, onClose, onSave, adtemplate }) => {
  const [parametersToEdit, setParametersToEdit] = useState(
    adtemplate?.parameters
  );

  const [deeplink, setDeeplink] = useState(adtemplate?.deeplink);
  useEffect(() => {
    if (adtemplate?.parameters) {
      setParametersToEdit(adtemplate.parameters);
    }
    if (adtemplate?.deeplink) {
      setDeeplink(adtemplate.deeplink);
    }
  }, [adtemplate, show]);

  const { headline, url, description, deeplinkDomain } =
    useSeaAdtemplateParameters(parametersToEdit, deeplink);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(parametersToEdit, deeplink);
  };

  const handleResetToDefault = () => {
    let newParameters = [...parametersToEdit];
    setParametersToEdit(
      newParameters.map((parameter) => ({
        ...parameter,
        value: parameter.default,
      }))
    );
    setDeeplink(adtemplate.default_deeplink);
  };

  const handleEdit = (name, value) => {
    let newParameters = [...parametersToEdit];
    newParameters = newParameters.map((parameter) => {
      if (parameter.name === name) {
        return { ...parameter, value };
      }
      return parameter;
    });

    setParametersToEdit(newParameters);
  };

  const parameterMaxLengths = [
    { name: "headline", maxLength: 30 },
    { name: "description", maxLength: 90 },
    { name: "path", maxLength: 15 },
  ];

  return (
    <Modal show={show} onClose={onClose}>
      <div className="min-w-max max-w-3xl">
        <p className="font-bold">Anzeige bearbeiten</p>
        <div className="my-4 border border-gray-200 rounded-lg p-4 max-w-3xl ">
          <SeaAdPreview
            url={url}
            headlines={headline}
            descriptions={description}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="divide-y divide-gray-200 space-y-2">
            <FormRow label="Ziel URL">
              <Input value={deeplink} onChange={setDeeplink} />
            </FormRow>
            {parametersToEdit
              ?.filter((parameter) => parameter.name.includes("headline"))
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((parameter, index) => (
                <div key={"headline" + index} className="pt-2 first:pt-0">
                  <FormRow
                    textColor={
                      parameter?.value?.length >
                        parameterMaxLengths[0].maxLength ||
                      parameter?.value?.length === 0
                        ? "text-red-500"
                        : null
                    }
                    label={`Überschrift ${index + 1} (${
                      parameter.value?.length
                    }/${parameterMaxLengths[0].maxLength})`}
                  >
                    <Input
                      value={parameter.value}
                      onChange={(value) => handleEdit(parameter.name, value)}
                      required
                    />
                  </FormRow>
                </div>
              ))}

            {parametersToEdit
              ?.filter((parameter) => parameter.name.includes("description"))
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((parameter, index) => (
                <div key={"description" + index} className="pt-2 first:pt-0">
                  <FormRow
                    textColor={
                      parameter?.value?.length >
                        parameterMaxLengths[1].maxLength ||
                      parameter?.value?.length === 0
                        ? "text-red-500"
                        : null
                    }
                    label={
                      <>
                        {`Beschreibungungstext ${index + 1}`} <br />
                        {`(${parameter.value?.length || 0}/${
                          parameterMaxLengths[1].maxLength
                        })`}
                      </>
                    }
                  >
                    <TextArea
                      name="description"
                      rows={2}
                      onChange={(value) => handleEdit(parameter.name, value)}
                      value={parameter.value}
                      required
                    />
                  </FormRow>
                </div>
              ))}
            <div className="pt-2">
              <FormRow label="Angezeigte URL">
                <div className="flex items-center space-x-1 text-sm">
                  <p className="whitespace-nowrap">{deeplinkDomain}</p>
                  {parametersToEdit
                    ?.filter((parameter) => parameter.name.includes("path"))
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((parameter, index) => (
                      <div
                        key={"path" + index}
                        className="flex  items-center space-x-1"
                      >
                        <p>/</p>
                        <Input
                          value={parameter.value}
                          onChange={(value) =>
                            handleEdit(parameter.name, value)
                          }
                        />
                        <p
                          className={`${
                            parameter.value?.length >
                              parameterMaxLengths[2].maxLength ||
                            parameter.value?.length === 0
                              ? "text-red-500"
                              : "text-gray-500"
                          }`}
                        >
                          {`(${parameter.value?.length || 0}/${
                            parameterMaxLengths[2].maxLength
                          })`}
                        </p>
                      </div>
                    ))}
                </div>
              </FormRow>
            </div>
          </div>
          <div className="mt-8 flex justify-end items-center w-full space-x-4">
            <div>
              <Button
                variant={"text"}
                label="Abbrechen"
                type="button"
                onClick={onClose}
              />
            </div>
            <div>
              <Button
                label="Ursprungsversion"
                type="button"
                onClick={handleResetToDefault}
                variant={"secondary"}
              />
            </div>
            <div>
              <Button
                label="Übernehmen"
                type="submit"
                disabled={parametersToEdit?.some(
                  (parameter) =>
                    parameter?.value?.length >
                      parameterMaxLengths.find((p) =>
                        parameter.name.includes(p.name)
                      )?.maxLength || parameter.value?.length === 0
                )}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditSeaAdModal;

export const FormRow = ({ label, children, textColor }) => {
  return (
    <div className="grid grid-cols-12 gap-4">
      <p
        className={`col-span-3 place-content-center text-sm ${
          textColor ?? "text-gray-500"
        }`}
      >
        {label}
      </p>
      <div className="col-span-9">{children}</div>
    </div>
  );
};

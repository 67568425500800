import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import { useParams, useSearchParams } from "react-router-dom";
import SeaAd from "./components/SeaAd";
import { Disclosure } from "@headlessui/react";
import noDataImg from "../../img/no_data.jpeg";
import LoadingBar from "../../components/loading/LoadingBar";

const Ads = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get("start");
  const endDate = searchParams.get("end");
  const [inactiveAds, setInactiveAds] = useState([]);
  const [activeAds, setActiveAds] = useState([]);
  const { data: campaign, isLoading } = useSingleCampaignData(
    id,
    `?sea=true${
      startDate && endDate ? `&start=${startDate}&end=${endDate}` : ""
    }`
  );
  useEffect(() => {
    if (campaign) {
      setActiveAds(
        campaign.channels.sea[0].adgroups.flatMap((adgroup) =>
          adgroup.ads.filter(
            (ad) =>
              ad.status?.toLowerCase() === "enabled" ||
              ad.status?.toLowerCase() === "active"
          )
        )
      );

      setInactiveAds(
        campaign.channels.sea[0].adgroups.flatMap((adgroup) =>
          adgroup.ads.filter(
            (ad) =>
              ad.status?.toLowerCase() === "paused" ||
              ad.status?.toLowerCase() === "disabled" ||
              ad.status === undefined
          )
        )
      );
    }
  }, [campaign]);
  return (
    <div>
      <Card>
        {isLoading ? (
          <div className="text-center border-2 border-dashed  flex items-center justify-center h-64 rounded">
            <LoadingBar height="h-5" width={"w-32"} />
          </div>
        ) : (
          activeAds.length === 0 && (
            <div className="text-center border-2 border-dashed  flex items-center justify-center h-64 rounded">
              <div>
                <div className="flex justify-center">
                  <img
                    src={noDataImg}
                    alt="no_data_found"
                    className="w-36 h-auto"
                  />
                </div>
                <h4 className="text-gray-300 font-bold text-center text-sm">
                  Leider konnten wir keine aktiven Anzeigen finden.
                </h4>
              </div>
            </div>
          )
        )}
        <ol className="space-y-4 divide-y divide-gray-200">
          {activeAds
            .sort((a, b) => (a.impressions < b.impressions ? -1 : 1))
            .map((ad, index) => (
              <li className="pt-4 first:pt-0">
                <SeaAd
                  ad={ad}
                  isLoading={isLoading}
                  index={index + 1}
                  key={"activeAd" + index}
                />
              </li>
            ))}
        </ol>
      </Card>
      {inactiveAds.length > 0 && (
        <div className="mt-4">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`py-2 text-center text-primary  bg-white ${
                    open ? "rounded" : "rounded"
                  } shadow-md p-5 w-full flex justify-center`}
                >
                  <p className="border-b border-primary max-w-fit font-semibold my-4 text-sm ">
                    Inaktive Anzeigen ({inactiveAds.length}){" "}
                    {open ? "ausblenden" : "einblenden"}
                  </p>
                </Disclosure.Button>
                <Disclosure.Panel className="bg-white px-5 pb-5 shadow-md rounded space-y-4">
                  {inactiveAds
                    .sort((a, b) => a.impressions > b.impressions)
                    .map((ad, index) => (
                      <SeaAd
                        ad={ad}
                        isLoading={isLoading}
                        key={"inactiveAd" + index}
                        index={activeAds.length + index + 1}
                      />
                    ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      )}
    </div>
  );
};

export default Ads;

import { Popover } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { DateRangePicker as DRP } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from "./buttons/Button";
import { CalendarIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import Card from "./Card";
import { de } from "date-fns/locale";
import { usePopper } from "react-popper";

const DateRangePicker = ({
  start,
  end,
  onChange,
  minDate,
  maxDate,
  definedRanges,
}) => {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  const [selectedRange, setSelectedRange] = useState([
    { startDate: new Date(start), endDate: new Date(end) },
  ]);

  const handleSelect = ({ range1 }) => {
    setSelectedRange([range1]);
    onChange({ start: range1.startDate, end: range1.endDate });
  };

  useEffect(() => {
    if (start && end) {
      setSelectedRange([
        { startDate: new Date(start), endDate: new Date(end) },
      ]);
    }
  }, [start, end]);

  return (
    <Popover className="relative">
      <Popover.Button as="div" ref={setReferenceElement}>
        <Button
          iconBeforeLabel={<CalendarIcon />}
          iconAfterLabel={<ChevronDownIcon />}
          label={
            moment(start).format("DD. MMM YYYY") +
            " - " +
            moment(end).format("DD. MMM YYYY")
          }
        />
      </Popover.Button>

      <Popover.Panel
        className="z-50 shadow-xl rounded mt-4 -left-50"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <Card>
          <DRP
            minDate={minDate}
            maxDate={maxDate}
            staticRanges={definedRanges}
            inputRanges={[]}
            rangeColors={["#671B34"]}
            showMonthAndYearPickers={true}
            showDateDisplay={false}
            locale={de}
            ranges={selectedRange}
            onChange={handleSelect}
          />
        </Card>
      </Popover.Panel>
    </Popover>
  );
};

export default DateRangePicker;

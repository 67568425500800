import React, { useContext, useEffect, useState } from "react";
import Header from "./components/Header";
import { useBooking, useUpdateBooking } from "../../hooks/useBookings";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import Card, { CardHeader } from "../../components/Card";
import BannerConfirmationArea from "./plaComponents/BannerConfirmationArea";
import Button from "../../components/buttons/Button";
import { useToaster } from "react-hot-toast";
import { HiMiniCheckCircle } from "react-icons/hi2";
import AuthenticateMessage from "./components/AuthenticateMessage";
import UpdateErrorMessage from "./components/UpdateErrorMessage";
import Modal from "../../components/modals/Modal";
import useModal from "../../hooks/useModal";
import BannerChangelogHistory from "./plaComponents/BannerChangelogHistory";
import BookingChangelogHistory from "./plaComponents/BookingChangelogHistory";
import { LuFileEdit } from "react-icons/lu";

const PLABooking = () => {
  const { hash } = useParams();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const { toast } = useToaster();
  const { data: booking, isLoading } = useBooking(auth?.token, hash);
  const [showRequiredErrors, setShowRequiredErrors] = useState(false);
  const [adtemplates, setAdtemplates] = useState([]);
  const { toggleModal, modalRegistered } = useModal();

  const {
    mutateAsync: updateBooking,
    isError: isUpdateError,
    isLoading: isUpdating,
  } = useUpdateBooking(auth?.token, hash);

  useEffect(() => {
    if (
      !isLoading &&
      booking?.adtemplates?.length > 0 &&
      adtemplates.length === 0
    ) {
      setAdtemplates(booking.adtemplates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking, isLoading]);

  const handleBannerAcceptedOrDeclined = (adtemplateIndex, accepted) => {
    const newAdtemplates = [...adtemplates];
    newAdtemplates[adtemplateIndex] = {
      ...newAdtemplates[adtemplateIndex],
      status: accepted ? "approved" : "rejected",
    };
    setAdtemplates(newAdtemplates);
  };

  const handleSubmit = async () => {
    setShowRequiredErrors(false);
    if (
      adtemplates.some(
        (adtemplate) =>
          adtemplate.status === null ||
          adtemplate.status === "waiting_for_approval" ||
          (adtemplate.status === "rejected" && !adtemplate.comment)
      )
    ) {
      setShowRequiredErrors(true);
      return;
    }

    try {
      if (adtemplates.some((adtemplate) => adtemplate.status === "rejected")) {
        const response = await updateBooking({
          status: "rejected",
          adtemplates: adtemplates,
        });
        if (response.success) {
          navigate(`rejection`);
        } else {
          toast.error("Fehler beim Freigeben der Kampagne");
        }
      } else {
        const response = await updateBooking({
          status: "accepted",
          adtemplates: adtemplates,
        });
        if (response.success) {
          navigate(`confirmation`);
        } else {
          toast.error("Fehler beim Freigeben der Kampagne");
        }
      }
    } catch (error) {
      toast.error("Fehler beim Freigeben der Kampagne");
    }
  };
  const handleCommentChange = (adtemplateIndex, comment) => {
    const newAdtemplates = [...adtemplates];
    newAdtemplates[adtemplateIndex] = {
      ...newAdtemplates[adtemplateIndex],
      comment,
    };
    setAdtemplates(newAdtemplates);
  };

  return (
    <>
      <div className="space-y-8">
        {[
          "accepted",
          "campaign_running",
          "campaign_over",
          "finished",
          "failed",
        ].includes(booking?.status) ? (
          <div className="flex items-center space-x-4 border border-green-500 bg-green-50 text-green-600 p-4 rounded-lg mt-8">
            <HiMiniCheckCircle className="h-5 w-5" />
            <p>Ihre Kampagne wurde bereits freigegeben.</p>
          </div>
        ) : booking?.status === "rejected" ? (
          <div className="flex items-center space-x-4 border border-yellow-500 bg-yellow-50 text-yellow-600 p-4 rounded-lg mt-8">
            <LuFileEdit className="h-5 w-5" />
            <p>Ihr Feedback befindet sich noch in Bearbeitung.</p>
          </div>
        ) : auth && !auth?.isAuthenticated && !isLoading && booking ? (
          <div className="mt-8">
            <AuthenticateMessage />
          </div>
        ) : (
          <></>
        )}
        <Header isLoading={isLoading} booking={booking} />
        {booking.changelog.length > 1 && (
          <Card>
            <CardHeader title="Freigabehistorie" />
            <BookingChangelogHistory changelogs={booking?.changelog} />
          </Card>
        )}
        <Card>
          <CardHeader
            title="Werbemittel freigeben"
            description={"Bitte überprüfen Sie die Werbemittel."}
          />
          <ol className="divide-y divide-stw3-gray-200 space-y-4">
            {adtemplates.map((adtemplate, index) => (
              <li key={adtemplate?.adtemplate_id} className="pt-4">
                <BannerConfirmationArea
                  booking={booking}
                  bookingStatus={booking?.status}
                  showRequiredErrors={showRequiredErrors}
                  adtemplate={adtemplate}
                  onAcceptedOrDenied={(status) =>
                    handleBannerAcceptedOrDeclined(index, status)
                  }
                  onCommentChange={(comment) =>
                    handleCommentChange(index, comment)
                  }
                  disabled={
                    booking?.status !== "in_coordination" ||
                    !auth?.isAuthenticated
                  }
                />
                <BannerChangelogHistory
                  changelogs={adtemplate.changelog}
                  bookingStatus={booking?.status}
                />
              </li>
            ))}
          </ol>
        </Card>
        {showRequiredErrors && (
          <div className="my-4 bg-yellow-100 rounded-lg text-yellow-700 p-4 border border-yellow-700">
            <p className="font-bold">Fehlende Informationen</p>
            <p className="text-sm whitespace-pre-line">
              {`Sieht aus, als hätten Sie uns noch nicht alle Banner bestätigt oder abgelehnt.\n Bitte überprüfen Sie Ihre Eingabe.`}
            </p>
          </div>
        )}
        {isUpdateError && <UpdateErrorMessage />}
        {booking?.status === "in_coordination" && auth?.isAuthenticated && (
          <div className="flex items-center space-x-4">
            <Button
              label={"Abbrechen"}
              variant={"text"}
              type="button"
              disabled={isUpdating}
              onClick={() => toggleModal("cancel")}
            />
            <Button
              label={"Absenden"}
              type="button"
              onClick={handleSubmit}
              isLoading={isUpdating}
            />
          </div>
        )}
      </div>

      <Modal
        show={modalRegistered("cancel")}
        onClose={() => toggleModal("cancel")}
      >
        <p className="font-bold text-base whitespace-nowrap">
          Kampagnenfreigabe abbrechen
        </p>
        <div className="text-sm text-gray-500 mt-4  min-w-[500px]">
          <p>Wir können Ihre Kampagne erst nach Freigabe durch Sie starten.</p>
          <p className="mt-4">
            Sind sie sicher, dass Sie die Freigabe abbrechen wollen?
          </p>
        </div>
        <div className="flex justify-end items-center space-x-4 whitespace-nowrap mt-8 ">
          <div>
            <Button
              label={"weiter Bearbeiten"}
              variant={"text"}
              type={"button"}
              onClick={() => toggleModal("cancel")}
            />
          </div>
          <div>
            <Button
              label="Zur Startseite"
              type="button"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PLABooking;

import React from "react";
import { GoLinkExternal } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";

const AuthenticateMessage = () => {
  const location = useLocation();
  return (
    <div className="flex items-center space-x-4 border border-green-500 bg-green-50 text-green-600 p-4 rounded-lg">
      <GoLinkExternal className="h-5 w-5" />
      <div>
        <span>Um die Kampagne zu bearbeiten oder freizugeben, bitte </span>
        <Link
          to="/login"
          className="underline"
          state={{
            from: location.pathname,
          }}
        >
          einloggen
        </Link>
      </div>
    </div>
  );
};

export default AuthenticateMessage;

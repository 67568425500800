import axios from "axios";
import { useQuery } from "react-query";

export const useKeywordsByKeywordgroupId = (
  token,
  keywordgroupId,
  customerId
) => {
  return useQuery(
    ["keywords", keywordgroupId, customerId],
    () => getKeywordsByKeywordgroupId(token, keywordgroupId, customerId),
    {
      enabled: !!keywordgroupId,
    }
  );
};

const getKeywordsByKeywordgroupId = async (
  token,
  keywordgroupId,
  customerId
) => {
  try {
    let url = `${process.env.REACT_APP_COREAPI_URL}/keywordgroups/${keywordgroupId}/keywords`;
    if (customerId) {
      url += `?customer_id=${customerId}`;
    }
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (error) {
    throw error;
  }
};

import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Creative from "./Creative";
import "@splidejs/splide/css";
import useScreenSize from "../../hooks/useScreenSize";
import CreativeOverview from "./CreativeOverview";

const CreativeCarousel = ({ creatives, onChange, onClick }) => {
  const { isWindowSmallerThan, windowWidth } = useScreenSize();
  const [carouselWidth, setCarouselWidth] = useState(900);

  useEffect(() => {
    if (isWindowSmallerThan("sm")) {
      setCarouselWidth(300);
      return;
    }
    if (isWindowSmallerThan("md")) {
      setCarouselWidth(400);
      return;
    }
    if (isWindowSmallerThan("lg")) {
      setCarouselWidth(600);
      return;
    }
    if (isWindowSmallerThan("xl")) {
      setCarouselWidth(700);
      return;
    }
    if (isWindowSmallerThan("2xl")) {
      setCarouselWidth(900);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  const handleCarouselMove = (newIndex) => {
    onChange(newIndex);
  };

  return (
    <>
      <style>{`.splide__arrow {
    display: true; }
    .splide__pagination__page{background-color: #BEBEBF}
    .splide__pagination__page.is-active{background-color: #671B34;}
  `}</style>
      <Splide
        onMove={(_, newIndex) => handleCarouselMove(newIndex)}
        //onClick={(_, newIndex) => handleCarouselMove(newIndex)}
        aria-label="creatives"
        options={{
          width: "100%",
          height: "340px",
          type: "loop",
          arrows: true,
        }}
      >
        {creatives.map((creative, index) => {
          return (
            <SplideSlide key={index}>
              <div className="flex items-center justify-center h-full w-full py-12 ">
                <div
                  className={` h-full flex items-center justify-center`}
                  style={{ width: carouselWidth }}
                >
                  <CreativeOverview ad={creative} onClick={onClick} />
                </div>
              </div>
            </SplideSlide>
          );
        })}
      </Splide>
    </>
  );
};

export default CreativeCarousel;

import React, { useContext, useEffect, useState } from "react";
import { useBooking, useUpdateBooking } from "../../hooks/useBookings";
import AuthContext from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Card, { CardHeader } from "../../components/Card";
import KeywordList from "./components/KeywordList";
import TextArea from "../../components/textareas/TextArea";
import CheckBox from "../../components/checkboxes/CheckBox";
import Button from "../../components/buttons/Button";
import EditSeaAdModal from "./components/EditSeaAdModal";
import SeaAdElement from "./components/SeaAdElement";
import EditSitelinkModal from "./components/EditSitelinkModal";
import Spinner from "../../components/loading/Spinner";
import Header from "./components/Header";
import EditCalloutModal from "./components/EditCalloutModal";
import useModal from "../../hooks/useModal";
import toast from "react-hot-toast";
import Modal from "../../components/modals/Modal";
import { HiMiniCheckCircle } from "react-icons/hi2";
import AuthenticateMessage from "./components/AuthenticateMessage";
import EditSnippetModal from "./components/EditSnippetModal";
import UpdateErrorMessage from "./components/UpdateErrorMessage";

const Booking = () => {
  const { auth } = useContext(AuthContext);
  const { hash } = useParams();
  const { data: booking, isLoading } = useBooking(auth?.token, hash);
  const {
    mutateAsync: updateBooking,
    isError: isUpdateError,
    isLoading: isUpdating,
  } = useUpdateBooking(auth?.token, hash);
  const navigate = useNavigate();
  const [comment, setComment] = useState(booking?.comment ?? "");
  const [adtemplateIndexToEdit, setAdtemplateIndexToEdit] = useState(null);
  const [sitelinkIndexToEdit, setSitelinkIndexToEdit] = useState(null);
  const [adtemplates, setAdtemplates] = useState([]);
  const [sitelinks, setSitelinks] = useState([]);
  const [callouts, setCallouts] = useState([]);
  const [snippets, setSnippets] = useState([]);
  const { modalRegistered, toggleModal } = useModal();

  const handleSubmitCampaign = async (e) => {
    e.preventDefault();
    let data = {
      status: "accepted",
      comment: comment !== "" ? comment : null,
      adtemplates: adtemplates.map((adtemplate) => {
        delete adtemplate.default_deeplink;
        return {
          ...adtemplate,
          parameters: adtemplate.parameters.map((parameter) => {
            delete parameter.default;

            return {
              ...parameter,
            };
          }),
        };
      }),
      sitelinks: transformSitelinksToBookingDataFormat(),
      callouts: transformCalloutsToBookingDataFormat(),
      snippets: transformSnippetsToBookingDataFormat(),
    };
    try {
      const response = await updateBooking(data);
      if (response.success) {
        navigate(`confirmation`);
      } else {
        toast.error("Fehler beim Freigeben der Kampagne");
      }
    } catch (error) {
      toast.error("Fehler beim Freigeben der Kampagne");
    }
  };

  useEffect(() => {
    if (booking?.comment) {
      setComment(booking.comment);
    }
    if (booking?.adtemplates?.length > 0) {
      setAdtemplates(booking.adtemplates);
    }
    if (booking?.sitelinks?.length > 0) {
      let sitelinkParameter = booking.sitelinks
        .map((sitelink) => sitelink.parameters)
        ?.flat();
      let groupedByPosition = Object.groupBy(
        sitelinkParameter,
        ({ position }) => position
      );

      setSitelinks(
        Object.keys(groupedByPosition).map((key) => groupedByPosition[key])
      );
    }

    if (booking?.callouts?.length > 0) {
      let calloutParameter = booking.callouts
        .map((callout) => callout.parameters)
        ?.flat();

      setCallouts(calloutParameter);
    }
    if (booking?.snippets?.length > 0) {
      let snippetParameters = booking.snippets
        .map((snippet) => snippet.parameters)
        ?.flat();

      setSnippets(snippetParameters);
    }
  }, [booking]);
  const handleSaveAdtemplate = (adtemplateParameters, deeplink) => {
    let newAdtemplates = [...adtemplates];
    newAdtemplates[adtemplateIndexToEdit] = {
      ...newAdtemplates[adtemplateIndexToEdit],
      parameters: adtemplateParameters,
      deeplink: deeplink,
    };
    setAdtemplates(newAdtemplates);
    setAdtemplateIndexToEdit(null);
  };

  const handleSaveSitelinks = (sitelinks) => {
    setSitelinks(sitelinks);
    setSitelinkIndexToEdit(null);
  };

  const transformSitelinksToBookingDataFormat = () => {
    let allEditeParameters = sitelinks.flat();
    return booking.sitelinks.map((originalSitelink) => {
      return {
        ...originalSitelink,
        parameters: originalSitelink.parameters.map((originalParameter) => {
          delete originalParameter.default;
          let editedParameter = allEditeParameters.find(
            (editedParameter) => editedParameter.id === originalParameter.id
          );
          return {
            ...originalParameter,
            value: editedParameter.value,
          };
        }),
      };
    });
  };

  const transformSnippetsToBookingDataFormat = () => {
    return booking.snippets.map((originalSnippet) => {
      return {
        ...originalSnippet,
        parameters: originalSnippet.parameters.map((originalParameter) => {
          delete originalParameter.default;

          let editedParameter = snippets.find(
            (editedParameter) => editedParameter.id === originalParameter.id
          );
          return {
            ...originalParameter,
            value: editedParameter.value,
          };
        }),
      };
    });
  };

  const transformCalloutsToBookingDataFormat = () => {
    return booking.callouts.map((originalCallout) => {
      return {
        ...originalCallout,
        parameters: originalCallout.parameters.map((originalParameter) => {
          delete originalParameter.default;
          let editedParameter = callouts.find(
            (editedParameter) => editedParameter.id === originalParameter.id
          );
          return {
            ...originalParameter,
            value: editedParameter.value,
          };
        }),
      };
    });
  };

  return (
    <>
      <div className="space-y-8 container mx-auto my-2 pb-12">
        <div className="mt-8">
          {[
            "accepted",
            "campaign_running",
            "campaign_over",
            "finished",
          ].includes(booking?.status) && (
            <div className="flex items-center space-x-4 border border-green-500 bg-green-50 text-green-600 p-4 rounded-lg">
              <HiMiniCheckCircle className="h-5 w-5" />

              <p>Die Suchmaschinen Kampagne wurde bereits freigegeben.</p>
            </div>
          )}

          {auth && !auth?.isAuthenticated && !isLoading && booking && (
            <AuthenticateMessage />
          )}
          <Header isLoading={isLoading} booking={booking} />
        </div>
        <Card>
          <CardHeader
            title="Anzeigen"
            description={
              auth?.isAuthenticated
                ? "Hier können Sie die von uns optimal konfigurierten Suchmaschinen-Anzeigen einsehen und bei Bedarf Änderungen vornehmen."
                : "Hier können Sie die von uns optimal konfigurierten Suchmaschinen-Anzeigen einsehen."
            }
          />
          {isLoading ? (
            <div className="grid grid-cols-12 gap-8 mt-4">
              <p className="text-base text-gray-500 font-normal">Anzeige:</p>
              <div
                className="h-64 border border-gray-300 col-span-8 flex items-center justify-center"
                style={{
                  boxShadow: "1px 2px 15px 0px #11182726",
                }}
              >
                <Spinner />
              </div>
            </div>
          ) : (
            <ol className="mt-4">
              {adtemplates?.length > 0 &&
                adtemplates.map((adtemplate, index) => (
                  <li
                    key={index}
                    className="border-t border-stw3-gray-200 first:border-0 mt-4 pt-4 first:pt-0 first:mt-0 "
                  >
                    <SeaAdElement
                      status={booking?.status}
                      sitelinks={sitelinks}
                      callouts={callouts}
                      adtemplate={adtemplate}
                      snippets={snippets}
                      onEditSnippets={() => toggleModal("editSnippets")}
                      onEdit={() => setAdtemplateIndexToEdit(index)}
                      onEditSitelinks={setSitelinkIndexToEdit}
                      onEditCallouts={() => toggleModal("editCallouts")}
                    />
                  </li>
                ))}
            </ol>
          )}
        </Card>
        <Card>
          <CardHeader
            title="Keywords"
            description={
              auth?.isAuthenticated
                ? "Wir haben die idealen Keywords, die zu Ihren Anzeigen passen, für Sie ausgesucht. Wenn Sie ein weiteres Keyword hinzufügen wollen, teilen Sie es uns über die Kommentare mit. Wir prüfen dann gerne für Sie, ob das Keyword für ihre Kampagne genug Relevanz hat."
                : "Wir haben die idealen Keywords, die zu Ihren Anzeigen passen, für Sie ausgesucht."
            }
          />

          <KeywordList keywords={booking?.keywords} isLoading={isLoading} />
        </Card>
        {auth?.isAuthenticated && (
          <Card>
            <CardHeader title="Kommentare" />
            <TextArea
              rows={6}
              placeholder="Möchten Sie uns etwas mitteilen? (Kein Pflichtfeld)"
              onChange={setComment}
              value={comment}
              disabled={booking?.status === "accepted"}
            />
          </Card>
        )}
        {booking?.status === "in_coordination" && auth?.isAuthenticated && (
          <form onSubmit={handleSubmitCampaign}>
            <Card>
              <label className="flex items-center space-x-8">
                <CheckBox required />
                <p className="text-sm font-semibold">
                  Hiermit bestätige ich, dass die Anzeigenentwürfe auf Ihre
                  Korrektheit und Vollständigkeit geprüft wurden und gebe diese
                  somit frei.
                </p>
              </label>
            </Card>

            {isUpdateError && <UpdateErrorMessage />}
            <div className="mt-8 flex items-center max-w-xs">
              <Button
                label="Abbrechen"
                variant="text"
                type={"button"}
                onClick={() => toggleModal("cancel")}
              />
              <Button
                variant="primary"
                label="Absenden"
                type={"submit"}
                disabled={isLoading}
                isLoading={isUpdating}
              />
            </div>
          </form>
        )}

        {auth && !auth?.isAuthenticated && !isLoading && (
          <AuthenticateMessage />
        )}
      </div>
      <EditSeaAdModal
        show={adtemplateIndexToEdit !== null}
        onClose={() => setAdtemplateIndexToEdit(null)}
        adtemplate={adtemplates?.at(adtemplateIndexToEdit)}
        onSave={handleSaveAdtemplate}
      />
      <EditSitelinkModal
        show={sitelinkIndexToEdit !== null}
        onClose={() => setSitelinkIndexToEdit(null)}
        sitelinks={sitelinks}
        selectedSitelinkIndex={sitelinkIndexToEdit}
        onSave={handleSaveSitelinks}
      />
      <EditCalloutModal
        show={callouts?.length > 0 && modalRegistered("editCallouts")}
        onClose={() => toggleModal("editCallouts")}
        callouts={callouts}
        onSave={(value) => {
          setCallouts(value);
          toggleModal("editCallouts");
        }}
      />
      <EditSnippetModal
        show={snippets?.length > 0 && modalRegistered("editSnippets")}
        onClose={() => toggleModal("editSnippets")}
        snippets={snippets}
        onSave={(value) => {
          setSnippets(value);
          toggleModal("editSnippets");
        }}
        isSnippet
      />
      <Modal
        show={modalRegistered("cancel")}
        onClose={() => toggleModal("cancel")}
      >
        <p className="font-bold text-base whitespace-nowrap">
          Kampagnenfreigabe abbrechen
        </p>
        <div className="text-sm text-gray-500 mt-4  min-w-[500px]">
          <p>Wir können Ihre Kampagne erst nach Freigabe durch Sie starten.</p>
          <p className="mt-4">
            Sind sie sicher, dass Sie die Freigabe abbrechen wollen?
          </p>
        </div>
        <div className="flex justify-end items-center space-x-4 whitespace-nowrap mt-8 ">
          <div>
            <Button
              label={"weiter Bearbeiten"}
              variant={"text"}
              type={"button"}
              onClick={() => toggleModal("cancel")}
            />
          </div>
          <div>
            <Button
              label="Zur Startseite"
              type="button"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Booking;

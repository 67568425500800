import React, { useMemo } from "react";
import useFormatter from "../../../hooks/useFormatter";

const useKeywordTableColumns = (sum, totalKeywords) => {
  const { decimalFormatter } = useFormatter();

  const columns = useMemo(
    () => [
      {
        Header: "Keyword",
        accessor: "keyword",
        id: "id",
        Footer: () => {
          return (
            <div>
              <p className="font-bold">Summe</p>
              <p className="mt-2">Weitere Keywords ...</p>
              <p className="text-gray-500 mt-2">Insgesamt</p>
            </div>
          );
        },
      },
      {
        Header: "Klicks",
        accessor: "clicks",
        align: "right",
        width: 50,
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        Footer: (info) => {
          return <Footer info={info} sum={sum} kpi="clicks" />;
        },
      },
      {
        Header: "Sichtkontakte",
        accessor: "impressions",
        align: "right",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        Footer: (info) => {
          return <Footer info={info} sum={sum} kpi="impressions" />;
        },
      },
      /* {
        Header: "Klickrate",
        align: "right",
        accessor: "ctr",
        Cell: ({ value }) =>
          value
            ? parseFloat(value)?.toFixed(2).replace(/\./g, ",") + " %"
            : "-",
        Footer: (info) => {
          const totalImps = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseInt(row?.values.impressions) + sum,
                0
              ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [info.rows]
          );
          const totalClicks = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseInt(row?.values.clicks) + sum,
                0
              ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [info.rows]
          );
          const total = useMemo(
            () =>
              info.rows.reduce((sum, row) => parseInt(row.values.ctr) + sum, 0),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [info.rows]
          );

          return (
            <div>
              <p className=" font-bold">
                {total
                  ? (total / info.rows.length)?.toFixed(2).replace(/\./g, ",") +
                    " %"
                  : "-"}
              </p>
              <p className="mt-2">
                {totalImps && totalClicks
                  ? ((totalClicks / totalImps) * 100)
                      ?.toFixed(2)
                      .replace(/\./g, ",") + " %"
                  : "-"}
              </p>
              <p className="text-gray-500 mt-2">
                {sum && sum.ctr
                  ? parseFloat(sum.ctr)?.toFixed(2).replace(/\./g, ",") + " %"
                  : "-"}
              </p>
            </div>
          );
        },
      }, */
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sum, totalKeywords]
  );

  return { columns };
};

export default useKeywordTableColumns;

const Footer = ({ info, sum, kpi }) => {
  const { decimalFormatter } = useFormatter();
  const total = useMemo(
    () => info.rows.reduce((sum, row) => parseInt(row?.values[kpi]) + sum, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [info.rows]
  );

  return (
    <div>
      <p className="font-bold">
        {total ? decimalFormatter.format(total) : "-"}
      </p>
      <p className=" mt-2">
        {total ? decimalFormatter.format(sum[kpi] - total) : "-"}
      </p>
      <p className="text-gray-500 mt-2">
        {sum && sum[kpi] ? decimalFormatter.format(sum[kpi]) : "-"}
      </p>
    </div>
  );
};

import { useQuery } from "react-query";
import axios from "axios";

// API-URL aus Umgebungsvariablen holen
const apiUrl = process.env.REACT_APP_COREAPI_URL;

export const useReach = (id, queryString) => {
  // useQuery-Hook, der die fetchData-Funktion aufruft
  return useQuery(["reachData", id, queryString], () =>
    FetchReachData(id, queryString)
  );
};

//Important is type for hook layer
const FetchReachData = async (id, queryString) => {
  // Fetch campaign data from the API using campaignId

  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(
      `${apiUrl}/campaigns/${id}/reach/daily${queryString ?? ""}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data.data; // Daten zurückgeben
  } catch (error) {
    console.error("Fehler beim Abrufen der Kampagnendaten", error);
    throw error; // Fehler werfen, um ihn in der aufrufenden Komponente zu behandeln
  }
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import DynamicBarChart from "../../components/DynamicBarChart";
import { useReach } from "../../hooks/useReach";
import Card from "../../components/Card";
import LineChart from "./components/LineChart";
import useFormatter from "../../hooks/useFormatter";
import LoadingBar from "../../components/loading/LoadingBar";
//import { TbMathAvg } from "react-icons/tb";
import Spinner from "../../components/loading/Spinner";
import UserGroupIcon from "../../components/icons/UserGroupIcon";
import UsersIcon from "../../components/icons/UsersIcon";
import { useGlobalReach } from "../../hooks/useGlobalReach";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

function ReportReach() {
  const { id } = useParams();
  const { data: campaignData, isLoading: campaignLoading } =
    useSingleCampaignData(id);
  const { data: reachData, isLoading: reachLoading } = useReach(
    id,
    `?start=${campaignData?.start}&end=${campaignData?.end}`
  );
  const { data: reachGlobal, isLoading: reachGlobalLoading } = useGlobalReach(
    id,
    `?start=${campaignData?.start}&end=${campaignData?.end}`
  );
  const { decimalFormatter } = useFormatter();
  const [sumReach, setSumReach] = useState([]);
  const [sortedReach, setSortedReach] = useState([]);
  const [cumulativeReach, setCumulativeReach] = useState([]);

  useEffect(() => {
    if (
      !campaignLoading &&
      !reachLoading &&
      !reachGlobalLoading &&
      campaignData &&
      reachData &&
      reachGlobal
    ) {
      const reachChartData = [];
      for (const [key, value] of Object.entries(reachData.total)) {
        reachChartData.push({ date: key, reach: value });
      }
      let sortedReach = reachChartData.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      setSortedReach(sortedReach);
      const cummulativeReach = sortedReach.reduce((acc, current, index) => {
        // Der aktuelle Wert wird zur Gesamtsumme hinzugefügt
        const total =
          index === 0 ? current.reach : current.reach + acc[index - 1].sumReach;

        // Fügen Sie das neue Objekt mit Datum und kumulativer Summe hinzu
        acc.push({ date: current.date, sumReach: total });

        return acc;
      }, []);
      setSumReach(cummulativeReach);

      //Für den LineChart!
      //für die Grenzwerte end und startdatum in das richtige Format bringen
      var endDate = campaignData?.end;
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);
      endDate = new Date(endDate).toISOString().split("T")[0];

      var startDate = campaignData?.start;
      startDate = new Date(startDate);
      startDate.setDate(startDate.getDate() + 1);
      startDate = new Date(startDate).toISOString().split("T")[0];

      //Auf die Gesamtreichweite pro Tag zugreifen
      const id_key = Object.keys(reachGlobal?.adn)[0];
      const datenArray = Object.values(reachGlobal?.adn[id_key]);

      //Dictionary date: reach
      var transformedData = datenArray.reduce((acc, item) => {
        acc[item.day] = item.reach;
        return acc;
      }, {});

      //Nur die Daten zwischen den Grenzwerten ausgeben
      const shortData = Object.entries(transformedData).reduce(
        (acc, [key, value]) => {
          if (
            new Date(key) < new Date(endDate) &&
            new Date(key) >= new Date(startDate)
          ) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      //Array mit Objekten date: "datum" und reach: int
      const rightArray = Object.keys(shortData).map((key) => ({
        date: key,
        reach: shortData[key],
      }));

      setCumulativeReach(rightArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignLoading,
    reachLoading,
    reachGlobalLoading,
    campaignData,
    reachData,
    reachGlobal,
  ]);

  return (
    <>
      <div className="lg:grid grid-cols-3">
        <div className="grid grid-flow-row grid-rows-3 gap-4">
          <Card>
            <div className="flex items-center justify-between space-x-12 w-full h-full">
              <div>
                <div className="flex items-center">
                  <p className="text-sm text-gray-500 font-normal">
                    Gesamtreichweite{" "}
                  </p>
                  <div
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Anzahl der einzelnen Personen, 
die mit dieser Kampagne erreicht wurden
"
                  >
                    <BiInfoCircle className="h-6 w-6 ml-1 text-gray-500" />
                  </div>
                  <Tooltip
                    className="my-tooltip-class z-20"
                    //#111827 gray 900
                    //#6b7280 gray 500
                    style={{
                      backgroundColor: "rgba(254, 254, 254, 1.5)",
                      opacity: "100%",
                      color: "#111827",
                      fontSize: "12px",
                      borderRadius: "4px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    id="my-tooltip"
                  />
                </div>
                <div className="">
                  {campaignLoading || reachLoading || reachGlobalLoading ? (
                    <LoadingBar type="headline" height="h-9" width="w-32" />
                  ) : (
                    <p className="text-3xl font-normal">
                      {campaignData?.sum?.reach
                        ? decimalFormatter.format(campaignData?.sum?.reach)
                        : "-"}
                    </p>
                  )}
                </div>
              </div>
              <UserGroupIcon className="text-primary h-14 w-14" />
            </div>
          </Card>
          <Card>
            <div className="flex items-center justify-between w-full h-full">
              <div className="flex  items-center space-x-12">
                <div>
                  <div className="flex items-center">
                    <p className="text-sm font-normal text-gray-500">
                      Reichweiten-Abdeckung
                    </p>
                    <div
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html="Anteil der Einwohner im Gebiet, die mit der Kampagne erreicht wurden"
                    >
                      <BiInfoCircle className="h-6 w-6 ml-1 text-gray-500" />{" "}
                    </div>
                    <Tooltip
                      className="my-tooltip-class z-20"
                      //#111827 gray 900
                      //#6b7280 gray 500
                      style={{
                        backgroundColor: "rgba(254, 254, 254, 1.5)",
                        opacity: "100%",
                        color: "#111827",
                        fontSize: "12px",
                        borderRadius: "4px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                      id="my-tooltip"
                    />
                  </div>
                  {campaignLoading || reachLoading || reachGlobalLoading ? (
                    <LoadingBar type="headline" height="h-9" width="w-24" />
                  ) : (
                    <div className="text-3xl font-normal">
                      {campaignData?.geo?.sum?.sum_population
                        ? (
                            (campaignData?.sum?.reach /
                              campaignData?.geo?.sum?.sum_population ?? 1) * 100
                          )
                            ?.toFixed(0)
                            .replace(/\./g, ",")
                        : "-"}{" "}
                      %
                    </div>
                  )}
                </div>
              </div>
              <UsersIcon className="h-14 w-14 text-primary " />
            </div>
          </Card>
          <div className="flex justify-between space-x-3">
            {/*             <Card>
              <div className="flex items-center justify-between w-full h-full">
                <div className="flex  items-center space-x-12 z-40">
                  <div>
                    <div className="flex items-center">
                      <p className="text-sm font-normal text-gray-500">
                        Tagesreichweite
                      </p>
                    </div>
                    {campaignLoading || reachLoading || reachGlobalLoading ? (
                      <LoadingBar type="headline" height="h-9" width="w-24" />
                    ) : (
                      <p className="text-3xl font-normal">
                        {decimalFormatter.format(
                          (
                            sumReach.at(-1)?.sumReach / sumReach?.length ?? 1
                          )?.toFixed(0)
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <TbMathAvg className="h-11 w-11 text-primary " />
              </div>
            </Card> */}
            <Card>
              <div>
                <div className="flex items-center">
                  <p className="mt-4 text-sm font-normal text-gray-500">
                    Tagesreichweite
                  </p>
                  <div
                    className="mt-4"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Durchschnittlich erreichte Personen pro Tag"
                  >
                    <BiInfoCircle className="h-6 w-6 ml-1 text-gray-500" />{" "}
                  </div>
                  <Tooltip
                    className="my-tooltip-class z-20"
                    //#111827 gray 900
                    //#6b7280 gray 500
                    style={{
                      backgroundColor: "rgba(254, 254, 254, 1.5)",
                      opacity: "100%",
                      color: "#111827",
                      fontSize: "12px",
                      borderRadius: "4px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    id="my-tooltip"
                  />
                </div>
                {campaignLoading || reachLoading || reachGlobalLoading ? (
                  <LoadingBar type="headline" height="h-9" width="w-24" />
                ) : (
                  <p className="text-3xl font-normal">
                    {decimalFormatter.format(
                      (
                        sumReach.at(-1)?.sumReach / sumReach?.length ?? 1
                      )?.toFixed(0)
                    )}
                  </p>
                )}
              </div>
            </Card>
            <Card>
              {" "}
              <div>
                <div className="flex items-center">
                  <p className="mt-4 text-sm font-normal text-gray-500">
                    Reagiererquote
                  </p>
                  <div
                    className="mt-4"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Anteil der Personen, die auf ein Werbemittel der Kampagne geklickt haben im Verhältnis zu den insgesamt erreichten Personen"
                  >
                    <BiInfoCircle className="h-6 w-6 ml-1 text-gray-500" />{" "}
                  </div>
                  <Tooltip
                    className="my-tooltip-class z-20"
                    //#111827 gray 900
                    //#6b7280 gray 500
                    style={{
                      backgroundColor: "rgba(254, 254, 254, 1.5)",
                      opacity: "100%",
                      color: "#111827",
                      fontSize: "13px",
                      borderRadius: "4px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    id="my-tooltip"
                  />
                </div>
                {campaignLoading || reachLoading || reachGlobalLoading ? (
                  <LoadingBar type="headline" height="h-9" width="w-24" />
                ) : (
                  <p className="text-3xl font-normal">
                    {decimalFormatter.format(
                      (
                        (campaignData.sum.clicks / campaignData.sum.reach) *
                          100 ?? 1
                      )?.toFixed(2)
                    )}{" "}
                    %
                  </p>
                )}
              </div>
            </Card>
          </div>
        </div>

        <div className=" col-span-2 lg:pl-4 mt-4 lg:mt-0">
          <Card title={"Reichweitenzuwachs"}>
            {campaignLoading || reachGlobalLoading || reachLoading ? (
              <div className="flex items-center justify-center w-full h-[300px]">
                <Spinner />
              </div>
            ) : (
              <div className="pt-4 z-10">
                <LineChart data={cumulativeReach} />
              </div>
            )}
          </Card>
        </div>
      </div>
      <div className="mt-4">
        <Card title={"Tagesreichweite"} className={"w-full"}>
          {campaignLoading || reachLoading || reachGlobalLoading ? (
            <div className="flex items-center justify-center w-full h-[400px]">
              <Spinner />
            </div>
          ) : (
            <div className="pt-4">
              <DynamicBarChart data={sortedReach} />
            </div>
          )}
        </Card>
      </div>
    </>
  );
}

export default ReportReach;

import React, { useMemo } from "react";
import useFormatter from "../../../hooks/useFormatter";
import SummationFooter from "../../../components/tables/SummationFooter";
import { StarIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

const useBannerColumns = () => {
  const { decimalFormatter } = useFormatter();

  const columns = React.useMemo(
    () => [
      {
        Header: "Format",
        accessor: "title",
        Footer: <p className="font-bold">Gesamt</p>,
        Cell: ({ value }) => {
          if (value === "Billboard") {
            return (
              <div className="flex items-center">
                <span className="font-semibold">{value}</span>
                <StarIcon className="h-5 w-5 text-gray-500 ml-2" />
              </div>
            );
          } else if (value === "Mobile Medium-Rectangle") {
            return (
              <div className="flex items-center">
                <span className="font-semibold">{value}</span>
                <StarIcon className="h-5 w-5 text-gray-500 ml-2" />
              </div>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
      {
        Header: "Abmessungen",
        accessor: "width",
        Cell: ({ row, value }) => `${value}  x  ${row.original.height} Pixel`,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Anzahl von Klicks auf das Werbemittel"
            >
              Klicks
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "clicks",
        align: "right",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        Footer: ({ rows }) => <SummationFooter rows={rows} kpi="clicks" />,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Anzahl der Einblendungen des 
digitalen Werbemittels 
"
            >
              Sichtkontakte
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "impressions",
        align: "right",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        Footer: ({ rows }) => <SummationFooter rows={rows} kpi="impressions" />,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Das Verhältnis von Klicks zu Sichtkontakten
"
            >
              Klickrate
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "clicks",
        id: "clickrate",
        align: "right",
        Cell: ({ row, value }) =>
          value && row.original.impressions
            ? ((value / row.original.impressions) * 100)
                .toFixed(2)
                .replace(/\./g, ",") + " %"
            : "-",
        Footer: (info) => {
          const totalImps = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseInt(row?.values.impressions) + sum,
                0
              ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [info.rows]
          );
          const totalClicks = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseInt(row?.values.clicks) + sum,
                0
              ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [info.rows]
          );

          return (
            <p className="font-bold">
              {totalImps
                ? ((totalClicks / totalImps) * 100)
                    ?.toFixed(2)
                    .replace(/\./g, ",") + " %"
                : "-"}
            </p>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { columns };
};

export default useBannerColumns;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../img/stw3_logo.png";

const SuccessChange = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-full max-w-lg">
        <img
          className=" text-center text-2xl font-bold leading-9 mx-auto h-10 w-auto mb-8"
          src={Logo}
          alt="Stellwerk3 GmbH"
        />
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Ihr Passwort wurde erfolgreich geändert!
            </label>

            <div className="flex items-center justify-center">
              <button
                id="backToLogin"
                className="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleBackToLogin}
              >
                Zurück zum Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SuccessChange;

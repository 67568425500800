/* import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../img/stw3_logo.png";

const SetNewPassword = () => {
  const [setnewPassword, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate("/passwordReset/successReset");
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // hier Logik für das Zurücksetzen des Passworts
  };
  useEffect(() => {
    isValid();
  }, []);

  function getHashFromUrl() {
    const url = window.location.href;
    const hashIndex = url.lastIndexOf("/");
    const queryStringIndex = url.lastIndexOf("?");
    if (hashIndex !== -1 && queryStringIndex !== -1) {
      return url.substring(hashIndex + 1, queryStringIndex);
    }
    return null;
  }

  const hash = getHashFromUrl();

  const isValid = async () => {
    try {
      const hash = getHashFromUrl();
      const response = await fetch(
        `${apiUrl}/api/users/password-reset/${hash}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        return (
          <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="w-full max-w-lg">
              <img
                className=" text-center text-2xl font-bold leading-9 mx-auto h-20 w-auto mb-8"
                src={Logo}
                alt="Stellwerk3 GmbH"
              />
              <form
                onSubmit={handleSubmit}
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              >
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  ></label>

                  <div className="  ">
                    <span>Neues Passwort</span>
                    <input
                      className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="setnewPassword"
                      type="password"
                      placeholder="Neues Passwort"
                      value={setnewPassword}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className=" ">
                    <span className="">Passwort bestätigen</span>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="confirmPassword"
                      type="password"
                      placeholder="Passwort bestätigen"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    id="successReset"
                    className="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleSuccess}
                  >
                    Neues Passwort setzen
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      } else {
        return (
          <div className=" items-center">
            <p className=" text-4xl">Invalid Link</p>
          </div>
        );
      }
    } catch (error) {
      // Handle error case here
    }
  };
};

export default SetNewPassword; */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../img/stw3_logo.png";
import Card from "../../components/Card";

const SetNewPassword = () => {
  const [setnewPassword, setPassword] = useState("");
  const [password, setConfirmPassword] = useState("");
  const [isValidLink, setIsValidLink] = useState(null); // Zustand hinzufügen
  const apiUrl = process.env.REACT_APP_COREAPI_URL;
  const navigate = useNavigate();
  const { hash } = useParams();
  const handleSuccess = () => {
    navigate("/passwordReset/successReset");
  };
  const handleBackToLogin = () => {
    navigate("/login");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var alert_string = "";
    if (setnewPassword === password) {
      if (setnewPassword.length < 8) {
        alert_string =
          alert_string + "Das Passwort muss mindestens 8 Zeichen lang sein! ";
      }
      if (!/[a-z]/.test(setnewPassword)) {
        alert_string =
          alert_string + "\n In ihrem Passwort fehlt ein Kleinbuchstabe!";
      }
      if (!/[A-Z]/.test(setnewPassword)) {
        alert_string =
          alert_string + "\n In ihrem Passwort fehlt ein Großbuchstabe!";
      }
      if (!/[0-9]/.test(setnewPassword)) {
        alert_string = alert_string + "\n In ihrem Passwort fehlt eine Zahl!";
      }
      if (!/[^a-zA-Z0-9]/.test(setnewPassword)) {
        alert_string =
          alert_string + "\n In ihrem Passwort fehlt ein Sonderzeichen!";
      }
      if (
        setnewPassword.includes(
          "(" || ")" || "=" || "[" || "]" || "{" || "}" || "/"
        )
      ) {
        alert_string =
          alert_string +
          "\n Das Passwort darf keine der folgenden Zeichen enthalten: (, ), =, [, ], {, }, /";
      }
      if (alert_string !== "") {
        alert(alert_string);
        return;
      }
      try {
        const response = await fetch(`${apiUrl}/users/password-reset`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ hash, password }),
        });
        if (response.ok) {
          navigate("../successChange");
          // Handle success case here
        } else {
          const data = await response.json();
          const errorMessage = data.message;
          alert(`Fehler beim Zurücksetzen des Passworts: ${errorMessage}`);
        }
      } catch (error) {
        // Handle error case here
        console.error("Fehler beim Zurücksetzen des Passworts:", error);
        alert(`Fehler beim Zurücksetzen des Passworts: ${error.message}`);
      }
    } else {
      alert(
        "Die Passwörter stimmen nicht überein! Bitte überprüfen sie ihre Eingabe!"
      );
    }
  };

  useEffect(() => {
    const checkValidity = async () => {
      try {
        const response = await fetch(`${apiUrl}/users/password-reset/${hash}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        await response.json(); // Nehme an, dass du dies für etwas benötigst

        setIsValidLink(response.ok); // Zustand basierend auf der Antwort aktualisieren
      } catch (error) {
        console.error("Fehler bei der Überprüfung des hashes:", error);
        setIsValidLink(false);
      }
    };

    checkValidity();
  }, []);

  if (isValidLink === null) {
    return <div>Lädt...</div>; // Ladezustand anzeigen
  }

  if (!isValidLink) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="max-w-xl">
          <img
            className="text-center text-2xl font-bold leading-7 mx-auto h-10 w-auto mb-8"
            src={Logo}
            alt="Stellwerk3 GmbH"
          />
          <Card title={""}>
            <div className="flex flex-col items-left">
              <p className="text-left mb-4">
                <span className="font-semibold">Ungültiger Link</span>
                <br />
                <span className="text-md text-gray-500">
                  Der Link, um ihr Passwort zu ändern, ist abgelaufen oder
                  ungültig.
                </span>
                <br />
                <br />
                Wir können Ihr Passwort leider nicht zurücksetzen, da ein Fehler
                aufgetreten ist.
              </p>
              <br />
              <p className="text-left">
                <span className="font-semibold">
                  Falls Sie Fragen haben, helfen wir Ihnen gerne weiter!
                </span>
                <br />
                bsp@email.com <br /> 01717273493729
              </p>
              <div className="flex justify-center mt-6">
                <button
                  id="backToLogin"
                  className="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handleBackToLogin}
                >
                  Zurück zum Login
                </button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  // Nun wird dieser Teil gerendert, wenn isValidLink true ist
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full max-w-lg">
        <img
          className=" text-center text-2xl font-bold leading-9 mx-auto h-10 w-auto mb-8"
          src={Logo}
          alt="Stellwerk3 GmbH"
        />
        <form onSubmit={handleSubmit} className="px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm text-left font-semibold mb-4"
              htmlFor="password"
            >
              Hier können sie ihr Passwort zurück setzen. Bitte geben sie
              zweimal ihr neues gewünschtes Passwort ein <br />
              <br />
              Anforderungen an das Passwort:
              <span className="font-normal">
                <br />
                - Mindestens 8 Zeichen lang
                <br /> - Mindestens ein Großbuchstabe und ein Kleinbuchstabe
                <br />- Mindestens eine Zahl
                <br />- Mindestens eins dieser Sonderzeichen:
                <br />
                {"  "} "@","$","!","%","*","#","?","&","_","-",",".",":",";"+"
              </span>
            </label>

            <div className="  ">
              <span>Neues Passwort</span>
              <input
                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                id="setnewPassword"
                type="password"
                placeholder="Neues Passwort"
                value={setnewPassword}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="my-3 ">
              <span className="">Passwort bestätigen</span>
              <input
                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                id="confirmPassword"
                type="password"
                placeholder="Passwort bestätigen"
                value={password}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              id="successReset"
              className="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={handleSubmit}
            >
              Neues Passwort setzen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetNewPassword;

import React, { useContext } from "react";
import { useCampaignData } from "../hooks/useCampaignData";
import AuthContext from "../contexts/AuthContext";
import CampaignList from "./campaigns/campaignComponents/CampaignList";
import { useBookingData } from "../hooks/useBookingData";
import BookingList from "./bookings/bookingComponents/BookingList";
import WelcomeBox from "../components/WelcomeBox";
import Spinner from "../components/loading/Spinner";
import Card from "../components/Card";

const DisplayCampaigns = () => {
  const { auth } = useContext(AuthContext);

  const { data: allCampaigns, isLoading: allCampaignsLoading } =
    useCampaignData(auth.token, "perPage=1000");

  const allDisplayCampaigns = allCampaigns?.data?.filter(
    (campaign) => campaign.seaOnly !== true
  );
  const { data: bookings, isLoading: isLoadingBookings } = useBookingData(
    auth.token,
    auth.user.customer_id
  );
  return (
    <>
      <div className="mt-8">
        <WelcomeBox
          title={`Ihre Display Kampagnen`}
          description={`Hier sehen Sie all Ihre Display Kampagnen und deren wichtigsten Kennzahlen. Melden Sie sich gerne, falls Sie Fragen haben.`}
        />
        <h1 className="font-bold text-xl mt-8">Ihre Display Kampagnen</h1>
        {allCampaignsLoading && (
          <Card className="mt-4 flex items-center px-6 py-6 bg-white animate-pulse">
            <div className="w-1/3">
              <div className="h-4 w-24 leading-6 rounded-lg bg-slate-200 mb-3" />
              <div className="mt-1 h-3 w-32 leading-5 rounded-lg bg-slate-200" />
            </div>
            <div className="flex w-1/3 items-center justify-center">
              <Spinner />
              <p className="text-slate-700 text-sm ml-2">
                Prüfe auf Display Kampagnen...
              </p>
            </div>
          </Card>
        )}
        {allDisplayCampaigns?.length === 0 && bookings?.length === 0 ? (
          <Card className="mt-4 flex justify-center items-center px-6 py-9 bg-white">
            <div>
              <p className="text-sm leading-6 text-gray-400">
                Sie haben derzeit keine Display Kampagnen.
              </p>
            </div>
          </Card>
        ) : (
          <>
            {bookings?.length > 0 && (
              <div className="mt-4">
                <BookingList
                  bookings={bookings?.filter((booking) => !booking.is_sea)}
                  isLoading={isLoadingBookings}
                />
              </div>
            )}
            {allDisplayCampaigns?.length > 0 && (
              <div className="mt-4">
                <CampaignList
                  campaigns={allDisplayCampaigns ?? []}
                  isLoading={allCampaignsLoading}
                  seaOnly={"true"}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DisplayCampaigns;

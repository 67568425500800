import React, { useContext } from "react";
import PenEditIcon from "../../../components/icons/PenEditIcon";
import AuthContext from "../../../contexts/AuthContext";

const SeaAdPreview = ({ url, headlines, descriptions, onEdit, status }) => {
  const { auth } = useContext(AuthContext);
  return (
    <>
      <button
        disabled={!auth?.isAuthenticated || status !== "in_coordination"}
        className={`text-sm border border-transparent ${onEdit && auth?.isAuthenticated && status === "in_coordination" ? "hover:border-primary" : ""
          } rounded-lg p-3 pb-0 group w-full text-left relative`}
        onClick={status === "in_coordination" && onEdit ? onEdit : null}
      >
        {onEdit && auth?.isAuthenticated && status == "in_coordination" && (
          <div className="absolute right-3 top-3 invisible group-hover:visible">
            <PenEditIcon className={"h-5 w-5 text-primary"} />
          </div>
        )}
        <div className="flex items-center space-x-2">
          <p className="font-bold ">Anzeige</p>
          <div className="rounded-lg bg-black p-0.5" />
          <a
            className=" hover:underline"
            target="_blank"
            href={url}
            rel="noreferrer"
          >
            {url}
          </a>
        </div>

        <p className="text-[#1a0eab] text-base ">{headlines}</p>
        <p className="text-[#434343] whitespace-pre-line">{descriptions}</p>
      </button>
    </>
  );
};

export default SeaAdPreview;

import React from "react";
import VendorCard from "./VendorCard";

const WelcomeBox = ({ title, description }) => {
  return (
    <div className="grid grid-cols-3 justify-between gap-8">
      <div className="grid grid-cols-2 col-span-2 overflow-hidden shadow-lg sm:rounded  border-stw3-gray-200 bg-white p-8">
        <div className="col-span-2">
          <h1 className="font-bold text-xl">
            {title}
          </h1>
          <p className="text-sm text-gray-600 mt-2 whitespace-pre-line">
            {description}
          </p>
        </div>
      </div>
      <VendorCard />
    </div>
  );
};

export default WelcomeBox;

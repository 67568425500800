import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useCampaignData } from "../../hooks/useCampaignData";
import CampaignList from "./campaignComponents/CampaignList";

const FinishedCampaigns = () => {
  const { auth } = useContext(AuthContext);
  const { data: campaigns, isLoading } = useCampaignData(
    auth.token,
    "status=finished"
  );

  return (
    <CampaignList
      campaigns={campaigns?.data ?? []}
      isLoading={isLoading}
      campaignStatus={"finished"}
    />
  );
};

export default FinishedCampaigns;

import React, { useMemo } from "react";
import useFormatter from "../../../hooks/useFormatter";
import moment from "moment";
import SummationFooter from "../../../components/tables/SummationFooter";
import { TbMathAvg } from "react-icons/tb";
import { Tooltip } from "react-tooltip";

const useDailyColums = () => {
  const { decimalFormatter } = useFormatter();

  const dailycolumns = React.useMemo(
    () => [
      {
        Header: "Datum",
        accessor: "day",
        Cell: ({ value }) => moment(value).format("DD.MM.YYYY"),
        Footer: () => <p className="font-bold text-sm">Gesamt</p>,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Anzahl von Klicks auf das Werbemittel"
            >
              Klicks
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "clicks",
        align: "right",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        Footer: ({ rows }) => <SummationFooter rows={rows} kpi="clicks" />,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Das Verhältnis von Klicks zu Sichtkontakten
"
            >
              Klickrate
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "ctr",
        align: "right",
        Cell: ({ value }) =>
          value ? value.toFixed(2).replace(/\./g, ",") + " %" : "-",
        Footer: ({ rows }) => {
          const total = useMemo(
            () =>
              rows.reduce((sum, row) => parseFloat(row?.values.ctr) + sum, 0),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [rows]
          );
          return (
            <p className=" font-bold">
              {total
                ? (total / rows.length)?.toFixed(2).replace(/\./g, ",") + " %"
                : "-"}
            </p>
          );
        },
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Anzahl der Einblendungen des 
digitalen Werbemittels 
"
            >
              Sichtkontakte
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "impressions",
        align: "right",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        Footer: ({ rows }) => <SummationFooter rows={rows} kpi="impressions" />,
      },
      {
        Header: () => (
          <div>
            <a
              className=" z-0"
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Anzahl der einzelnen Personen, die mit der Kampagne erreicht wurden"
            >
              Tagesreichweite
            </a>
            <Tooltip
              className="my-tooltip-class z-20"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </div>
        ),
        accessor: "reach",
        align: "right",
        Cell: ({ value }) => (value ? decimalFormatter.format(value) : "-"),
        //Footer: ({ rows }) => <SummationFooter rows={rows} kpi="reach" />,
        Footer: ({ rows }) => {
          const total = useMemo(
            () =>
              rows.reduce((sum, row) => parseFloat(row?.values.reach) + sum, 0),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [rows]
          );
          return (
            <div className="flex justify-end font-bold">
              <TbMathAvg className="h-5 w-4 mr-1" />
              <span>
                {total
                  ? decimalFormatter.format((total / rows.length)?.toFixed(0))
                  : "-"}{" "}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { dailycolumns };
};

export default useDailyColums;

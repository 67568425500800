import { useEffect, useState } from "react";

const useSnippets = (snippetsBookingParameters) => {
  const [groupedSnippets, setGroupedSnippets] = useState({});

  useEffect(() => {
    let grouped = Object.groupBy(
      snippetsBookingParameters,
      ({ position }) => position
    );
    setGroupedSnippets(grouped);
  }, [snippetsBookingParameters]);

  return { groupedSnippets };
};

export default useSnippets;

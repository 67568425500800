import React from "react";
import Logo from "../img/stw3_logo.png";
import { Disclosure } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import {
  EnvelopeIcon,
  PhoneArrowDownLeftIcon,
} from "@heroicons/react/24/outline";
import Card from "../components/Card";

const Impressum = () => {
  const location = useLocation();
  const showDisclosure = location.pathname.startsWith("/dashboard");

  return (
    <>
      {!showDisclosure && (
        <Disclosure className="border-b-0 shadow-sm">
          <div className="h-20">
            <a href="/login">
              <img
                src={Logo}
                alt="Firmenlogo"
                className="mb-6 ml-24 w-40 h-20 object-contain"
              />
            </a>
          </div>
        </Disclosure>
      )}
      <div className="mt-12 mt text-left w-full max-w-7xl">
        {!showDisclosure ? (
          <div className="p-6 ml-20 w-full max-w-7xl ">
            <h1 className="text-2xl font-bold mb-4">Impressum</h1>
            <p className="mb-2 font-medium text-lg">Stellwerk3 GmbH</p>
            <p className="mb-4 text-md">
              Unter den Linden 4 <br></br>
              72762 Reutlingen
            </p>
            <div className="mb-1 text-lg flex space-x-2">
              <PhoneArrowDownLeftIcon className="h-6 w-5" />{" "}
              <span>+49 (0) 7121 994490</span>
            </div>
            <p className="mb-4 text-lg flex space-x-2">
              <EnvelopeIcon className="h-8 w-6" />{" "}
              <span className="pt-1">info@stellwerk3.de</span>
            </p>
            <p className="mb-1 text-md">Amtsgericht Stuttgart HRB 751593</p>
            <p className="mb-1 text-md">UST-ID: DE298687455</p>
            <p className="mb-1 text-md">Geschäftsführer: Armin Hipp</p>
          </div>
        ) : (
          <Card>
            <div className="w-full p-2">
              <h1 className="text-2xl font-bold mb-4">Impressum</h1>
              <p className="mb-2 font-medium text-lg">Stellwerk3 GmbH</p>
              <p className="mb-4 text-md">
                Unter den Linden 4, <br></br>
                72762 Reutlingen
              </p>
              <div className="mb-1 text-lg flex space-x-2">
                <PhoneArrowDownLeftIcon className="h-6 w-5" />{" "}
                <span>+49 (0) 7121 994490</span>
              </div>
              <p className="mb-4 text-md flex space-x-2">
                <EnvelopeIcon className="h-8 w-6" />{" "}
                <span className="pt-1">info@stellwerk3.de</span>
              </p>
              <p className="mb-1 text-md">Amtsgericht Stuttgart HRB 751593</p>
              <p className="mb-1 text-md">UST-ID: DE298687455</p>
              <p className="mb-1 text-md">Geschäftsführer: Armin Hipp</p>
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default Impressum;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import { useContext } from "react";

const Footer = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <footer>
      <div className="mx-auto max-w-7xl px-4 xl:px-0">
        <div
          className={`flex h-16 ${
            auth.isAuthenticated ? "justify-end" : "justify-center"
          } text-sm`}
        >
          <Link
            to={auth.isAuthenticated ? `/dashboard/impressum` : `/impressum`}
            className="text-gray-500 mr-2"
          >
            Impressum
          </Link>
          <Link
            to={auth.isAuthenticated ? `/dashboard/privacy` : `/privacy`}
            className="text-gray-500 ml-2"
          >
            Datenschutz
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

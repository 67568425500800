import React, { useState } from "react";
import PropTypes from "prop-types";
import { Combobox } from "@headlessui/react";
import Spinner from "../loading/Spinner";
import { ImSad } from "react-icons/im";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const ComboboxWithDataFetch = ({
  onSearch,
  options,
  inputDefault,
  onSelect,
  loading,
}) => {
  const [value, setValue] = useState();
  const handleSelect = (option) => {
    setValue(option);
    onSelect(option.value);
  };
  return (
    <Combobox value={value?.label} onChange={handleSelect}>
      <div className="relative">
        <Combobox.Input
          placeholder={inputDefault}
          onChange={(event) => onSearch(event.target.value)}
          className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md text-black"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
      </div>
      <Combobox.Options className="absolute mt-1 max-h-60 z-50  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm w-full">
        {!loading &&
          options.map((option) => (
            <Combobox.Option
              key={option.value}
              value={option}
              className={({ active }) =>
                `cursor-default select-none py-2  flex items-center justify-between px-4 text-black ${
                  active ? "bg-axWarmgray-50" : ""
                }`
              }
            >
              <div className="flex justify-between space-x-4 items-center w-full">
                <p>{option.label}</p>
                {option.badge ?? <></>}
              </div>
            </Combobox.Option>
          ))}
        {loading && (
          <div className="flex items-center justify-center p-4 bg-white">
            <Spinner />
          </div>
        )}
        {!loading && options.length === 0 && (
          <div className=" p-4 bg-white">
            <div className="flex justify-center w-full">
              <ImSad className="h-6 w-6 text-axColdgray-500" />
            </div>
            <div className="text-center mt-4 text-axColdgray-500 text-sm ">
              <p>Keine Einträge gefunden</p>
            </div>
          </div>
        )}
      </Combobox.Options>
    </Combobox>
  );
};

export default ComboboxWithDataFetch;

ComboboxWithDataFetch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  inputDefault: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

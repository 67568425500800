import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useChannelData } from "../../hooks/useChannelData";
import { useSingleCampaignData } from "../../hooks/useSingleCampaignData";
import useFormatter from "../../hooks/useFormatter";
import UsersIcon from "../../components/icons/UsersIcon";
import CampaignmapWithStats from "../../components/maps/CampaignMapWithStats";
//import InformationCircle from "../../components/icons/InformationCircle";
import Card from "../../components/Card";
import CreativeCarousel from "../../components/creatives/CreativeCarousel";
import Spinner from "../../components/loading/Spinner";
import LoadingBar from "../../components/loading/LoadingBar";
//import MotiveImageCard from "./components/MotiveImageCard";
import Button from "../../components/buttons/Button";
import CreativePreviewModal from "../../components/modals/CreativePreviewModal";
import UserGroupIcon from "../../components/icons/UserGroupIcon";
import EyeIcon from "../../components/icons/EyeIcon";
import CursorClickIcon from "../../components/icons/CursorClickIcon";
import ClickrateIcon from "../../components/icons/Clickrate";
import ReachProgressLine from "../../components/ReachProgressLine";
import { Tooltip } from "react-tooltip";
//import InfoPopup from "../../components/InfoPopup";
import { BiInfoCircle } from "react-icons/bi";

const Overview = () => {
  const { id } = useParams();
  const { decimalFormatter } = useFormatter();
  const { data: channelData, isLoading: channelDataLoading } =
    useChannelData(id);
  const { data: campaignData, isLoading: campaignLoading } =
    useSingleCampaignData(id);
  const [creatives, setCreatives] = useState([]);
  const [selectedCreativeIndex, setSelctedCreativeIndex] = useState(0);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const stats = [
    {
      name: "Sichtkontakte",
      icon: EyeIcon,
      stat: campaignData?.sum?.impressions
        ? decimalFormatter.format(campaignData.sum.impressions)
        : "-",
      tooltext: "Anzahl der Einblendungen des digitalen Werbemittels",
    },
    {
      name: "Kontaktdosis",
      icon: UsersIcon,
      stat:
        campaignData?.sum?.reach && campaignData?.sum?.impressions
          ? (campaignData.sum.impressions / campaignData.sum.reach)
              ?.toFixed(2)
              .replace(/\./g, ",")
          : "-",
      tooltext:
        "Durchschnittliche Anzahl der Sichtkontakte einer Person mit der Kampagne",
    },
    {
      name: "Klicks",
      icon: CursorClickIcon,
      stat: campaignData?.sum?.clicks
        ? decimalFormatter.format(campaignData.sum.clicks)
        : "-",
      tooltext: "Anzahl von Klicks auf das Werbemittel",
    },
    {
      name: "Reagiererquote",
      icon: ClickrateIcon,
      stat:
        campaignData?.sum?.clicks && campaignData?.sum?.impressions
          ? decimalFormatter.format(
              (
                (campaignData.sum.clicks / campaignData.sum.reach) * 100 ?? 1
              )?.toFixed(2)
            ) + " %"
          : "-",

      tooltext:
        "Anteil der Personen, die auf ein Werbemittel der Kampagne geklickt haben im Verhältnis zu den insgesamt erreichten Personen",
    },
  ];

  useEffect(() => {
    if (channelData) {
      let ads = channelData?.channels.display.adgroups.flatMap((adgroup) => {
        return adgroup.ads;
      });
      setCreatives(ads.sort((a, b) => a.title?.localeCompare(b.title)));
    }
  }, [channelData]);

  return (
    <>
      {/*       <InfoPopup
        startDate={campaignData?.start}
        infoText={
          "Ihre Kampagne ist heute planmäßig gestartet und wird bereits in Ihrem Geschäftsgebiet ausgespielt. Ab morgen werden Ihnen hier dann täglich die aktuellen Zahlen und Ergebnisse angezeigt."
        }
      /> */}
      <div className="lg:grid lg:grid-cols-3 gap-4 justify-items-stretch">
        {/* <div className="gap-4 grid lg:grid-flow-row sm:grid-rows-0 lg:grid-rows-3 sm:grid-cols-2 lg:grid-cols-1 col-span-3 lg:col-span-1 "> */}
        {/*         <div className="gap-4 grid lg:grid-flow-row sm:grid-rows-0 lg:grid-rows-3 sm:grid-cols-2 lg:grid-cols-1 col-span-3 lg:col-span-1 ">
          <div className="sm:row-span-2 lg:row-span-1  h-full w-full">
            <Card className={"h-full w-full bg-primary "}>
              <div className="flex h-full w-full ">
                <div className="flex items-center  justify-between w-full">
                  <div>
                    <p className="text-base text-stw3-gray-400 "> Reichweite</p>
                    {campaignLoading ? (
                      <LoadingBar type="headline" height="h-9" width="w-32" />
                    ) : (
                      <p className="text-4xl text-white font-normal">
                        {campaignData?.sum?.reach
                          ? decimalFormatter.format(campaignData?.sum?.reach)
                          : "-"}
                      </p>
                    )}
                  </div>

                  <UserGroupIcon className=" text-white h-16 w-16" />
                </div>
              </div>
            </Card>
          </div> */}

        <div className="gap-4 grid lg:grid-flow-row sm:grid-rows-0 sm:grid-cols-2 lg:grid-cols-1 col-span-3 lg:col-span-1 ">
          <div className="sm:row-span-2 lg:row-span-1  h-full w-full">
            {/*< Card>
              <div className="flex justify-between">
                <div>
                  {campaignLoading ? (
                    <LoadingBar type="headline" height="h-8" width="w-32" />
                  ) : (
                    <dd className=" text-20 tracking-wider text-gray-500">
                      Kampagnenreichweite
                      <dt className="flex justify-between text-3xl font-normal mb-3 text-gray-900">
                        {campaignData?.sum?.reach
                          ? decimalFormatter.format(campaignData?.sum?.reach)
                          : "-"}{" "}
                        <UserGroupIcon className=" text-primary h-16 w-16" />
                      </dt>
                    </dd>
                  )}
                </div>
              </div>
            </Card> */}

            <Card className={"h-full w-full bg-white "}>
              <div className="flex items-center h-full w-full ">
                <div className="grid grid-rows-2 items-center h-full w-full">
                  <div className="mt-10 z-20">
                    <div className="flex items-center">
                      <p className="text-24 text-gray-500 tracking-wider font-light">
                        Kampagnenreichweite
                      </p>
                      <a
                        className=" z-0"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-html="Anzahl der einzelnen Personen, 
die mit der Kampagne erreicht wurden
"
                      >
                        {/*Die <b> Reichweite</b> <br> gibt den Anteil der
				Zielpersonen an, die durch einen <br>Werbeträger oder durch eine
				Werbeträgerkombination erreicht werden. <br> <br> Unsere <b>Kampagnenreichweite</b> <br>entspricht der Nettoreichweite und ist die tatsächliche Reichweite
				einer <br> Werbekampagne ohne mögliche Überschneidungen. Alle relevanten <br>
				Zielpersonen werden während der Kampagnenlaufzeit nur einmalig <br>
				erfasst (Unique User). Personen, die die Werbung mehrfach sehen,
				werden ausgefiltert. */}
                        <BiInfoCircle className="h-6 w-6 ml-1 text-gray-500" />{" "}
                      </a>
                    </div>
                    <Tooltip
                      className="my-tooltip-class z-20"
                      //#111827 gray 900
                      //#6b7280 gray 500
                      style={{
                        backgroundColor: "rgba(254, 254, 254, 1.5)",
                        opacity: "100%",
                        color: "#111827",
                        fontSize: "12px",
                        borderRadius: "4px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                      id="my-tooltip"
                    />

                    <dt className="grid grid-cols-2 justify-between items-center">
                      {campaignLoading ? (
                        <LoadingBar type="headline" height="h-9" width="w-32" />
                      ) : (
                        <dd className="text-5xl font-normal">
                          {campaignData?.sum?.reach
                            ? decimalFormatter.format(campaignData?.sum?.reach)
                            : "-"}{" "}
                        </dd>
                      )}
                      <dd className=" ml-10">
                        {" "}
                        <UserGroupIcon className="   h-20 w-20" />
                      </dd>
                    </dt>
                  </div>

                  <div className="mb-5">
                    <ReachProgressLine
                      reach={campaignData?.sum?.reach}
                      population={campaignData?.geo?.sum?.sum_population}
                      isLoading={campaignLoading}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>

          {/*           <div className=" sm:row-span-3 rounded   lg:row-span-2 h-44 lg:h-full">
            <MotiveImageCard
              isLoading={campaignLoading}
              motiveName={campaignData?.motive?.name}
              motiveUrl={campaignData?.motive?.image}
            />
                          </div>*/}
        </div>
        <div className="col-start-1 col-span-3 lg:col-span-2 mt-4 lg:mt-0">
          <CampaignmapWithStats mapHeight={250} />
        </div>
      </div>
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4 py-4 pt-5">
        {stats.map((item) => (
          <Card className="z-10">
            <div className="flex relative h-full w-full ">
              <div className="flex relative items-center  justify-between w-full">
                <dt className="truncate relative text-sm  text-gray-500">
                  <p className="relative flex items-center">
                    {item.name}
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html={item.tooltext}
                      className="ml-1"
                    >
                      <BiInfoCircle className="h-5 w-5  text-gray-500" />{" "}
                    </a>
                  </p>

                  {campaignLoading ? (
                    <LoadingBar height="h-10" width="w-32" />
                  ) : (
                    <dd className="relative z-10 mt-1 text-3xl font-medium text-gray-900 tracking-tight ">
                      {item.stat}{" "}
                    </dd>
                  )}
                </dt>
                <dt className="truncate relative z-10 text-sm font-medium ">
                  {item.icon && (
                    <item.icon
                      className=" text-primary mr-4 h-12 w-12"
                      aria-hidden="true"
                    />
                  )}
                </dt>
              </div>
            </div>
          </Card>
        ))}
      </dl>
      <div className="mt-1">
        <Card>
          <div className="lg:grid  grid-cols-4 gap-4 lg:divide-x divide-stw3-gray-200">
            <div className="col-span-3 ">
              {channelDataLoading ? (
                <div className="flex items-center justify-center w-full h-full">
                  <LoadingBar height="h-5" width="w-12" />
                  <Spinner />
                </div>
              ) : (
                <CreativeCarousel
                  onClick={() =>
                    setSelectedCreative(creatives[selectedCreativeIndex])
                  }
                  creatives={creatives}
                  onChange={setSelctedCreativeIndex}
                />
              )}
            </div>
            <div className="sm:pl-12 h-full flex items-center">
              <div className="w-full">
                <div className="p-12 lg:p-4 xl:p-12  w-full">
                  <p className="text-sm text-gray-500 font-normal">Format</p>
                  {channelDataLoading ? (
                    <LoadingBar height="h-5" width="w-12" />
                  ) : (
                    <p className="text-sm">
                      {creatives.at(selectedCreativeIndex)?.title}
                    </p>
                  )}
                  <div className="mt-4">
                    <p className="text-sm text-gray-500 font-normal">
                      Abmessungen
                    </p>
                    {channelDataLoading ? (
                      <LoadingBar height="h-5" width={"w-32"} />
                    ) : (
                      <p className="text-sm">
                        {creatives.at(selectedCreativeIndex)?.width} x{" "}
                        {creatives.at(selectedCreativeIndex)?.height} px
                      </p>
                    )}
                  </div>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500 font-normal">
                      Sichtkontakte
                    </p>
                    {channelDataLoading ? (
                      <LoadingBar height="h-9" width={"w-18"} type="headline" />
                    ) : (
                      <p className="text-3xl font-normal">
                        {decimalFormatter.format(
                          creatives.at(selectedCreativeIndex)?.impressions
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      {selectedCreative && (
        <CreativePreviewModal
          creative={selectedCreative}
          onClose={() => setSelectedCreative(null)}
        />
      )}
    </>
  );
};

export default Overview;

import React from "react";

const LoadingBar = ({ height, width, type, className }) => {
  const getColorByType = () => {
    switch (type) {
      case "headline":
        return "bg-gray-900 ";
      case "paragraph":
        return "bg-gray-500 ";
      default:
        return "bg-gray-500 ";
    }
  };

  return (
    <div className={`opacity-30 ${className && className}`}>
      <div
        className={`${height} ${width} rounded ${getColorByType()} animate-pulse `}
      ></div>
    </div>
  );
};

export default LoadingBar;

import { useEffect, useState } from "react";
import useScreenSize from "./useScreenSize";

const useResponsiveCharts = (dataCount) => {
  const { isWindowSmallerThan, windowHeight, windowWidth } = useScreenSize();

  const [intervalCount, setIntervalCount] = useState(0);

  useEffect(() => {
    if (isWindowSmallerThan("md") && dataCount > 7) {
      setIntervalCount(1);
    } else if (isWindowSmallerThan("xl") && dataCount > 20) {
      setIntervalCount(1);
    } else {
      setIntervalCount(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowHeight, windowWidth, dataCount]);

  return { intervalCount };
};

export default useResponsiveCharts;

import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Card from "../../components/Card";

const OfferList = ({ offers, customerName, isLoading }) => {
  return (
    <ul className="space-y-4">
      {offers?.map((offer) => (
        <li key={offer.hash} className="on hover">
          <Link
            to={`/offer/${offer.hash}`}
            className="w-full flex min-w-0 gap-x-4"
          >
            <Card>
              <div className="flex justify-between ">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {offer.offer_number}_{customerName.replace(/\s/g, "")}_
                    {moment(offer.created_at).format("DDMMYYYY")}
                  </p>
                  <p className="mt-1 flex text-sm leading-5 text-gray-500">
                    {moment(offer.created_at).format("DD. MMMM YYYY")}
                  </p>
                </div>

                <div className="flex gap-x-12">
                  <div className="flex shrink-0 items-center gap-x-4 w-44 justify-items-end">
                    {offer.status === "accepted" ? (
                      <>
                        <div className="bg-green-200 text-green-900 rounded-md px-9 py-1.5 text-sm font-semibold  shadow-sm ">
                          Angenommen
                        </div>
                      </>
                    ) : (
                      <div className="bg-primary text-white rounded-md px-6 py-1.5 text-sm font-semibold  shadow-sm ">
                        Angebot ansehen
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default OfferList;

import React from "react";
import ComboboxWithDataFetch from "./inputs/ComboboxWithDataFetch";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { getCustomerList } from "../hooks/useCustomer";
import Badge from "./badges/Badge";
import Button from "./buttons/Button";
import { updateUser } from "../hooks/useUserData";

const AdminPanel = () => {
  const { auth } = useContext(AuthContext);
  const [customerInput, setCustomerInput] = useState(
    auth?.user?.customer?.company
  );
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  useEffect(() => {
    setLoading(true);
    let handler = setTimeout(() => {
      getCustomerList(auth.token, customerInput).then((res) => {
        setCustomers([...res.masters, ...res.customers]);
        setLoading(false);
      });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInput]);
  const handleUpdateProfile = () => {
    updateUser(auth.token, auth.user.user.id, {
      customer_id: selectedCustomerId,
    }).then((res) => {
      window.location.reload();
    });
  };
  return (
    <div>
      <p className="mb-1 text-axColdgray-500 text-sm">Admin Kunde wechseln</p>
      <ComboboxWithDataFetch
        loading={loading}
        onSelect={(value) => {
          setSelectedCustomerId(value);
          //setSelectedBrandId(null);
        }}
        options={customers.map((customer) => {
          return {
            label: customer.company,
            value: customer.id,
            badge: customer.is_master ? (
              <Badge color="red" text="master" variant={"extrasmall"} />
            ) : null,
          };
        })}
        inputDefault={
          auth.user?.is_brandspace_user ? "-" : auth?.user?.customer?.company
        }
        onSearch={setCustomerInput}
      />
      <div className="pt-2">
        <Button
          onClick={handleUpdateProfile}
          disabled={!selectedCustomerId}
          label="Ansicht wechseln"
          className={"w-full text-xs"}
        />
      </div>
    </div>
  );
};

export default AdminPanel;

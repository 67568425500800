import moment from "moment";
import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CampaignStatusBadge from "../../../components/badges/CampaignStatusBadge";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

const CampaignList = ({ campaigns, isLoading, campaignStatus }) => {
  //const { decimalFormatter } = useFormatter();

  const navigate = useNavigate();

  // Neuer State für den ausgewählten Filter
  //const [selectedFilter, setSelectedFilter] = useState("alle");
  const [isSeaOnlyChecked, setIsSeaOnlyChecked] = useState(false);
  const [isDisplayOnlyChecked, setIsDisplayOnlyChecked] = useState(false);

  useEffect(() => {
    const hasRedirected = sessionStorage.getItem("hasRedirected");

    if (!hasRedirected) {
      // Prüft ob dies der erste Render ist
      if (!isLoading && campaigns.length === 0 && campaignStatus === "active") {
        navigate("/campaigns/finished");
        sessionStorage.setItem("hasRedirected", "true");
      }
    }
  }, [campaigns, navigate]);

  const filteredCampaigns = campaigns.filter((campaign) => {
    if (!isSeaOnlyChecked && !isDisplayOnlyChecked) return true;
    if (isSeaOnlyChecked && campaign.seaOnly) return true;
    if (isDisplayOnlyChecked && !campaign.seaOnly) return true;
    return false;
  });

  return (
    <>
      <ul className="space-y-4 overflow-hidden pb-5">
        {filteredCampaigns.map((campaign) => (
          <li
            key={campaign.id}
            className="flex justify-between items-center px-6 py-5 bg-white hover:bg-gray-50 border-stw3-gray-200 shadow-lg sm:rounded"
          >
            <Link
              to={
                campaign?.seaOnly
                  ? `/campaigns/sea/${campaign.id}`
                  : `/campaigns/${campaign.id}`
              }
              className="w-full flex min-w-0 gap-x-4"
            >
              <div
                className="h-12 w-12 rounded-full border border-gray-200 bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: campaign?.seaOnly
                    ? `url(/img/google-logo-240-be.png)`
                    : `url(${campaign?.motive?.image})`,

                  backgroundSize: campaign?.seaOnly ? "70%" : "",
                }}
                alt=""
              ></div>
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {campaign.name}
                </p>
                <p className="mt-1 flex text-sm leading-5 text-gray-500">
                  {moment(campaign.start).format("DD.MM.YYYY")} -{" "}
                  {moment(campaign.end).format("DD.MM.YYYY")}
                </p>
              </div>
            </Link>
            <div className="flex gap-x-12">
              <div className="flex gap-x-12">
                <div>
                  <p className="text-sm font-semibold text-gray-900 text-right">
                    {campaign?.seaOnly
                      ? campaign?.performance?.clicks.toLocaleString("de-DE", {
                          maximumFractionDigits: 0,
                        })
                      : campaign?.performance?.reach
                      ? campaign.performance.reach.toLocaleString("de-DE", {
                          maximumFractionDigits: 0,
                        })
                      : "-"}
                  </p>
                  <p className="text-sm text-gray-500">
                    {campaign?.seaOnly ? " Klicks" : " Reichweite"}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-semibold text-gray-900 text-right">
                    {campaign?.performance?.impressions
                      ? campaign.performance.impressions.toLocaleString(
                          "de-DE",
                          { maximumFractionDigits: 0 }
                        )
                      : "-"}
                  </p>
                  <p className="text-sm text-gray-500">Sichtkontakte</p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-4 w-32 justify-items-end">
                <CampaignStatusBadge
                  status={campaign.status}
                  startDate={campaign.start}
                />
                <Link
                  to={
                    campaign?.seaOnly
                      ? `/campaigns/sea/${campaign.id}`
                      : `/campaigns/${campaign.id}`
                  }
                >
                  <ChevronRightIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CampaignList;

import React, { useContext } from "react";
import Card from "../../components/Card";
import { useKeywords } from "../../hooks/useCampaignData";
import { useParams, useSearchParams } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import Table from "../../components/Table";
import useKeywordTableColumns from "./tableColumns/useKeywordTableColumns";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

const Keywords = () => {
  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get("start");
  const endDate = searchParams.get("end");
  const { data: keywords, isLoading } = useKeywords(
    auth.token,
    id,
    "?sortBy=clicks&orderBy=desc&perPage=20" +
      (startDate && endDate ? `&start=${startDate}&end=${endDate}` : "")
  );
  const { columns } = useKeywordTableColumns(keywords?.sum);

  return (
    <>
      <Card
        title={
          <span className="flex items-center">
            Keywords
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-html="Zu folgenden Suchanfragen wurden 
die Textanzeigen gefunden.
 "
            >
              <BiInfoCircle className="h-6 w-6 ml-1 text-gray-500" />{" "}
            </a>
            <Tooltip
              className="my-tooltip-class"
              //#111827 gray 900
              //#6b7280 gray 500
              style={{
                backgroundColor: "rgba(254, 254, 254, 1.5)",
                opacity: "100%",
                color: "#111827",
                fontSize: "12px",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              id="my-tooltip"
            />
          </span>
        }
      >
        <div className="-mx-5 overflow-x-auto">
          <Table
            columns={columns}
            data={keywords?.keywords ?? []}
            cardPadding
            isLoading={isLoading}
            initialSortBy={{ id: "clicks", desc: true }}
          />
        </div>
      </Card>
    </>
  );
};

export default Keywords;

import React, { useEffect, useState } from "react";
import { LuFileCheck2, LuFileEdit } from "react-icons/lu";
import { HiMiniCheckCircle } from "react-icons/hi2";

const BookingChangelogHistory = ({ changelogs }) => {
  const [newChangelogs, setNewChangelogs] = useState([]);

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    // Berechne historische Anzahl für jeden Log
    const newLogs = changelogs
      .slice(0, -1)
      .map((log, index) => {
        // Zähle bis zum aktuellen Log-Eintrag die Anzahl "in_coordination"
        const inCoordinationCount = changelogs
          .slice(index)
          .filter((l) => l.status.after === "in_coordination").length;

        return {
          ...log,
          inCoordinationCount, // Speichere die Anzahl als neues Feld
        };
      })
      .filter((log) => log.status.after !== "prepared");

    setNewChangelogs(newLogs);
  }, [changelogs]);

  return (
    <div className="w-full">
      <div className="space-y-4">
        {newChangelogs.map((log, index) => (
          <div
            key={index}
            className={`flex items-center space-x-4 pb-3 ${
              index !== newChangelogs.length - 1 ? "border-b" : ""
            }`}
          >
            {/* Icon */}
            {log.status.after === "rejected" ? (
              <LuFileEdit className="w-5 h-5" />
            ) : log.status.after === "accepted" ? (
              <HiMiniCheckCircle className="w-5 h-5" />
            ) : (
              <LuFileCheck2 className="w-5 h-5" />
            )}

            {/* Datum und Beschreibung */}
            <span className="font-bold text-sm">
              {formatDate(log.created_at)}
            </span>
            <span className="font-normal text-sm">
              {log.status.after === "rejected" ? (
                <>
                  Feedback von{" "}
                  <span className="font-semibold">
                    {log.user.first_name} {log.user.last_name}
                  </span>
                </>
              ) : log.status.after === "accepted" ? (
                <>
                  Freigegeben von{" "}
                  <span className="font-semibold">
                    {log.user.first_name} {log.user.last_name}
                  </span>
                </>
              ) : (
                <>Erneute Abstimmung</>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default BookingChangelogHistory;

import React from "react";
import Card from "../components/Card";
import { Link } from "react-router-dom";
import Logo from "../img/stw3_logo.png";

const NotFound = () => {
  return (
    <>
      <div className="bg-white border-b border-gray-200 shadow">
        <div className="container mx-auto bg-white">
          <div className="py-6 px-4 sm:px-0">
            <Link to="/">
              <img src={Logo} alt="Logo" className="w-32" />
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Card>
          <div className="text-center py-32 flex items-center justify-center">
            <div>
              <h1 className="font-bold text-3xl">404 - Seite nicht gefunden</h1>
              <p className="mt-12 ">
                Es tut uns leid, aber wir konnten die gewünschte Seite nicht
                finden.
              </p>
              <p>Möglicherweise wurde sie verschoben oder gelöscht.</p>
              <p>
                Wenn Sie nicht finden können, was Sie suchen, kontaktieren Sie
                uns bitte.
              </p>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default NotFound;

import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
//flex-grow for main
const MainLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <Outlet />
      </main>
      <div className="border-t pt-5">
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;

import ErrorBoundary from "./errorHandling/ErrorBoundary";
import GenericErrorMessage from "./errorHandling/GenericErrorMessage";

const Card = ({ title, children, className }) => {
  const hasBgColor = /bg-/.test(className);

  return (
    <ErrorBoundary fallback={<GenericErrorMessage />}>
      <div
        className={`container relative shadow-lg sm:rounded border-stw3-gray-200 p-5 ${
          hasBgColor ? "" : "bg-white"
        } ${className && className}`}
      >
        {title && (
          <h2 className="my-4 text-2xl leading-4 text-gray-900">{title}</h2>
        )}
        {children}
      </div>
    </ErrorBoundary>
  );
};

export default Card;

export const CardHeader = ({ title, description, titleColor }) => {
  return (
    <div className="mb-8">
      <p className={`font-bold text-xl mb-2 ${titleColor} `}>{title}</p>
      <p className="text-gray-500 text-base font-normal whitespace-pre-line ">
        {description}
      </p>
    </div>
  );
};

import { RadioGroup } from "@headlessui/react";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { FaX } from "react-icons/fa6";

const RadioDesicionButtons = ({
  labelAcceptButton,
  labelDeclineButton,
  onChange,
  disabled,
  value,
}) => {
  return (
    <RadioGroup
      disabled={disabled}
      value={value}
      onChange={onChange}
      className={"flex items-center space-x-4"}
    >
      <DesicionButton label={labelAcceptButton} value={true} />
      <DesicionButton label={labelDeclineButton} value={false} />
    </RadioGroup>
  );
};

export default RadioDesicionButtons;

const DesicionButton = ({ label, value }) => {
  return (
    <RadioGroup.Option value={value}>
      {({ checked }) => (
        <button
          className={` text-base font-medium rounded  p-2 flex  items-center space-x-2 ${
            checked ? "bg-[#E0E0E0]" : "border border-stw3-gray-700"
          }`}
        >
          <div className="relative">
            <div
              className={`h-4 w-4 rounded-full ${
                checked ? "bg-white" : "border"
              } border-stw3-gray-700`}
            ></div>
            {checked && (
              <div
                className={`absolute top-0 left-0 ${
                  value ? "-translate-y-1 " : "-translate-y-1 -translate-x-1"
                }`}
              >
                {value ? (
                  <FaCheck className="h-5 w-5 text-green-700 " />
                ) : (
                  <FaX className="h-6 w-6 text-red-600 stroke-2" />
                )}
              </div>
            )}
          </div>
          <p className=" whitespace-nowrap ">{label}</p>
        </button>
      )}
    </RadioGroup.Option>
  );
};

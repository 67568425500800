import { useEffect, useState } from "react";

const useScreenSize = () => {
  const [windowWidth, setWindoWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleWindowSizeChange = () => {
    setWindoWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isWindowSmallerThan = (breakpoint) => {
    if (breakpoint === "sm") {
      return windowWidth <= 480;
    }
    if (breakpoint === "md") {
      return windowWidth <= 768;
    }
    if (breakpoint === "lg") {
      return windowWidth <= 976;
    }
    if (breakpoint === "xl") {
      return windowWidth <= 1280;
    }
    if (breakpoint === "2xl") {
      return windowWidth <= 1536;
    }
  };

  return { windowWidth, windowHeight, isWindowSmallerThan };
};

export default useScreenSize;

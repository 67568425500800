import React from "react";
import SeaAdPreview from "./SeaAdPreview";
import useSeaAdtemplateParameters from "../../../hooks/useSeaAdtemplateParameters";
import SitelinkPreview from "./SitelinkPreview";
import CalloutPreview from "./CalloutPreview";
import SnippetPreview from "./SnippetPreview";

const SeaAdElement = ({
  status,
  adtemplate,
  onEdit,
  sitelinks,
  onEditSitelinks,
  callouts,
  onEditCallouts,
  snippets,
  onEditSnippets,
}) => {
  const { headline, url, description } = useSeaAdtemplateParameters(
    adtemplate.parameters,
    adtemplate.deeplink
  );
  return (
    <div className="lg:grid grid-cols-12 gap-8">
      <p className="text-base text-gray-500 font-normal">Anzeige:</p>
      <div
        className="col-span-11 xl:col-span-8 border border-gray-300  p-4"
        style={{
          boxShadow: "1px 2px 15px 0px #11182726",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <SeaAdPreview
          status={status}
          onEdit={onEdit}
          url={url}
          headlines={headline}
          descriptions={description}
        />
        {callouts?.length > 0 && (
          <CalloutPreview
            callouts={callouts}
            onEdit={onEditCallouts}
            status={status}
          />
        )}
        {snippets?.length > 0 && (
          <SnippetPreview
            snippets={snippets}
            onEdit={onEditSnippets}
            status={status}
          />
        )}

        {sitelinks?.length > 0 && (
          <SitelinkPreview
            sitelinks={sitelinks}
            onEdit={onEditSitelinks}
            status={status}
          />
        )}
      </div>
      <p className="text-base text-gray-500 font-normal col-start-1 mt-4 lg:mt-0">
        Ziel URL:
      </p>
      <a
        className="text-base font-medium hover:underline col-span-11"
        target="_blank"
        href={adtemplate?.deeplink}
        rel="noreferrer"
      >
        {adtemplate?.deeplink}
      </a>
    </div>
  );
};

export default SeaAdElement;

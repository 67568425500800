import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useSitelinks } from "../../hooks/useCampaignData";
import { useParams, useSearchParams } from "react-router-dom";
import Card from "../../components/Card";
import Table from "../../components/Table";
import useSitelinkTableColumns from "./tableColumns/useSitelinkTableColumns";
import { Tooltip } from "react-tooltip";
import { BiInfoCircle } from "react-icons/bi";

const Sitelinks = () => {
  const { auth } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const startDate = searchParams.get("start");
  const endDate = searchParams.get("end");

  const { data: sitelinks, isLoading } = useSitelinks(
    auth.token,
    id,
    `?showInactive=true ` +
      (startDate && endDate ? `&start=${startDate}&end=${endDate}` : "")
  );
  const { columns } = useSitelinkTableColumns(sitelinks?.sum);
  return (
    <Card
      title={
        <span className="flex items-center">
          Sitelinks
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Ergebnisse der Anzeigenerweiterungen  

 "
          >
            <BiInfoCircle className="h-6 w-6 ml-1 text-gray-500" />{" "}
          </a>
          <Tooltip
            className="my-tooltip-class"
            //#111827 gray 900
            //#6b7280 gray 500
            style={{
              backgroundColor: "rgba(254, 254, 254, 1.5)",
              opacity: "100%",
              color: "#111827",
              fontSize: "12px",
              borderRadius: "4px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            id="my-tooltip"
          />
        </span>
      }
    >
      <div className="-mx-5 overflow-x-auto">
        <Table
          columns={columns}
          data={sitelinks?.sitelinks ?? []}
          cardPadding
          isLoading={isLoading}
          initialSortBy={{ id: "clicks", desc: true }}
        />
      </div>
    </Card>
  );
};

export default Sitelinks;
